import loader from "../../assets/loader.svg";

const Loader = () => {
  return (
    <div className="d-flex justify-content-center" style={{ height: "200px" }}>
      <img src={loader} alt="loader" style={{ width: "100px" }} />
    </div>
  );
};

export default Loader;
