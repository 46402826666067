import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
import DateFormater from "../../Common/DateFormater";
import ToggleCheck from "../../Common/ToggleCheck";
import Table from "../../Common/Table";
import { return400Error } from "../../Common/Errors";

const MedicineStrength = () => {
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [actionbtnsts, setactionbtnsts] = useState("add");
  const [ActionTitle, setActionTitle] = useState("ADD MEDICINE STRENGTH");
  const [actionbtntxt, setactionbtntxt] = useState("ADD");
  const [allFormData, setAllFormData] = useState({});

  const columns = [
    // {
    //   dataField: "sl_no",
    //   text: "Index no.",
    //   sort: true,
    // },
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "created_on",
      text: "Created on",
      sort: true,
    },
    {
      dataField: "is_active",
      text: "Status",
    },
    // {
    //   dataField: "edit",
    //   text: "Edit",
    // },
  ];

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  useEffect(() => {
    fetchAllData();
  }, [pageNo, totalItemToBeFetched]);

  const fetchAllData = () => {
    setLoader(true);
    axios
      .get(
        process.env.REACT_APP_DOCTOR_URL +
        "/master/get_admin_medicine_strength?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
        {
          headers: {
            Authorization: process.env.REACT_APP_DOCTOR_TOKEN,
          },
        }
      )
      .then((res) => {
        setAllData([]);
        setLoader(false);
        setTotalItems(res.data.total_count);
        res.data.data.map((e, i) => {
          let updatedata = {
            sl_no: i + 1,
            id: e.id,
            name: e.name,
            created_on: <DateFormater time={e.created_on} />,
            is_active: (
              <ToggleCheck
                value={e.is_active}
                countryCode={e.country_code}
                baseURL={process.env.REACT_APP_DOCTOR_URL}
                token={process.env.REACT_APP_DOCTOR_TOKEN}
                id={e.id}
                url="/master/delete_medicine_strength/"
                method={"DELETE"}
              />
            ),
            // edit: <EditData data={e} sendSuccesResponse={sendSuccesResponse} />,
          };
          setAllData((oldArray) => [...oldArray, updatedata]);
        });
      })
      .catch((err) => {
        const { status, message } = err.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          return400Error(err);
        }
      });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setAllFormData({ ...allFormData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var specialChars = /[!@#$^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (!specialChars.test(allFormData.name)) {
      axios
        .post(
          process.env.REACT_APP_DOCTOR_URL + "/master/add_medicine_strength",
          allFormData,
          {
            headers: {
              Authorization: process.env.REACT_APP_DOCTOR_TOKEN,
            },
          }
        )
        .then((res) => {
          fetchAllData();
          toast.success(res.data.message);
          setAllFormData({
            name: "",
          });
        })
        .catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            sessionStorage.clear()
            window.location = "/";
            toast.error(message);
          }
          else {
            return400Error(err);
          }
        });
    }
    else {
      toast.warning('special character is not allowed except %');
    }
  };

  return (
    <div className="row px-2">
      <div className="col-8">
        <Table
          data={allData}
          searchKey="name"
          columns={columns}
          maxRow={10}
          loader={loader}
          totalItems={totalItems}
          totalItemToBeFetched={totalItemToBeFetched}
          setTotalItemToBeFetched={setTotalItemToBeFetched}
          pageNo={pageNo}
          setPageNo={setPageNo}
        />
      </div>

      <div className="col-4">
        <div className="card border-primary border">
          <div className="card-body">
            <h5 className="text-center mb-2 pb-1 text-uppercase">
              {ActionTitle}
            </h5>
            <form onSubmit={handleSubmit}>
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                required
                value={allFormData.name}
                onChange={handleOnChange}
              />
              <button
                type="submit"
                className="btn btn-primary px-3 py-1 mt-3 w-100"
              >
                {actionbtntxt}
              </button>
              {actionbtnsts === "add" ? null : (
                <button
                  className="btn btn-primary px-3 py-1 mt-3 w-100"
                  onClick={() => {
                    setActionTitle("ADD MEDICINE STRENGTH");
                    setactionbtntxt("ADD");
                    setactionbtnsts("add");
                    setAllFormData({});
                  }}
                >
                  {"Cancel"}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MedicineStrength;
