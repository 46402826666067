import React, { useEffect, useState } from 'react';
import withRouter from '../../HOCs/withRouter';
import Editor from "../../Common/Editor";
import { toast } from 'react-toastify';
import axios from 'axios';
import { return400Error } from '../../Common/Errors';

const EditStaticPage = ({
  setShowSummary,
  onSuccess,
  router: {
    location: {
      state
    },
    navigate,
    // params: {
    //   ID
    // }
  }
}) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState('');

  useEffect(() => {
    if (state?.summary?.id) {
      setSelectedItem(state.summary);
      setTitle(state.summary?.page_title);
      setIcon(state.summary?.page_icon);
    }
  }, [state]);

  const handleSubmit = (event, deleteIcon = false) => {
    const formData = new FormData();
    formData.append('text', content || selectedItem?.text);
    const pageTitle = title.length > 0 ? title : selectedItem?.page_title;
    formData.append('page_title', pageTitle);
    console.log('>>', deleteIcon, icon);
    formData.append('page_icon', deleteIcon ? '' : icon);
    if (selectedItem?.id) {
      formData.append('sequence', selectedItem?.sequence);
      formData.append('is_active', selectedItem.is_active)
    }
    axios({
      method: selectedItem?.id ? "PUT" : "POST",
      url:
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin" + (selectedItem?.id ? '/update_static_page/' + selectedItem.id : '/add_static_page'),
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
      data: formData,
    })
      .then(function (response) {
        toast.success(response.data.message);
        setShowSummary(false);
        navigate('/all-static-pages');
        onSuccess();
      })
      .catch(function (error) {
        return400Error(error);
      });
  }

  const {
    summary
  } = state || {};

  const [content, setContent] = useState('');

  return (
    <>
      {window.location.href.includes('edit-static-pages') &&
        <div className="mr-2 my-4 d-flex flex-row justify-content-between align-items-center">
          <div className="flex-grow-1 mr-3">
            {<input
              value={title?.slice(title?.lastIndexOf(';') + 1) || selectedItem.page_title?.slice(selectedItem.page_title?.lastIndexOf(';') + 1)}
              name="page-title"
              className='form-control'
              placeholder='Please enter title'
              onChange={(e) => setTitle(e.target.value)}
            />}
          </div>
          <div className='row d-flex justify-content-center mr-2'>
            <button
              className='btn btn-primary'
              style={{
                width: '110%',
              }}
              onClick={handleSubmit}
            // disabled={(!title || !selectedItem.page_title) || (!content || !selectedItem.text)}
            >
              Save
            </button>
          </div>
          <button
            className="btn btn-outline-primary back-button"
            style={{
              width: '10%',
            }}
            onClick={() => {
              setShowSummary(false);
              navigate(-1);
            }}>
            Back
          </button>
        </div>}
      {selectedItem.id && <h2 className="text-center mb-3">{title}</h2>}
      <div className='row'>
        <div className="col-md-6">
          <input
            value={icon}
            name="icon"
            className='form-control my-2'
            placeholder='Please enter icon name'
            onChange={(e) => { setIcon(e.target.value); }}
          />
        </div>
        <div className='col-md-1 d-flex align-items-center'>
          <i className={icon} style={{ fontSize: '28px' }} />
        </div>
        <div className='col-md-5 d-flex align-items-center'>
          <button className='btn btn-primary' onClick={(event) => { handleSubmit(event, true) }} disabled={!icon}>Delete Icon</button>
        </div>
      </div>

      <div className='row'>
        <div className="col-md-6">
          <Editor
            value={content || selectedItem.text}
            onChange={(e) => {
              setContent(e);
            }}
          />
        </div>
        <div className="col-md-6">
          <div className="card border-primary border">
            <div className="card-body" style={{ overflowY: "auto" }}>
              <h4 className="card-title text-primary mb-2">
                {/* <i className="uil-document-layout-left mr-1"></i> */}
                <span dangerouslySetInnerHTML={{ __html: title || selectedItem?.page_title }} />
              </h4>
              <div dangerouslySetInnerHTML={{ __html: content || selectedItem?.text }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(EditStaticPage);