import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import CommonTable from "../Common/CommonTable";
import ToggleCheck from "../Common/ToggleCheck";
import moment from "moment";
import CreateRole from "./CreateRoles";
import { AccessList, getKeyByValue } from "./utils";
import { return400Error } from "../Common/Errors";
import { FiltersContext } from "../Common/FiltersProvider";
import { toQueryParams } from "../Pages/Consumer/helpers/filtersHelpers";

const Roles = () => {
  const { state: { filters } } = useContext(FiltersContext);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);
  const [refresh, setRefresh] = useState();
  const close = () => setOpen(false);

  function fetchRoles(filterFormPayload, setApplyFiltersFalseFn, searchTerm, applyFilter) {
    const apiLinkUrl = process.env.REACT_APP_BASE_URL +
      "/medpacks_admin/get_admin_roles?page_no=" +
      (applyFilter ? '1' : pageNo) +
      "&page_count=" +
      totalItemToBeFetched;
    setLoader(true);
    axios({
      method: "GET",
      url:
        searchTerm
          ? apiLinkUrl +
          "&search_string=" +
          searchTerm
          : apiLinkUrl + (filters && Object.keys(filters).length > 0 ? '&' + toQueryParams(filters) : ''),
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          setTotalItems(response.data.total_count);
          setLoader(false);
          const tableData = response.data.data.map((e, i) => {
            let updatedata = {
              ...e,
              created_on: (
                <span>
                  {moment(e?.created_on).format("DD MMM YYYY") || "_"}
                </span>
              ),
              roles: (
                <div className="row">
                  {Object.values(AccessList).map(
                    (value) =>
                      e[getKeyByValue(AccessList, value)] && (
                        <div className="col-12 text-left">&nbsp;{value}</div>
                      )
                  )}
                </div>
              ),
            };
            return updatedata;
          });
          setData(tableData);
          if (setApplyFiltersFalseFn) {
            setApplyFiltersFalseFn(false);
          }
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // setLoader(false);
        return400Error(error);
      });
  }

  useEffect(() => {
    fetchRoles(filters);
  }, [pageNo, totalItemToBeFetched, refresh]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: "description",
      text: "Description",
      type: "input",
      isFilterable: false,
    },
    {
      dataField: "created_by_name",
      text: "Created By",
      type: "input",
      isFilterable: true,
      filterLabel: 'created_by'
    },
    {
      dataField: "created_on",
      text: "Created On",
      type: "datePicker",
      isFilterable: true,
    },
    {
      dataField: "roles",
      text: "Roles",
      type: "input",
      isFilterable: false,
    },
  ];
  return (
    <div className="card">
      <div className="d-flex align-items-center">
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#roleModal"
          style={{ marginLeft: "88%", marginTop: "10px" }}
          onClick={() => setOpen(true)}
        >
          Create Role
        </button>
        {open && <CreateRole close={close} setRefresh={setRefresh} />}
      </div>
      <div className="card w-50 p-2 ml-2">
        <small>
          <b>* Admin Access -</b> Can create more admins and access to all data{" "}
          <br />
          <b>* Clinic Access -</b> Access to Doctor Panel <br />
          <b>* Customer App Settings -</b> Access to Master data creation and
          updates <br />
          <b>* Customer Access -</b> Access to order data and customer data
        </small>
      </div>
      <CommonTable
        data={data}
        columns={columns}
        maxRow={10}
        loader={loader}
        totalItems={totalItems}
        totalItemToBeFetched={totalItemToBeFetched}
        setTotalItemToBeFetched={setTotalItemToBeFetched}
        pageNo={pageNo}
        setPageNo={setPageNo}
        url={process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/get_admin_roles?page_no=" +
          pageNo +
          "&page_count=" +
          totalItemToBeFetched}
        onFilter={fetchRoles}
      />
    </div>
  );
};

export default Roles;
