export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};

export const AccessList = {
    'admin_access': 'Admin Access',
    'clinic_access': 'Clinic Access',
    'consumer_app_settings_access': 'Customer App Settings',
    'customers_access': 'Customer Access',
    'finance_access': 'Finance Access',
    'reports_and_analytics_access': 'Reports And Analytics'
};

export const AccessLabels = Object.values(AccessList);