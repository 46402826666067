import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { return400Error } from "./Errors";

const ToggleCheck2 = (props) => {
  const [status, setStatus] = useState('');
  const [featured, setFeatured] = useState('');

  useEffect(() => {
    props.actionfor === "status" && setStatus(props.value);
    props.actionfor === "featured" && setFeatured(props.value);
  }, [props.value])
  const handleClick = (e) => {
    if (props.actionfor === "status") {
      setStatus(e.target.checked);
      // disable or active
      axios({
        method: props.method,
        url: props.baseURL + props.url + props.id,
        data: {
          is_active: e.target.checked,
        },
        headers: {
          Authorization: props.token,
        },
      })
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            props.onSuccess && props.onSuccess();
          } else {
            toast.warning(res.data.message);
          }
        })
        .catch(function (error) {
          return400Error(error);
        });
    } else {
      setFeatured(e.target.checked);
      axios({
        method: props.method,
        url: props.baseURL + props.url + props.id,
        data: {
          featured: e.target.checked,
        },
        headers: {
          Authorization: props.token,
        },
      })
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            props.onSuccess && props.onSuccess();
          } else {
            toast.warning(res.data.message);
          }
        })
        .catch(function (error) {
          return400Error(error);
        });
    }
  };

  return props.actionfor === "status" ? (
    <div className={`custom-control custom-switch mb-1 ${props.className}` + props.id + 'status'}>
      <input
        type="checkbox"
        className="custom-control-input"
        id={props.id + "_status"}
        checked={status !== '' ? status : props.value}
        onClick={handleClick}
      />
      <label
        className="custom-control-label"
        htmlFor={props.id + "_" + props.actionfor}
      ></label>
    </div>
  ) : (
    <div className={`custom-control custom-switch mb-1 ${props.className}` + props.id + 'featured'}>
      <input
        type="checkbox"
        className="custom-control-input"
        id={props.id + "_featured"}
        checked={featured !== '' ? featured : props.value}
        onClick={handleClick}
      />
      <label
        className="custom-control-label"
        htmlFor={props.id + "_" + props.actionfor}
      ></label>
    </div>
  );
};

export default ToggleCheck2;
