import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Table from "../../Common/Table";
import Drawer from "react-modern-drawer";
import ToggleCheck2 from "../../Common/ToggleCheck2";
import CommonTable from "../../Common/CommonTable";
import ToggleCheck from "../../Common/ToggleCheck";
import ProductSequence from "./ProductSequence";
import { return400Error } from "../../Common/Errors";
import { FiltersContext } from "../../Common/FiltersProvider";
import { toFormData } from "./helpers/filtersHelpers";

const Products = () => {
  const { state: { filters } } = React.useContext(FiltersContext);
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(true);
  const [successResponse, sendSuccesResponse] = useState(null);
  const columns = [
    {
      dataField: "sl_no",
      text: "Index no.",
      sort: true,
    },
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "product_images",
      text: "",
    },
    {
      dataField: "itemName",
      text: "Name",
      sort: true,
      isFilterable: false,
      type: "input",
    },
    {
      dataField: "avg_rating",
      text: "Rating",
      filterLabel: 'rating',
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: "pricePerPcs",
      text: "Price / pcs",
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: "pricePerPack",
      text: "Price / pack",
      sort: true,
      isFilterable: true,
      type: "input",
    },
    // {
    //   dataField: "sequence",
    //   text: "Sequence",
    //   sort: false,
    //   isFilterable: true,
    //   type: "input",
    // },
    {
      dataField: "is_active",
      text: "Status",
      sort: false,
      isFilterable: true,
      type: "dropDown",
    },
    // {
    //   dataField: "featured",
    //   text: "Featured",
    //   sort: false,
    //   isFilterable: true,
    //   type: "dropDown",
    // },
  ];

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  const fetchData = (filterFormPayload = new FormData(), setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    setLoader(true);
    if (searchTerm && filterFormPayload) {
      filterFormPayload.append('search_string', searchTerm);
    }
    const apiLinkUrl = process.env.REACT_APP_BASE_URL +
      "/medpacks_admin/get_all_products?page_no=" +
      (applyFilter ? '1' : pageNo) +
      "&page_count=" +
      totalItemToBeFetched;
    axios({
      method: "POST",
      url:
        apiLinkUrl,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
      data: filterFormPayload,
    })
      .then(function (response) {
        setLoader(false);
        setTotalItems(response.data.total_count);
        if (response.data.status === 1) {
          setProducts([]);
          //   customize data
          response.data.data.map((e, i) => {
            let updatedata = {
              sl_no: i + 1,
              id: e.id,
              product_images: (
                <img
                  src={process.env.REACT_APP_BASE_URL + e.product_images[0]}
                  alt="image"
                  width={50}
                />
              ),
              itemName: e.itemName,
              avg_rating: e.avg_rating,
              pricePerPcs: e.pricePerPcs + " / " + e.pcsUnit,
              pricePerPack: e.pricePerPack + " / " + e.packUnit,
              sequence: (
                <ProductSequence
                  id={e.id + "_sequence"}
                  sequence={e.sequence}
                  onChange={async (event) => {
                    if (event.target.value?.length > 0) {
                      await axios
                        .put(
                          process.env.REACT_APP_BASE_URL +
                          "/medpacks_admin/update_product/" +
                          e.id,
                          {
                            sequence: Number(event.target.value),
                          },
                          {
                            headers: {
                              Authorization:
                                "Token " + sessionStorage.getItem("token"),
                            },
                          }
                        )
                        .then((res) => {
                          toast.success(res.data.message);
                          setLoader(true);
                          fetchData(filters);
                        })
                        .catch((err) => {
                          const { status, message } = err.response.data.data;
                          if (status === "400") {
                            sessionStorage.clear()
                            window.location = "/";
                            toast.error(message);
                          }
                          else {
                            return400Error(err);
                          }
                        });
                    }
                  }}
                />
              ),
              is_active: (
                <ToggleCheck2
                  id={e.id}
                  value={e.is_active}
                  actionfor={"status"}
                  method={"PUT"}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  url="/medpacks_admin/update_product/"
                  token={"Token " + sessionStorage.getItem("token")}
                  onSuccess={() => fetchData(filters)}
                />
              ),
              featured: (
                <ToggleCheck2
                  id={e.id}
                  value={e.featured}
                  actionfor={"featured"}
                  method={"PUT"}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  url="/medpacks_admin/update_product/"
                  token={"Token " + sessionStorage.getItem("token")}
                  onSuccess={() => fetchData(filters)}
                />
              ),
            };
            setProducts((oldArray) => [...oldArray, updatedata]);
          });
          if (setApplyFiltersFalseFn) {
            setApplyFiltersFalseFn(false);
          }
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  };

  useEffect(() => {
    fetchData(toFormData(filters));
  }, [successResponse, pageNo, totalItemToBeFetched]);

  return (
    <>
      <CommonTable
        data={products}
        columns={columns}
        maxRow={10}
        searchKey="itemName"
        loader={loader}
        search={true}
        totalItems={totalItems}
        totalItemToBeFetched={totalItemToBeFetched}
        setTotalItemToBeFetched={setTotalItemToBeFetched}
        pageNo={pageNo}
        setPageNo={setPageNo}
        onFilter={fetchData}
        url={process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/get_all_products?page_no=" +
          pageNo +
          "&page_count=" +
          totalItemToBeFetched}
      />
    </>
  );
};
export default Products;
