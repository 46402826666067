import axios from "axios";
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CommonTable from "../Common/CommonTable";
import { return400Error } from "../Common/Errors";
import { FiltersContext } from "../Common/FiltersProvider";
import Table from "../Common/Table";
import ToggleCheck from "../Common/ToggleCheck";
import withRouter from "../HOCs/withRouter";
import { toFormData } from "./Consumer/helpers/filtersHelpers";
import OrderItem from "./customers/Profile/panels/orders/OrderItem";

const Orders = ({ router: { navigate } }) => {
  const { state: { filters } } = React.useContext(FiltersContext);
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showBackbtn, setShowBackbtn] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  useEffect(() => {
    if (window.location.href.includes("details")) {
      setShowSummary(true);
      localStorage.setItem("MENU", "DETAILS");
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("orders")) {
      setShowSummary(false);
      localStorage.setItem("MENU", "ORDERS");
    }
  }, [window.location.href]);

  useEffect(() => {
    if (localStorage.getItem("MENU") === "ORDERS") {
      setShowSummary(false);
    }
  }, [localStorage.getItem("MENU")]);

  function fetchOrders(filterFormPayload = new FormData(), setApplyFiltersFalseFn, searchTerm, applyFilter) {
    setLoader(true);
    if (searchTerm && filterFormPayload) {
      filterFormPayload.append('search_string', searchTerm);
    }
    const apiLinkUrl = process.env.REACT_APP_BASE_URL +
      "/medpacks_admin/get_all_orders_list?page_no=" +
      (applyFilter ? '1' : pageNo) +
      "&page_count=" +
      totalItemToBeFetched;
    setLoader(true);
    axios({
      method: "POST",
      url: apiLinkUrl,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
      data: filterFormPayload,
    })
      .then(function (response) {
        setLoader(false);
        setTotalItems(response.data.total_count);
        if (response.status === 200) {
          const tableData = response.data.data.map((e, i) => {
            let updatedata = {
              ...e,
              sl_no: i + 1,
              cancel_status: e.cancel_status ? "Y" : "N",
              id: e.id,
              viewMore: (
                <button
                  className="btn"
                  style={{
                    fontSize: "0.6rem",
                    color: "#727cf5",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setShowBackbtn(true);
                    navigate(`/details`, {
                      state: { order: { ...e } },
                    });
                    setShowSummary(true);
                    setSelectedOrder(e);
                  }}
                >
                  View Order Details
                </button>
              ),
              is_active: (
                <ToggleCheck
                  value={e.is_active}
                  id={e.id}
                  url="/medpacks_admin/disable_user/"
                  method={"DELETE"}
                />
              ),
            };
            return updatedata;
          });
          setOrders(tableData);
          if (setApplyFiltersFalseFn) {
            setApplyFiltersFalseFn(false);
          }
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // setLoader(false);
        return400Error(error);
      });
  }

  useEffect(() => {
    fetchOrders(toFormData(filters));
  }, [pageNo, totalItemToBeFetched]);
  const columns = [
    {
      dataField: "sl_no",
      text: "Index no.",
      sort: true,
      isFilterable: false,
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
      isFilterable: false,
      filterLabel: 'order_id'
    },
    {
      dataField: "order_no",
      text: "Safemed Order No",
      type: "input",
      isFilterable: true,
      filterLabel: 'safemed_order_no'
    },
    {
      dataField: "id",
      text: "Medpack Order Id",
      type: "input",
      isFilterable: true,
    },
    {
      dataField: "orderdate",
      text: "Order Date",
      sort: true,
      type: "datePicker",
      isFilterable: true,
      dateFilterLabels: ['from_date', 'to_date']
    },
    {
      dataField: "order_status",
      text: "Fulfillment Status",
      sort: true,
      type: "input",
      headerStyle: () => {
        return { width: "137px" };
      },
      isFilterable: true,
    },
    {
      dataField: "viewMore",
      text: "",
      isFilterable: false,
    },
  ];

  // console.log('>>', showSummary);
  return !showSummary ? (
    <div className="orders-page">
      <CommonTable
        data={orders}
        columns={columns}
        maxRow={10}
        loader={loader}
        searchKey="order_no"
        tabLabel="All Orders"
        totalItems={totalItems}
        totalItemToBeFetched={totalItemToBeFetched}
        setTotalItemToBeFetched={setTotalItemToBeFetched}
        pageNo={pageNo}
        setPageNo={setPageNo}
        onFilter={fetchOrders}
        url={process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/get_all_orders_list?page_no=" +
          pageNo +
          "&page_count=" +
          totalItemToBeFetched}
      />
    </div>
  ) : (
    <OrderItem selectedOrder={selectedOrder} setShowSummary={setShowSummary} />
  );
};

export default withRouter(Orders);
