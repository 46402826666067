import React from "react";
import moment from 'moment';

const DateFormater = (props) => {
  return (
    <small>
      {moment(new Date(props?.time))?.get('date')} - {moment(new Date(props?.time))?.format('MMM')} - {moment(new Date(props?.time))?.get('year')} T{moment(new Date(props?.time))?.format('LTS')}
    </small>
  );
};
export default DateFormater;
