import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import CommonTable from '../../Common/CommonTable';
import moment from 'moment';
import ToggleCheck from '../../Common/ToggleCheck';
import ProductSequence from './ProductSequence';
import AddImage from './AddImage';
import { return400Error } from '../../Common/Errors';

const ImageControl = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});

  const columns = [
    {
      dataField: "id",
      text: "ID",
      isFilterable: true,
      type: 'input',
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "image",
      text: "Image",
      sort: false,
      isFilterable: false,
    },
    {
      dataField: "created_on",
      text: "Created On",
      isFilterable: true,
      type: "datePicker",
    },
    {
      dataField: "modified_on",
      text: "Modified On",
      isFilterable: true,
      type: "datePicker",
    },
    {
      dataField: "sequence",
      text: "Sequence",
      isFilterable: false,
      type: "dropDown",
    },
    {
      dataField: "is_active",
      text: "Active",
      isFilterable: true,
      isBoolean: true,
      type: "dropDown",
    },
    {
      dataField: "viewMore",
      text: "",
      isFilterable: false,
      headerStyle: () => {
        return { width: "60px" };
      },
    },
  ];

  const handleDeleteImage = (id) => {
    axios({
      method: "DELETE",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/delete_login_page_banner/" + id,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setLoader(true);
          fetchData();
          setSelectedItem({});
        }
      })
      .catch(function (error) {
        return400Error(error);
      });
  };

  const fetchData = () => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/get_login_page_banner",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoader(false);
        if (response.status === 200) {
          setData(response.data.data.map((e, i) => {
            return {
              ...e,
              image: (
                <img
                  src={
                    process.env.REACT_APP_BASE_URL + e.image
                  }
                  height={100}
                  width={100}
                  alt={"banner_img"}
                />
              ),
              created_on: <span>{moment(e?.created_on).format('DD MMM YYYY Thh:mm:ss') || '_'}</span>,
              modified_on: <span>{moment(e?.modified_on).format('DD MMM YYYY Thh:mm:ss') || '_'}</span>,
              sequence: (
                <ProductSequence
                  id={e.id + '_sequence'}
                  sequence={e.sequence}
                  onChange={async (event) => {
                    if (event.target.value?.length > 0) {
                      const formData = new FormData();
                      Object.keys(e).forEach(key => formData.append(key, e[key]));
                      formData.delete('sequence');
                      formData.delete('image');
                      formData.append('sequence', Number(event.target.value));
                      await axios
                        .put(
                          process.env.REACT_APP_BASE_URL +
                          "/medpacks_admin/update_login_page_banner/" +
                          e.id,
                          formData,
                          {
                            headers: {
                              Authorization:
                                "Token " + sessionStorage.getItem("token"),
                            },
                          }
                        )
                        .then((res) => {
                          toast.success(res.data.message);
                        })
                        .catch((err) => {
                          const { status, message } = err.response.data.data;
                          if (status === "400") {
                            sessionStorage.clear()
                            window.location = "/";
                            toast.error(message);
                          }
                          else {
                            return400Error(err);
                          }
                        });
                    }
                  }}
                />
              ),
              is_active: (
                <ToggleCheck
                  value={e.is_active}
                  id={e.id}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  token={"Token " + sessionStorage.getItem("token")}
                  url="/medpacks_admin/delete_login_page_banner/"
                  method={"DELETE"}
                  onSuccess={fetchData}
                />
              ),
              viewMore: (
                <div class="dropdown">
                  <button class="btn text-primary user-edit p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h4><i className='mdi mdi-dots-vertical' /></h4>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button className='btn' data-toggle="modal"
                      data-target="#add-user-image-modal" onClick={() => setSelectedItem(e)}><a class="dropdown-item" href="#"><i className='mdi mdi-edit' />Edit</a></button>
                  </div>
                </div >
              ),
            };
          }));
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div>
      <div className="row mb-2">
        <div className="container-fluid">
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#add-user-image-modal"
            onClick={() => { }}
          >
            <i class="mdi mdi-plus-circle"></i> Upload Image
          </button>
          <AddImage
            onSuccess={fetchData}
            selectedItem={selectedItem}
            setLoader={setLoader}
            setSelectedItem={setSelectedItem}
          />
        </div>
      </div>
      <div className="row">
        <CommonTable
          data={data}
          columns={columns}
          maxRow={10}
          loader={loader}
          enableFESearchFilter={true}
        />
      </div>
    </div>
  );
}

export default ImageControl;