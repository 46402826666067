import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-modern-drawer/dist/index.css";
import Profile from "./Pages/customers/Profile";
import Orders from "./Pages/Orders";
import "../styles/main.scss";
import OrderItem from "../Components/Pages/customers/Profile/panels/orders/OrderItem";
import Index from "./Index";

// import components
import Login from "./Auth/Login";
// import consumer panel components /
import Customers from "./Pages/Consumer/Customers";
import Dashboard from "./Pages/Consumer/Dashboard";
import PhoneCode from "./Pages/Consumer/PhoneCode";
import Carousel from "./Pages/Consumer/Carousel";
import Offers from "./Pages/Consumer/Offers";
import Coupons from "./Pages/Consumer/Coupons";
import TopMenu from "./Pages/Consumer/TopMenu";
import LoyaltyPoint from "./Pages/Consumer/LoyaltyPoint";
import Categories from "./Pages/Consumer/Categories";
import StaticPages from "./Pages/Consumer/StaticPages";
import SocialMediaLink from "./Pages/Consumer/SocialMediaLink";
import BloodGroups from "./Pages/Doctor/BloodGroups";
import Tests from "./Pages/Doctor/Tests";
import Complaints from "./Pages/Doctor/Complaints";
import MedicineDuration from "./Pages/Doctor/MedicineDuration";
import MedicineRoute from "./Pages/Doctor/MedicineRoute";
import DosagesUnit from "./Pages/Doctor/DosagesUnit";
import MedicineStrength from "./Pages/Doctor/MedicineStrength";
import Diagnosis from "./Pages/Doctor/Diagnosis";
import AddClinic from "./Pages/Doctor/AddClinic";
import Products from "./Pages/Consumer/Products";
import Settings from "./settings/settings";
import Roles from "./settings/roles";
import FAQ from "./Pages/Doctor/FAQ";
import MyAccount from "./Pages/admin/MyAccount";
import ImageControl from "./Pages/Consumer/ImageControl";
import NoPermissions from "./Common/NoPermissions";
import NoMatch from "./Common/NoMatch";
import CreateStaticPages from "./Pages/Consumer/CreateStaticPages";
import EditStaticPage from "./Pages/Consumer/EditStaticPage";
import ProductList from "./Pages/Consumer/ProductList";
import LoyaltyPointSetting from "./Pages/Consumer/LoyaltyPointSetting";
import MedicineDirection from "./Pages/Doctor/MedicineDirection";
import DocumentTypes from "./Pages/Doctor/DocumentTypes";
// import doctor panel components

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/auth" element={<Login />} />
      </Routes>
      <Routes>
        <Route path="/" element={<Index />}>
          {/* <Route path="/" element={<Dashboard />} /> */}
          <Route path="/" element={<MyAccount />} />
          <Route path="/users" element={<Settings />} />
          {/* router for consumer */}
          <Route path="/customers" element={<Customers />} />
          <Route path="/summary/*" element={<Customers />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/details/*" element={<Orders />} />
          {/* <Route path="/userSummary/:ID" element={<Profile />} /> */}
          <Route path="/top-products" element={<Products />} />
          <Route path="/products-sequence" element={<ProductList />} />
          <Route path="/country-code" element={<PhoneCode />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/loyalty-points" element={<LoyaltyPoint />} />
          <Route
            path="/loyalty-point-setting"
            element={<LoyaltyPointSetting />}
          />
          <Route path="/roles" element={<Roles />} />
          <Route path="/top-menu" element={<TopMenu />} />
          <Route path="/images-control" element={<ImageControl />} />
          {/* <Route path="/static-pages" element={<StaticPages />} /> */}
          <Route path="/edit-static-pages" element={<CreateStaticPages />} />
          <Route path="/all-static-pages" element={<CreateStaticPages />} />
          <Route path="/social-media-link" element={<SocialMediaLink />} />
          <Route path="/homepage-carousel" element={<Carousel />} />
          {/* router for clinic */}
          <Route path="/add-clinic" element={<AddClinic />} />
          <Route path="/blood-group" element={<BloodGroups />} />
          <Route path="/medicine-strength" element={<MedicineStrength />} />
          <Route path="/document-types" element={<DocumentTypes />} />
          <Route path="/dosage-unit" element={<DosagesUnit />} />
          <Route path="/medicine-route" element={<MedicineRoute />} />
          <Route path="/medicine-duration" element={<MedicineDuration />} />
          <Route path="/medicine-direction" element={<MedicineDirection />} />
          <Route path="/complaints" element={<Complaints />} />
          <Route path="/diagnosis" element={<Diagnosis />} />
          <Route path="/test" element={<Tests />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/no-access=true" element={<NoPermissions />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
