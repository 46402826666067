import * as React from 'react';
import moment from 'moment';

const Address = ({
  status: data
}) => {
  return (
    <div className='card orderItem'>
      <div className='order-status'><h5>Order History</h5></div>
      {data.length ?
        <div className='track-history'>
          <div className='track-history-item text-primary '>
            {data.map(({
              id,
              status,
              status_date_api,
            }, i) => {
              const noBorderCX = i === (data.length - 1) ? 'no-border' : '';
              return (
                <>
                  <div className={`track-item ${status.split(' ').pop().toLowerCase()}`}>
                    <span className='track-icon'><i className='mdi mdi-check-circle' /></span>
                    <div className='track-label'>{status}</div>
                  </div>
                  <div className={`track-date ${status.split(' ').pop().toLowerCase()} ${noBorderCX}`}>{moment(status_date_api).format('MMMM Do YYYY, h:mm:ss a')}</div>
                </>
              );
            })}
          </div>
        </div> : <p style={{ paddingLeft: '10px' }}>No Tracking information available</p>}
    </div>
  );
}

export default Address;