import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ToggleCheck from "../../Common/ToggleCheck";
import Table from "../../Common/Table";
import { return400Error } from "../../Common/Errors";
import CommonTable from "../../Common/CommonTable";

const SocialMediaLink = () => {
  const [socialMedialFormData, setSocialMedialFormData] = useState({});
  const [ActionTitle, setActionTitle] = useState("ADD SOCIAL MEDIA");
  const [actionbtntxt, setactionbtntxt] = useState("ADD");
  const [alldata, setAlldata] = useState([]);
  const [actionbtnsts, setactionbtnsts] = useState("add");
  const [loader, setLoader] = useState(true);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "link",
      text: "Link",
    },
    {
      dataField: "sequence",
      text: "Sequence",
      sort: true,
    },
    {
      dataField: "is_active",
      text: "Status",
    },
    {
      dataField: "edit",
      text: "Edit",
    },
  ];

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  useEffect(() => {
    fetchAllSocialMedia();
  }, [pageNo, totalItemToBeFetched]);

  const fetchAllSocialMedia = () => {
    setLoader(true);
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_social_media_link?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
        {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoader(false);
        setTotalItems(res.data.total_count);
        setAlldata([]);
        res.data.data.map((e, i) => {
          let updatedata = {
            id: e.id,
            name: e.name,
            link: (
              <a href={e.link} target="_blank">
                {e.link}
              </a>
            ),
            sequence: e.sequence,
            is_active: (
              <ToggleCheck
                value={e?.is_active}
                id={e?.id}
                baseURL={process.env.REACT_APP_BASE_URL}
                token={"Token " + sessionStorage.getItem("token")}
                url="/medpacks_admin/delete_social_media_link/"
                method={"DELETE"}
                className="mt-1"
              />
            ),
            edit: (
              <i
                className="uil-edit"
                onClick={handleClickEditbtn}
                allval={JSON.stringify(e)}
                style={{ cursor: "pointer" }}
              ></i>
            ),
          };
          setAlldata((oldArray) => [...oldArray, updatedata]);
        });
      })
      .catch((err) => {
        const { status, message } = err.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          return400Error(err);
        }
      });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSocialMedialFormData({ ...socialMedialFormData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (actionbtnsts === "add") {
      axios
        .post(
          process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/add_social_media_link",
          socialMedialFormData,
          {
            headers: {
              Authorization: "Token " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          fetchAllSocialMedia();
          setSocialMedialFormData({
            name: '',
            link: '',
            sequence: ''
          })
          document.getElementById('form').reset();
          toast.success(res.data.message);
        })
        .catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            sessionStorage.clear()
            window.location = "/";
            toast.error(message);
          }
          else {
            return400Error(err);
          }
        });
    } else {
      axios
        .put(
          process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/update_social_media_link/" +
          actionbtnsts,
          socialMedialFormData,
          {
            headers: {
              Authorization: "Token " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          fetchAllSocialMedia();
          setSocialMedialFormData({
            name: '',
            link: '',
            sequence: ''
          })
          toast.success(res.data.message);
        })
        .catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            sessionStorage.clear()
            window.location = "/";
            toast.error(message);
          }
          else {
            return400Error(err);
          }
        });
    }
  };

  const handleClickEditbtn = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const data = JSON.parse(e.target.getAttribute("allval"));
    setSocialMedialFormData(data);
    setActionTitle("UPDATE " + data.name);
    setactionbtntxt("UPDATE");
    setactionbtnsts(data.id);
  };

  return (
    <div className="row px-3">
      <div className="col-8">
        <CommonTable
          data={alldata}
          searchKey="name"
          columns={columns}
          maxRow={100}
          loader={loader}
          hideFilters={true}
          enableFESearchFilter={true}
          totalItems={totalItems}
          totalItemToBeFetched={totalItemToBeFetched}
          setTotalItemToBeFetched={setTotalItemToBeFetched}
          pageNo={pageNo}
          setPageNo={setPageNo}
        />
      </div>

      {/* ADD or UPDATE SOCIAL MEDIA */}
      <div className="col-4">
        <div
          className={`card border ${actionbtntxt === "UPDATE" ? `border-highlight` : null
            }`}
        >
          <div className="card-body">
            <h5
              className={`text-center mb-2 pb-1 text-uppercase ${actionbtntxt === "UPDATE" ? `text-primary` : null
                }`}
            >
              {ActionTitle}
            </h5>
            <form onSubmit={handleSubmit} id="form">
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Social media name"
                required
                value={socialMedialFormData?.name}
                onChange={handleOnChange}
              />
              <input
                className="form-control mt-2"
                type="url"
                name="link"
                placeholder="Link (ex:- https://example.com)"
                required
                value={socialMedialFormData?.link}
                onChange={handleOnChange}
              />
              <input
                className="form-control mt-2"
                type="number"
                name="sequence"
                placeholder="Sequence"
                required
                value={socialMedialFormData?.sequence}
                onChange={handleOnChange}
              />
              <button
                type="submit"
                className="btn btn-primary px-3 py-1 mt-3 w-100"
              >
                {actionbtntxt}
              </button>
              {actionbtnsts === "add" ? null : (
                <button
                  className="btn btn-primary px-3 py-1 mt-3 w-100"
                  onClick={() => {
                    setActionTitle("ADD SOCIAL MEDIA");
                    setactionbtntxt("ADD");
                    setactionbtnsts("add");
                    setSocialMedialFormData({});
                    document.getElementById('form').reset();
                  }}
                >
                  {"Cancel"}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SocialMediaLink;
