import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import CommonTable from "../Common/CommonTable";
import ToggleCheck from "../Common/ToggleCheck";
import moment from "moment";
import AddUser from "./AddUser";
import { return400Error } from "../Common/Errors";
import { FiltersContext } from "../Common/FiltersProvider";
import { toQueryParams } from "../Pages/Consumer/helpers/filtersHelpers";

const Settings = () => {
  const { state: { filters } } = useContext(FiltersContext);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [open, setOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  const fetchAllUsers = (filterFormPayload, setApplyFiltersFalseFn, searchTerm, applyFilter) => {
    setLoader(true);
    const apiLinkUrl = process.env.REACT_APP_BASE_URL +
      "/medpacks_admin/get_admin_user?page_no=" +
      (applyFilter ? '1' : pageNo) +
      "&page_count=" +
      totalItemToBeFetched;
    axios({
      method: "GET",
      url: searchTerm
        ? apiLinkUrl +
        '&' + toQueryParams(filters) +
        "&search_string=" +
        searchTerm
        : apiLinkUrl + (filters && Object.keys(filters).length > 0 ? '&' + toQueryParams(filters) : ''),
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          setLoader(false);
          setTotalItems(response.data.total_count);
          const tableData = response.data.data.map((e, i) => {
            let updatedata = {
              ...e,
              created_on: (
                <span>
                  {moment(e?.created_on).format("DD MMM YYYY") || "_"}
                </span>
              ),
              viewMore: (
                <div class="dropdown">
                  <button
                    class="btn text-primary user-edit p-0"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <h4>
                      <i className="mdi mdi-dots-vertical" />
                    </h4>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      className="btn"
                      data-toggle="modal"
                      data-target="#add-user-modal"
                      onClick={() => {
                        setSelectedUser(e);
                        setOpen(true);
                      }}
                    >
                      <a class="dropdown-item" href="#">
                        <i className="mdi mdi-edit" />
                        Edit
                      </a>
                    </button>
                  </div>
                </div>
              ),
              is_active: (
                <ToggleCheck
                  value={e.is_active}
                  id={e.id}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  token={"Token " + sessionStorage.getItem("token")}
                  url="/medpacks_admin/disable_user/"
                  method={"DELETE"}
                />
              ),
            };
            return updatedata;
          });
          setData(tableData);
          if (setApplyFiltersFalseFn) {
            setApplyFiltersFalseFn(false);
          }
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // setLoader(false);
        return400Error(error);
      });
  };

  useEffect(() => {
    fetchAllUsers(filters);
  }, [pageNo, totalItemToBeFetched]);

  const handleDeleteUser = (id) => {
    axios({
      method: "DELETE",
      url:
        process.env.REACT_APP_BASE_URL + "/medpacks_admin/disable_user/" + id,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          toast.success(response.data.message);
          setLoader(true);
          fetchAllUsers(filters);
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        return400Error(error);
      });
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      isFilterable: false,
    },
    {
      dataField: "email",
      text: "Email",
      type: "input",
      isFilterable: true,
    },
    {
      dataField: "role_name",
      text: "Role",
      type: "input",
      isFilterable: true,
      filterLabel: 'role'
    },
    {
      dataField: "created_on",
      text: "Created On",
      sort: true,
      type: "datePicker",
      isFilterable: true,
    },
    {
      dataField: "modified_on",
      text: "Modified On",
      sort: true,
      type: "datePicker",
      isFilterable: true,
    },
    {
      dataField: "created_by_name",
      text: "Created By",
      sort: true,
      type: "input",
      isFilterable: true,
      filterLabel: 'created_by',
    },
    {
      dataField: "is_active",
      text: "Status",
      sort: true,
      type: "dropDown",
      isFilterable: true,
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "viewMore",
      text: "",
      isFilterable: false,
      headerStyle: () => {
        return { width: "60px" };
      },
    },
  ];

  const close = () => setOpen(false);

  return (
    <div className="card">
      <div className="d-flex align-items-center">
        <p className="flex-grow-1 pl-3 pt-3">
          Invite your team members to your account.
          <br />
          Add, edit and remove your team members using User Settings.
        </p>
        <button
          className="btn btn-primary mr-2"
          data-toggle="modal"
          data-target="#add-user-modal"
          style={{ height: "40px" }}
          onClick={() => {
            setOpen(true);
          }}
        >
          Add User
        </button>
      </div>
      <CommonTable
        data={loader ? [] : data}
        columns={columns}
        maxRow={10}
        loader={loader}
        totalItems={totalItems}
        totalItemToBeFetched={totalItemToBeFetched}
        setTotalItemToBeFetched={setTotalItemToBeFetched}
        pageNo={pageNo}
        setPageNo={setPageNo}
        url={process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/get_admin_user?page_no=" +
          pageNo +
          "&page_count=" +
          totalItemToBeFetched}
        onFilter={fetchAllUsers}
      />
      {open && (
        <AddUser
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          onSuccess={() => {
            setLoader(true);
            fetchAllUsers(filters);
          }}
          close={close}
        />
      )}
    </div>
  );
};

export default Settings;
