import * as React from 'react';

const Address = ({
  address: {
    name,
    address,
    building_number,
    street,
    is_default
  }
}) => {
  return (
    <div className='card orderItem'>
      <div className='order-status'><h5>Shipping Address <span style={{ float: 'right', padding: '0 10px 20px 0' }}>{is_default ? 'Default' : ''}</span></h5></div>
      <div className='addresses'>
        <div>{name}</div>
        {building_number && <div>Building No: {building_number}</div>}
        {street && <div>Street: {street}</div>}
        {address}
      </div>
    </div>
  );
}

export default Address;