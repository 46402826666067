import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Drawer from "react-modern-drawer";
import "../customers/Profile/styles.scss";
// import Table from "../../Common/Table";
import CustomersFilter from "../SubCmp/CustomersFilter";
import ToggleCheck from "../../Common/ToggleCheck";
import withRouter from "../../HOCs/withRouter";
import CommonTable from "../../Common/CommonTable";
import Profile from "../customers/Profile";
import { useLocation } from "react-router-dom";
import { return400Error } from "../../Common/Errors";
import { FiltersContext } from "../../Common/FiltersProvider";
import { toFormData } from "./helpers/filtersHelpers";

const Customers = ({ router: { navigate } }) => {
  const { state: { filters } } = React.useContext(FiltersContext);
  const [customers, setCustomers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  const cur = useLocation();
  useEffect(() => {
    if (window.location.href.includes("summary")) {
      setShowSummary(true);
      localStorage.setItem("MENU", "SUMMARY");
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("customers")) {
      setShowSummary(false);
      localStorage.setItem("MENU", "CUSTOMERS");
    }
  }, [window.location.href]);

  useEffect(() => {
    if (localStorage.getItem("MENU") === "CUSTOMERS") {
      setShowSummary(false);
    }
  }, [localStorage.getItem("MENU")]);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const columns = [
    {
      dataField: "sl_no",
      text: "Index no.",
      sort: true,
      isFilterable: false,
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "id",
      text: "User ID",
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      isFilterable: true,
      type: "input",
      // editCellStyle: (cell, row, rowIndex, colIndex) => {
      //   return { backgroundColor: 'green' };
      // }
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
      isFilterable: true,
      type: "input",
      headerStyle: () => {
        return { width: "137px" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      isFilterable: true,
      type: "input",
    },
    {
      dataField: "gender",
      text: "Gender",
      isFilterable: true,
      isMulti: false,
      type: "dropDown",
      headerStyle: () => {
        return { width: "80px" };
      },
      editCellStyle: (cell, row, rowIndex, colIndex) => (
        <div style={{ textTransform: "capitalize !important" }}>{cell}</div>
      ),
    },
    {
      dataField: "dob",
      text: "DOB",
      isFilterable: true,
      type: "datePicker",
    },
    {
      dataField: "is_active",
      text: "Status",
      isFilterable: true,
      isMulti: false,
      type: "dropDown",
    },
    {
      dataField: "viewMore",
      text: "",
      isFilterable: false,
    },
  ];

  function fetchCustomers(filterFormPayload = new FormData(), setApplyFiltersFalseFn, searchTerm, applyFilter) {
    setLoader(true);
    if (searchTerm && filterFormPayload) {
      filterFormPayload.append('search_string', searchTerm);
    }
    const apiLinkUrl = process.env.REACT_APP_BASE_URL +
      "/medpacks_admin/get_all_users?page_no=" +
      (applyFilter ? '1' : pageNo) +
      "&page_count=" +
      totalItemToBeFetched;
    axios({
      method: "POST",
      url: apiLinkUrl,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
      data: filterFormPayload,
    })
      .then(function (response) {
        setLoader(false);
        setTotalItems(response.data.total_count);
        // setApplyFiltersFalseFn(false);
        if (response.data.status === 200) {
          setCustomers([]);
          // customize data
          response.data.data.map((e, i) => {
            let updatedata = {
              sl_no: i + 1,
              id: e.id,
              name: e.name,
              phone_number: e.phone_number,
              email: e.email,
              gender: e.gender,
              dob: e.dob,
              phone_number: (
                <>
                  {e?.phone_number?.slice(0, 4) + " " + e?.phone_number?.slice(4)}
                </>
              ),
              viewMore: (
                <button
                  className="btn"
                  style={{
                    fontSize: "0.6rem",
                    color: "#727cf5",
                    fontWeight: "500",
                  }}
                  onClick={() =>
                  // navigate(`/userSummary/${e.id}`, {
                  //   state: { order: { ...e } },
                  // })
                  {
                    navigate(`/summary`, {
                      state: { order: { ...e } },
                    });
                    setShowSummary(true);
                    setSelectedCustomer(e);
                  }
                  }
                >
                  View Details
                </button>
              ),
              is_active: (
                <ToggleCheck
                  value={e.is_active}
                  id={e.id}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  token={"Token " + sessionStorage.getItem("token")}
                  url="/medpacks_admin/disable_user/"
                  method={"DELETE"}
                />
              ),
            };
            setCustomers((oldArray) => [...oldArray, updatedata]);
          });
          if (setApplyFiltersFalseFn) {
            setApplyFiltersFalseFn(false);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  }

  useEffect(() => {
    fetchCustomers(toFormData(filters));
  }, [pageNo, totalItemToBeFetched]);

  const rowStyle = { cursor: "pointer", padding: "0 !important" };

  return !showSummary ? (
    <>
      {/* filter button */}
      {/* <div
        className="w-100 pb-3 d-flex justify-content-end"
        style={{ marginTop: "-50px" }}
      >
        <button className="btn btn-primary" onClick={toggleDrawer}>
          <i className="uil-filter"></i> Filter Data
        </button>
      </div> */}

      {/* table data */}
      <CommonTable
        data={customers}
        columns={columns}
        maxRow={10}
        loader={loader}
        rowStyle={rowStyle}
        searchKey="name"
        tabLabel="All Customers"
        totalItems={totalItems}
        totalItemToBeFetched={totalItemToBeFetched}
        setTotalItemToBeFetched={setTotalItemToBeFetched}
        pageNo={pageNo}
        url={process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/get_all_users?page_no=" +
          pageNo +
          "&page_count=" +
          totalItemToBeFetched}
        setPageNo={setPageNo}
        onFilter={fetchCustomers}
      />

      {/* right drawer */}
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        duration={200}
        className="CommonDrawer w-25"
      >
        <div className="rightbar-title">
          <a
            href="javascript:void(0);"
            className="right-bar-toggle float-right"
            onClick={toggleDrawer}
          >
            <i className="dripicons-cross noti-icon"></i>
          </a>
          <h5 className="m-0">
            <i className="uil-filter"></i> Filtter Customers
          </h5>
        </div>
        <div className="rightbar-content h-100 px-3" data-simplebar>
          <CustomersFilter />
        </div>
      </Drawer>
    </>
  ) : (
    <Profile
      selectedCustomer={selectedCustomer}
      setShowSummary={setShowSummary}
    />
  );
};

export default withRouter(Customers);
