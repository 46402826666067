import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { return400Error } from "../../Common/Errors";

const LoyaltyPointSetting = () => {
  const [FormData, setFormdata] = useState({
    qad_value: "",
    point_value: "",
    expiry_in_number_of_days: "",
  });
  const [actionbtntxt, setactionbtntxt] = useState("SET");
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_loyalty_conversion_rate",
        {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          setData(res.data.data);
        } else {
          toast.warning(res.data.message);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          if (error.response?.status === 400) {
            if (
              error.response?.data &&
              error.response?.data["data Request failed"]
            ) {
              toast.error(
                return400Error(error.response?.data["data Request failed"])
              );
            } else if (error.response?.data && error.response?.data.message) {
              toast.error(error.response.data.message.toString());
            }
          } else {
            toast.error(error.toString());
          }
        }
      });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...FormData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setactionbtntxt("SETTING...");
    axios
      .post(
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/update_loyalty_conversion_rate",
        FormData,
        {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setactionbtntxt("SET");
        if (res.data.status === 200) {
          fetchData();
          setFormdata({
            qad_value: "",
            point_value: "",
            expiry_in_number_of_days: "",
          });
          toast.success(res.data.message);
        } else {
          toast.warning(res.data.message);
        }
      })
      .catch((error) => {
        setactionbtntxt("SET");
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          if (error.response?.status === 400) {
            if (
              error.response?.data &&
              error.response?.data["data Request failed"]
            ) {
              toast.error(
                return400Error(error.response?.data["data Request failed"])
              );
            } else if (error.response?.data && error.response?.data.message) {
              toast.error(error.response.data.message.toString());
            }
          } else {
            toast.error(error.toString());
          }
        }
      });
  };

  return (
    <div className="row px-3">
      <div className="col-4">
        <div className={`card border`}>
          <div className="card-body">
            <h5 className={`text-center mb-2 pb-1 text-uppercase`}>
              SET LOYALTY POINT
            </h5>
            <form id="form" onSubmit={handleSubmit}>
              <input
                className="form-control"
                type="number"
                name="qad_value"
                placeholder="QAR Value"
                required
                defaultValue={FormData?.qad_value}
                onChange={handleOnChange}
              />
              <input
                className="form-control mt-2"
                type="number"
                name="point_value"
                placeholder="Point value"
                required
                defaultValue={FormData?.point_value}
                onChange={handleOnChange}
              />
              <input
                className="form-control mt-2"
                type="number"
                name="expiry_in_number_of_days"
                placeholder="expiry in number of days"
                required
                defaultValue={FormData?.expiry_in_number_of_days}
                onChange={handleOnChange}
              />
              <button
                type="submit"
                className="btn btn-primary px-3 py-1 mt-3 w-100"
                disabled={actionbtntxt === "SETTING..."}
              >
                {actionbtntxt}
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="col-2"></div>
      <div className="col-6">
        <div className={`card border`}>
          <div className="card-body">
            <h4>Conversion ratio (Deducted)</h4>
            -------------------------------------------
            <h6>1 QAR spent = {data?.one_qad_equivalent} Loyalty Point</h6>
            <br />
            <h4>Spending ratio (Credited)</h4>
            -------------------------------------------
            <h6>100 Loyalty Point = {data?.qad_value} QAR</h6>
            <br />
            <h6>Point value - {data?.point_value}</h6>
            <h6>Expiry in - {data?.expiry_in_number_of_days} days</h6>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoyaltyPointSetting;
