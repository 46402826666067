import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommonTable from '../../Common/CommonTable';
import { return400Error } from '../../Common/Errors';
import ToggleCheck from '../../Common/ToggleCheck';
import moment from 'moment';
import EditStaticPage from './EditStaticPage';

const CreateStaticPages = ({

}) => {
  const [showSummary, setShowSummary] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (window.location.href.includes("edit-static-pages")) {
      setShowSummary(true);
      localStorage.setItem("MENU", "EDIT-PAGE");
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("all-static-pages")) {
      setShowSummary(false);
      localStorage.setItem("MENU", "FETCH-PAGE");
    }
  }, [window.location.href]);

  useEffect(() => {
    if (localStorage.getItem("MENU") === "FETCH-PAGE") {
      setShowSummary(false);
    }
  }, [localStorage.getItem("MENU")]);

  const fetchStaticPages = () => {
    setLoader(true);
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/all_static_pages?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoader(false);
        setTotalItems(response.data.total_count);
        if (response.data.status === 200) {
          setData(response.data.data.map((e, i) => {
            return {
              ...e,
              page_title: e.page_title.slice(e.page_title.lastIndexOf(';') + 1),
              viewMore: (
                <button
                  className="btn"
                  style={{
                    fontSize: "0.6rem",
                    color: "#727cf5",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    setShowSummary(true);
                    navigate(`/edit-static-pages`, {
                      state: { summary: { ...e } },
                    });
                  }
                  }
                >
                  View Details
                </button>
              ),
              is_active: (
                <ToggleCheck
                  value={e.is_active}
                  id={e.id}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  token={"Token " + sessionStorage.getItem("token")}
                  url="/medpacks_admin/update_static_page/"
                  method={"DELETE"}
                  onSuccess={fetchStaticPages}
                />
              ),
              created_on: (
                <span>
                  {moment(e?.created_on).format("DD MMM YYYY") || "_"}
                </span>
              ),
              modified_on: (
                <span>
                  {moment(e?.modified_on).format("DD MMM YYYY") || "_"}
                </span>
              ),
            };
          }));
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  }

  useEffect(() => {
    fetchStaticPages();
  }, [pageNo, totalItemToBeFetched]);

  const columns = [
    {
      dataField: "id",
      text: "Page Id",
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "page_title",
      text: "Page Title",
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: "created_on",
      text: "Created On",
      isFilterable: true,
      type: "datePicker",
    },
    {
      dataField: "modified_on",
      text: "Modified On",
      isFilterable: true,
      type: "datePicker",
    },
    {
      dataField: "is_active",
      text: "Status",
      isFilterable: true,
      isBoolean: true,
      type: "dropDown",
    },
    {
      dataField: "viewMore",
      text: "",
      isFilterable: false,
    },
  ];

  return (
    <>
      {!showSummary
        ?
        <>
          <div className='row'>
            <span className='col-12'>
              <button
                className='btn btn-primary my-2 mx-2'
                onClick={() => {
                  setShowSummary(true);
                  navigate(`/edit-static-pages`, {
                    state: { summary: {} },
                  });
                }}
              >
                <i class="mdi mdi-plus-circle mr-2"></i> Create Static Pages
              </button>
            </span>
          </div>
          {data.length > 0 && <CommonTable
            data={data}
            columns={columns}
            maxRow={10}
            loader={loader}
            totalItems={totalItems}
            totalItemToBeFetched={totalItemToBeFetched}
            setTotalItemToBeFetched={setTotalItemToBeFetched}
            pageNo={pageNo}
            setPageNo={setPageNo}
            enableFESearchFilter={true}
          />}
        </>
        : <EditStaticPage
          setShowSummary={setShowSummary}
          onSuccess={fetchStaticPages}
        />
      }

    </>
  );
}

export default CreateStaticPages;