export const wishlistColumns = [
    {
        dataField: "name",
        text: "WishList",
        type: 'input',
        isFilterable: true,
    },
    {
        dataField: "created_on",
        text: "Created On",
        sort: true,
        type: 'datePicker',
        isFilterable: true,
    },
    {
        dataField: "viewMore",
        text: "",
        sort: true,
        isFilterable: false,
    },
];

export const orderColumns = [
    {
        dataField: "details.order_id",
        text: "Medpack Order Id",
        filterLabel: 'medpack_id',
        type: 'input',
        isFilterable: true,
        headerStyle: () => {
            return { width: '100px' }
        },
    },
    {
        dataField: "details.order_number",
        text: "Safemed Order No",
        filterLabel: 'safemed_id',
        type: 'input',
        isFilterable: true,
        headerStyle: () => {
            return { width: '100px' }
        },
    },
    {
        dataField: "details.order_placed_date",
        text: "Date",
        type: 'datePicker',
        isFilterable: true,
        dateFilterLabels: ['created_on_from', 'created_on_to']
    },
    {
        dataField: "productSize",
        text: "Products",
        sort: true,
        // type: 'input',
        isFilterable: false,
        headerStyle: () => {
            return { width: '76px' }
        },
    },
    {
        dataField: "payment_status",
        text: "Payment",
        sort: true,
        type: 'dropDown',
        isFilterable: true,
    },
    {
        dataField: "status",
        text: "Fullfillment Status",
        filterLabel: 'order_status',
        sort: true,
        type: 'input',
        isFilterable: true,
        style: () => {
            return { textAlign: 'left' }
        },
    },
    {
        dataField: "details.total_amount",
        text: "Total",
        sort: true,
        type: 'input',
        isFilterable: false,
        headerStyle: () => {
            return { width: '150px' }
        },
    },
    {
        dataField: "viewMore",
        text: "",
        sort: true,
        isFilterable: false,
    },
];

export const prescriptionColumns = [
    {
        dataField: "name",
        text: "Prescription",
        type: 'input',
        isFilterable: true,
    },
    {
        dataField: "created_on",
        text: "Uploaded On",
        sort: true,
        type: 'datePicker',
        isFilterable: true,
        dateFilterLabels: ['created_on_from', 'created_on_to']
    },
    {
        dataField: "number_of_products_ordered",
        text: "Order(s) Placed",
        sort: true,
        type: 'input',
        isFilterable: true,
    },
    {
        dataField: "file",
        text: "File",
        sort: true,
        // type: 'input',
        isFilterable: false,
    },
    {
        dataField: "is_active",
        text: "Active",
        sort: true,
        type: 'dropDown',
        isFilterable: true,
    },
    {
        dataField: "active_inactive_status",
        text: "Status",
        sort: true,
        // type: 'dropDown',
        isFilterable: false,
    },
];
