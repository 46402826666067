import axios from "axios";
import { useState } from "react";
import Drawer from "react-modern-drawer";
import { toast } from "react-toastify";
import { return400Error } from "./Errors";
import CountryCodes from "../Common/CountryCodes.json"

const EditData = ({ data, sendSuccesResponse, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const [btnDisable, setBtnDisable] = useState(false);
  const [countryCode, setCountryCode] = useState('');

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };


  // update data
  const handleSubmit = (e) => {
    e.preventDefault();
    if (countryCode !== data?.country_code) {
      setBtnDisable(true);
      axios
        .put(
          process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/update_phone_codes/" +
          data?.id,
          {
            country_code: countryCode,
            is_active: true,
          },
          {
            headers: {
              Authorization: "Token " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          setBtnDisable(false);
          sendSuccesResponse(res.data.status)
          toast.success(res.data.message);
          setIsOpen(false);
        })
        .catch(function (error) {
          setBtnDisable(false);
          return400Error(error);
        });
    } else {
      toast.error("Change code to update");
    }
  };

  return (
    <>
      <i
        className="uil-edit-alt"
        style={{
          cursor: "pointer",
          fontSize: "20px",
        }}
        onClick={handleClick}
      ></i>

      {/* right drawer */}
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        duration={200}
        className="CommonDrawer w-25"
      >
        <div className="rightbar-title">
          <a
            href="javascript:void(0);"
            className="right-bar-toggle float-right"
            onClick={toggleDrawer}
          >
            <i className="dripicons-cross noti-icon"></i>
          </a>
          <h5 className="m-0">
            <i className="uil-edit-alt"></i> Edit Phone Code
          </h5>
        </div>
        <div className="rightbar-content h-100 px-3" data-simplebar>
          {/* Edit form */}
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="form-group">
              <label htmlFor="country_code">Phone Code</label>
              {/* <input
                className="form-control"
                type="text"
                name="country_code"
                required
                value={countryCode || data.country_code}
                id="country_code"
                onChange={(event) => {
                  const isValid = /^[0-9\W_]*$/.test(event.target.value);
                  if (isValid) {
                    setCountryCode(event.target.value);
                  }
                  return false;
                }}
              /> */}

              <select
                name="country_code"
                id="country_code"
                className="form-control"
                value={countryCode || data.country_code}
                onChange={(event) => setCountryCode(event.target.value)}
              >
                <option value="">Select country code</option>
                {
                  CountryCodes.map((e, i) => <option key={i} value={e.dial_code}>{e.name} ({e.dial_code})</option>)
                }
              </select>
            </div>

            <div className="form-group mb-0 text-center">
              <button
                className="btn btn-primary"
                disabled={btnDisable}
                type="submit"
              >
                {" "}
                {"Update"}{" "}
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default EditData;
