import { toast } from "react-toastify";

const return400Error = (error) => {
  const render400Error = (err) => {
    return (
      <ul style={{ listStyleType: "none" }}>
        {Object.entries(err).map((x, i) => (
          <li key={i} className="mb-2">
            <h6 style={{ textTransform: 'capitalize' }}>{x[0] === 'terms_and_conditions' ? 'Terms & Condtions is required field' : x[0]}</h6>{x[0] === 'terms_and_conditions' ? '' : ': '}{x[0] === 'terms_and_conditions' ? '' : x[1]}
          </li>
        ))}
      </ul>
    );
  };
  if (error.response?.status === 400) {
    if (error.response?.data && (error.response?.data["data Request failed"] || error.response?.data.data)) {
      toast.error(render400Error(error.response?.data["data Request failed"] || error.response?.data.data));
    } else if (error.response?.data && error.response?.data.message) {
      toast.error(error.response.data.message.toString());
    }
  } else {
    toast.error(error.toString());
  }
};

export { return400Error };
