import axios from 'axios';
import JoditEditor from 'jodit-react';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import CommonTable from '../../Common/CommonTable';
import '../styles/doctorPanel.scss';
import FAQStatus from './FAQStatus';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import ProductSequence from '../Consumer/ProductSequence';
import { return400Error } from '../../Common/Errors';
import { toQueryParams } from '../Consumer/helpers/filtersHelpers';
import { FiltersContext } from '../../Common/FiltersProvider';


const FAQ = () => {
  const { state: { filters } } = useContext(FiltersContext);
  const [data, setData] = useState([
    {
      "question": "FAQ 1",
      "answer": "Answer 1",
      "sequence": 2,
      "status": "DRAFT",
      "faq_id": 1
    },
    {
      "question": "FAQ 2",
      "answer": "Answer 2",
      "sequence": 1,
      "status": "DRAFT",
      "faq_id": 2
    },
    {
      "question": "FAQ 3",
      "answer": "Answer 3",
      "sequence": 3,
      "status": "DRAFT",
      "faq_id": 3
    }
  ]);
  const [loader, setLoader] = useState(true);
  const [valid, setIsValid] = useState(false);
  const [status, setStatus] = useState('DRAFT');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [showAddFaq, setShowAddFAQ] = useState(false);

  const fetchFAQsList = (filterFormPayload, setApplyFiltersFalseFn, searchTerm) => {
    setLoader(true);
    const apiLinkUrl = process.env.REACT_APP_BASE_URL + "/medpacks_admin/faq";
    axios({
      method: "GET",
      url: searchTerm
        ? apiLinkUrl +
        "?search_string=" +
        searchTerm
        : apiLinkUrl + (filters && Object.keys(filters).length > 0 ? '?' + toQueryParams(filters) : ''),
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.status === 200) {
          setData(response.data.data.map(e => ({
            ...e,
            answer: (
              <>
                <a id={"tooltip-anchor" + e.id} data-tooltip-html={e.answer} style={{ cursor: 'pointer', fontSize: '16px' }}> <i className='mdi mdi-comment-text-outline' /> </a>
                <Tooltip anchorId={"tooltip-anchor" + e.id} place="top" style={{ margin: 'auto', minHeight: '50px', maxWidth: '200px' }} />
              </>
            ),
            sequence: (
              <ProductSequence
                id={e.id + '_sequence'}
                sequence={e.sequence}
                onChange={async (event) => {
                  if (event.target.value?.length > 0) {
                    const formData = new FormData();
                    formData.set('question', question || e.question);
                    formData.set('answer', answer || e.answer);
                    formData.set('status', status || e.status);
                    formData.set('sequence', event.target?.value || e.sequence);
                    e?.id && formData.set('faq_id', e.id);
                    await axios
                      .post(
                        process.env.REACT_APP_BASE_URL +
                        "/medpacks_admin/faq",
                        formData,
                        {
                          headers: {
                            Authorization:
                              "Token " + sessionStorage.getItem("token"),
                          },
                        }
                      )
                      .then((res) => {
                        toast.success(res.data.message);
                        setLoader(true);
                        fetchFAQsList(filters);
                      })
                      .catch((err) => {
                        const { status, message } = err.response.data.data;
                        if (status === "400") {
                          sessionStorage.clear()
                          window.location = "/";
                          toast.error(message);
                        }
                        else {
                          return400Error(err);
                        }
                      });
                  }
                }}
              />
            ),
            edit: (
              <button
                className='btn'
                onClick={() => {
                  setSelectedItem(e);
                  setShowAddFAQ(true);
                }}
              >
                <i className='mdi mdi-square-edit-outline' />
              </button>
            ),
            delete: (
              <button
                className='btn'
                onClick={() => {
                  deleteFAQResponse(e);
                }}
              >
                <i className='mdi mdi-delete' />
              </button>
            ),
            faq_status: (
              <FAQStatus
                e={e}
                onSuccess={() => fetchFAQsList(filters)}
                setLoader={setLoader}
              />
            )
          })));
          if (setApplyFiltersFalseFn) {
            setApplyFiltersFalseFn(false);
          }
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  }

  useEffect(() => {
    if (selectedItem && selectedItem.status) {
      setStatus(selectedItem.status);
    }
  }, [selectedItem]);

  const submitFAQResponse = () => {
    const formData = new FormData();
    formData.set('question', question || selectedItem.question);
    formData.set('answer', answer || selectedItem.answer);
    console.log('>>', selectedItem, status);
    formData.set('status', status);
    selectedItem?.id && formData.set('faq_id', selectedItem.id);

    axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/faq",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
      data: formData,
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setLoader(true);
          setShowAddFAQ(false);
          window.location = "/faq"
        } else if (response.data.status === 400) {
          setLoader(false);
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        const { status, message, data } = error.response.data;
        setLoader(false);
        if (Number(status) === 400) {
          Object.keys(data).forEach((field) => {
            toast.warning(field + " - " + data[field][0])
          })
        }
        else {
          toast.error(message)
        }
      });
  }

  const deleteFAQResponse = (e) => {
    axios({
      method: "DELETE",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/faq/" + e.id,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setLoader(true);
          fetchFAQsList(filters);
        } else if (response.data.status === 400) {
          setLoader(false);
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  }

  const validate = () => {
    if ((question.length > 0 && answer.length > 0) || (selectedItem && selectedItem.question?.length > 0 && selectedItem.answer?.length > 0)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  useEffect(() => {
    fetchFAQsList(filters);
  }, []);

  useEffect(() => {
    validate();
  }, [question, answer, status]);

  const columns = [
    {
      dataField: "id",
      text: "FAQ ID",
      headerStyle: () => {
        return { width: "80px" };
      },
      filterLabel: 'faq_id',
      isFilterable: true,
      type: 'input',
      sort: true,
    },
    {
      dataField: "sequence",
      text: "Sequence",
      headerStyle: () => {
        return { width: "82px" };
      },
      isFilterable: false,
      type: 'input',
      sort: true,
    },
    {
      dataField: "question",
      text: "Question",
      isFilterable: true,
      type: 'input',
    },
    {
      dataField: "answer",
      text: "Answer",
      isFilterable: false,
      type: 'input',
    },
    {
      dataField: "faq_status",
      text: "Status",
      isFilterable: true,
      type: 'dropDown'
    },
    {
      dataField: "edit",
      text: "Edit",
    },
    {
      dataField: "delete",
      text: "Delete",
    },
  ];

  const config = {
    readonly: false,
    placeholder: 'Start typings...'
  };

  return (
    <>
      <div>
        {!showAddFaq && <div className="row">
          <div className='mb-2'>
            <button
              className='btn btn-primary'
              onClick={() => setShowAddFAQ(true)}
            >
              <i class="mdi mdi-plus-circle"></i> Add FAQ
            </button>
          </div>
          <div>
            <CommonTable
              columns={columns}
              data={data}
              loader={loader}
              maxRow={10}
              url={process.env.REACT_APP_BASE_URL + "/medpacks_admin/faq"}
              onFilter={fetchFAQsList}
            />
          </div>
        </div>}
        {showAddFaq &&
          <div class="faq">
            <button
              className="btn btn-outline-primary mb-2"
              style={{ marginLeft: '94%' }}
              onClick={() => {
                setShowAddFAQ(false);
                setStatus('DRAFT');
                setQuestion('');
                setAnswer('');
                setSelectedItem({});
              }
              }
            >
              Back
            </button>
            <input
              className='form-control w-100 mb-2'
              name='question'
              placeholder='FAQ Question'
              onChange={(e) => setQuestion(e.target.value)}
              defaultValue={question || selectedItem.question}
            />
            <JoditEditor
              value={answer || selectedItem.answer}
              config={config}
              // tabIndex={1}
              onBlur={newContent => setAnswer(newContent)}
            />
            <div className='mt-2'>
              <div className={`custom-control custom-switch mb-1`}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  defaultChecked={selectedItem?.status === 'PUBLISH'}
                  id={'status'}
                  name={status}
                  onClick={(e) => setStatus(() => {
                    console.log('>>', e.target.name);
                    return e.target.name === 'DRAFT' ? 'PUBLISH' : 'DRAFT';
                  })}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"status"}
                >{status}</label>
              </div>
            </div>
            <div className='d-flex justify-content-end mt-2'>
              <button
                className='btn btn-primary mr-2'
                onClick={submitFAQResponse}
                disabled={!valid}
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default FAQ;