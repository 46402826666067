import axios from 'axios';
import * as React from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { toast } from 'react-toastify';
import Table from '../../../../Common/Table';
import { renderProductListComponent, TABS, transformData } from './utilities';
import PrescriptionProduct from './PrescriptionProducts';
import CommonTable from '../../../../Common/CommonTable';
import { return400Error } from '../../../../Common/Errors';
import { toFormData } from '../../../Consumer/helpers/filtersHelpers';
import { FiltersContext } from '../../../../Common/FiltersProvider';

const CommonPanel = ({
  url,
  ID,
  columns,
  tab,
  searchKey,
}) => {
  const { state: { filters }, dispatch } = React.useContext(FiltersContext);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showProductsList, setShowProductsList] = useState(false);
  const [row, setRow] = useState({});
  const [image, setImage] = useState({});

  function fetchTabData(filterFormPayload = new FormData(), setApplyFiltersFalseFn, searchTerm) {
    setLoader(true);
    if (searchTerm && filterFormPayload) {
      filterFormPayload.append('search_string', searchTerm);
    }
    const apiLinkUrl = process.env.REACT_APP_BASE_URL + url;
    filterFormPayload.append('customer_id', ID);
    axios({
      method: "POST",
      url: apiLinkUrl,
      data: filterFormPayload,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoader(false);
        if (response.status === 200) {
          setData(transformData(response.data.data, tab, setShowProductsList, setRow, setImage, image, fetchTabData, filters));
          if (setApplyFiltersFalseFn) {
            setApplyFiltersFalseFn(false);
          }
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        // setLoader(false);
        return400Error(error);
      });
  }
  useEffect(() => {
    dispatch({
      type: 'reset-filters'
    });
    fetchTabData();
  }, []);

  return (
    <>
      {!showProductsList
        ? <CommonTable
          columns={columns}
          data={data}
          maxRow={10}
          loader={loader}
          searchKey={searchKey || 'name'}
          tabLabel={`All ${tab}`}
          filterData={tab === TABS.ORDERS && data.map(x => ({ ...x, ...x.details }))}
          url={process.env.REACT_APP_BASE_URL + url}
          onFilter={fetchTabData}
        />

        : tab !== TABS.PRESCRIPTIONS && renderProductListComponent(row, tab, setShowProductsList, setRow, setData, data)
      }
      <PrescriptionProduct
        image={image} />
    </>
  )
}

export default CommonPanel;