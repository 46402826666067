import axios from "axios";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "./FirebaseConfig";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const Login = () => {
  const navigate = useNavigate();
  // const [sendOtp, setSendOtp] = useState("Send OTP");
  // const [verifyOtp, setVerifyOtp] = useState("Verify OTP");
  // const [countryCode, setCountryCode] = useState();
  // const [phoneCountryCode, setPhoneCountryCode] = useState();
  // const [phone, setPhone] = useState();
  // const [otp, setOtp] = useState();

  // new code
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [confirmationObj, setConfirmationObj] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [phoneCountryCode, setPhoneCountryCode] = useState(null);
  const [otpActionBtnTxt, setOtpActionBtnTxt] = useState("Verify OTP");
  const [submitbtntxt, setSubmitbtntxt] = useState("Send OTP");

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      navigate("/auth");
    } else {
      navigate("/");
    }
  }, []);

  const [enterOTP, setEnterOTP] = useState(false);

  // const submitPhone = (event) => {
  //   event.preventDefault();
  //   if (phone.length === 8) {
  //     setSendOtp("Sending...");

  //     axios({
  //       method: "POST",
  //       url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/admin_login",
  //       data: {
  //         phone_number: phone,
  //         country_code: countryCode,
  //       },
  //     })
  //       .then(function(response) {
  //         if (response.data.status === 200) {
  //           toast.success(response.data.message);
  //           setEnterOTP(true);
  //         } else if (response.data.status === 400) {
  //           toast.error(response.data.message);
  //         } else {
  //           toast.error(response.data.message);
  //         }
  //         setSendOtp("Send OTP");
  //       })
  //       .catch(function(error) {
  //         setSendOtp("Send OTP");
  //         if (error.response.data.status === 0) {
  //           toast.warning(error.response.data.message);
  //         } else {
  //           toast.error(error.toString());
  //         }
  //       });
  //   } else {
  //     toast.warning("Please enter a 8 digit valid number");
  //   }
  // };

  const sendOTP = () => {
    setSubmitbtntxt("Sending...");
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    signInWithPhoneNumber(auth, phoneCountryCode, recaptchaVerifier)
      .then((confirmationResult) => {
        setConfirmationObj(confirmationResult);
        setOtpSent(true);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const verifyOTP = () => {
    setOtpActionBtnTxt("Verifying...");
    confirmationObj
      .confirm(otp)
      .then(async (response) => {
        submitOtpToServer();
      })
      .catch((err) => {
        setOtpActionBtnTxt("Verify OTP");
        toast.error("Invalid OTP");
      });
  };

  const submitOtpToServer = () => {
    // const formData = new FormData(event.target);
    axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/admin_verify_otp",
      data: {
        phone_number: phone,
        otp,
      },
    })
      .then(function(response) {
        if (response.data.status === 200) {
          setOtpActionBtnTxt("Verify OTP");
          toast.success(response.data.message);
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem(
            "user",
            btoa(JSON.stringify(response.data.user_details))
          );
          navigate("/country-code");
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function(error) {
        toast.error(error.toString());
      });
  };

  return (
    <div className="account-pages mt-5 mb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card">
              <div className="card-header py-3 text-center bg-dark">
                <Link to="/">
                  <span>
                    <img src={logo} alt={logo} height="50" />
                  </span>
                </Link>
              </div>

              {enterOTP ? null : (
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                      Sign In
                    </h4>
                    <p className="text-muted mb-4">
                      Enter your phone number to access admin panel.
                    </p>
                  </div>

                  {/* <form onSubmit={submitPhone}>
                    <PhoneInput
                      inputStyle={{
                        width: "100%",
                        height: "40px",
                      }}
                      defaultMask={'.... ....'}
                      onChange={(phoneNumber, countryCodeObj) => {
                        setPhone(
                          phoneNumber.substring(countryCodeObj.dialCode.length)
                        );
                        setCountryCode(countryCodeObj.dialCode);
                        setPhoneCountryCode(phoneNumber);
                      }}
                      country="qa"
                      value={phoneCountryCode}
                    />

                    <div className="form-group mb-0 text-center mt-3">
                      <button className="btn btn-primary" type="submit">
                        {" "}
                        {sendOtp}{" "}
                      </button>
                    </div>
                  </form> */}

                  {/* new code start */}
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                    }}
                    country="qa"
                    // onlyCountries={["qa"]}
                    defaultMask={".... ...."}
                    countryCodeEditable={false}
                    value={phoneCountryCode}
                    onChange={(phoneNumber, countryCodeObj) => {
                      setPhone(
                        phoneNumber?.substring(countryCodeObj?.dialCode?.length)
                      );
                      setCountryCode(`+${countryCodeObj?.dialCode}`);
                      setPhoneCountryCode(`+${phoneNumber}`);
                    }}
                  />
                  {otpSent ? null : (
                    <div
                      id="recaptcha-container"
                      className="d-flex justify-content-center my-3 mb-0"
                    />
                  )}
                  {otpSent ? (
                    <>
                      <div className="my-4 w-100 d-flex justify-content-center">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          separator={<span>&nbsp;&nbsp;</span>}
                          isInputNum={true}
                          inputStyle={{
                            width: "45px",
                            height: "45px",
                          }}
                          focusStyle={{
                            outline: "red",
                          }}
                        />
                      </div>
                      <div className="mb-3 mb-0 text-center">
                        {/* <Button className="btn btn-primary w-100" onClick={verifyOTP} label="Verify OTP" /> */}
                        <button
                          className="btn btn-primary"
                          onClick={verifyOTP}
                          disabled={otpActionBtnTxt === "Verifying..."}
                        >
                          {otpActionBtnTxt}
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="mb-3 mb-0 text-center">
                      {/* <Button className="btn btn-primary w-100" onClick={sendOTP} label="Send OTP" /> */}
                      <button
                        className="btn btn-primary"
                        onClick={sendOTP}
                        label="Send OTP"
                        disabled={submitbtntxt === "Sending..."}
                      >
                        {submitbtntxt}
                      </button>
                    </div>
                  )}
                  {/* new code start */}
                </div>
              )}

              {/* {enterOTP ? (
                <form onSubmit={submitOtp}>
                  <div className="mb-4 text-center py-4">
                    <label className="form-label">Enter OTP</label>
                    <p className="text-muted mb-4">
                      OTP has been sent to your entered mobile number
                    </p>
                    <input type="hidden" value={phone} name="phone_number" />
                    <input type="hidden" value={otp} name="otp" />
                    <div className="otp-container">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        isInputNum={true}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="form-group mb-4 text-center">
                      <button className="btn btn-primary" type="submit">
                        {" "}
                        {verifyOtp}{" "}
                      </button>
                    </div>
                  </div>
                </form>
              ) : null} */}
            </div>

            {/* <div className="row mt-3">
              <div className="col-12 text-center">
                <p className="text-muted">
                  Don't have an account?{" "}
                  <a href="pages-register.html" className="text-muted ml-1">
                    <b>Sign Up</b>
                  </a>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
