import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { return400Error } from '../../Common/Errors';

const AddImage = ({
    selectedItem,
    onSuccess,
    setLoader,
    setSelectedItem,
}) => {
    const [image, setImage] = useState('');
    const [imageData, setImageData] = useState('');
    const [imageError, setImageError] = useState(false);
    const [fileType, setFileType] = useState('');
    const [fileSize, setFileSize] = useState('');

    const handleAddImage = () => {
        const formData = new FormData();
        formData.append('image', imageData);
        formData.append('url', process.env.REACT_APP_BASE_URL);
        selectedItem?.sequence && formData.append('sequence', selectedItem?.sequence);
        axios({
            method: selectedItem?.id ? "PUT" : "POST",
            url: selectedItem?.id ? process.env.REACT_APP_BASE_URL + "/medpacks_admin/update_login_page_banner/" + selectedItem.id : process.env.REACT_APP_BASE_URL + "/medpacks_admin/save_login_page_banner",
            headers: {
                Authorization: "Token " + sessionStorage.getItem("token"),
            },
            data: formData,
        })
            .then(function (response) {
                if (response.data.status === 200) {
                    setImage('');
                    setImageError('');
                    toast.success(response.data.message);
                    setLoader(true);
                    onSuccess();
                    setSelectedItem();
                }
            })
            .catch(function (error) {
                return400Error(error);
            });
    };

    return (
        <div class="modal fade" tabindex="-1" role="dialog" id='add-user-image-modal' data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{selectedItem?.id ? 'Edit' : 'Add'} Image</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                            setImage('');
                            setImageError('');
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div className='container'>
                            <div className='row' style={{ margin: '15px 0' }}>
                                <input
                                    type="file"
                                    className="form-control p-1"
                                    accept="image/*, .pdf"
                                    id="myFile"
                                    name="myImage"
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files[0]) {
                                            let file = e.target.files[0];
                                            setFileSize(file.size);
                                            setFileType(file.type);
                                            if (['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].some(x => x === file?.type) && Number((file.size / 1048576).toFixed(0)) <= 10) {
                                                setImageData(file);
                                                setImage(URL.createObjectURL(file));
                                                setImageError(false);
                                            } else {
                                                setImageError(true);
                                                setImageData('');
                                                setImage('');
                                            }
                                        }
                                        e.target.value = null;
                                    }} />
                                <span className='mt-2' style={{ fontStyle: 'italic', color: imageError ? 'red' : 'unset' }}>Please upload only .png, .jpg, .jpeg, .pdf files and size of maximum 10MB</span>
                            </div>
                            {(image || selectedItem?.image) && <div className="row mt-2">
                                <img
                                    style={{ margin: 'auto' }}
                                    id="img"
                                    src={(selectedItem?.image && !image) ? selectedItem.url + selectedItem.image : image}
                                    height={300}
                                    width={400}
                                    alt={image.toString()}
                                />
                            </div>}
                            {(image || imageError) && selectedItem && Object.keys(selectedItem).length === 0 && <div className="row  mt-4">
                                <label className="col row ml-1" style={{ color: imageError ? 'red' : 'initial' }}><label className="col">Type: {fileType}</label></label>
                                <label className="col row" style={{ color: imageError ? 'red' : 'initial' }}><label className="col">Size: {(fileSize / 1048576).toFixed(2)}MB</label></label>
                            </div>}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div className='m-auto'>
                            <button
                                type="button"
                                class="btn btn-primary mr-2"
                                data-dismiss="modal"
                                onClick={() => {
                                    handleAddImage();
                                }}
                                disabled={!imageData && Number((fileSize / 1048576).toFixed(0)) > 10}
                            >
                                {selectedItem ? 'Update' : 'Add'}
                            </button>
                            <button
                                type="button"
                                class="btn btn-secondary"
                                onClick={() => {
                                    setImage('');
                                    setImageData('');
                                    setImageError('');
                                }}
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddImage;

