import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FiltersProvider } from "./Common/FiltersProvider";
import SideMenu from "./Common/SideMenu";
import TopBar from "./Common/TopBar";
import withPermissions from "./HOCs/withPermissions";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      navigate("/auth");
    }

    // set title
    if (location.pathname === "/") {
      setTitle("My Account");
    } else if (location.pathname === "/customers") {
      setTitle("Customers");
    } else if (location.pathname === "/userSettings") {
      setTitle("Users");
    } else if (location.pathname.includes("/no-access")) {
      setTitle("User do not have required permissions to view this page");
    } else if (location.pathname === "/roles") {
      setTitle("Roles");
    } else if (location.pathname === "/orders") {
      setTitle("Orders");
    } else if (location.pathname === "/images") {
      setTitle("Image Control");
    } else if (location.pathname === "/top-products") {
      setTitle("Products");
    } else if (location.pathname === "/products-sequence  ") {
      setTitle("Products");
    } else if (location.pathname === "/country-code") {
      setTitle("Phone Code");
    } else if (location.pathname === "/categories") {
      setTitle("Categories");
    } else if (location.pathname === "/offers") {
      setTitle("Offers");
    } else if (location.pathname === "/coupons") {
      setTitle("Coupons");
    } else if (location.pathname === "/loyalty-points") {
      setTitle("Loyalty Points");
    } else if (location.pathname === "/loyalty-point-setting") {
      setTitle("Loyalty Point Setting");
    } else if (location.pathname === "/homepage-carousel") {
      setTitle("Carousel");
    } else if (location.pathname === "/top-menu") {
      setTitle("Top Menu");
    } else if (location.pathname === "/static-pages") {
      setTitle("Static Pages");
    } else if (location.pathname === "/social-media-link") {
      setTitle("Social Media Link");
    } else if (location.pathname === "/add-clinic") {
      setTitle("Add Clinic");
    } else if (location.pathname === "/blood-group") {
      setTitle("Blood Groups");
    } else if (location.pathname === "/medicine-strength") {
      setTitle("Medicine Strength");
    } if (location.pathname === "/document-types") {
      setTitle("Document Types");
    } else if (location.pathname === "/dosage-unit") {
      setTitle("Dosage Unit");
    } else if (location.pathname === "/medicine-route") {
      setTitle("Medicine Route");
    } else if (location.pathname === "/medicine-duration") {
      setTitle("Medicine Duration");
    } else if (location.pathname === "/medicine-direction") {
      setTitle("Medicine Direction");
    } else if (location.pathname === "/complaints") {
      setTitle("Complaints");
    } else if (location.pathname === "/diagnosis") {
      setTitle("Diagnosis");
    } else if (location.pathname === "/test") {
      setTitle("Tests");
    } else if (location.pathname === "/faq") {
      setTitle("Frequently asked questions");
    } else {
      setTitle(null);
    }
  }, [location.pathname]);
  return (
    <FiltersProvider>
      <div className="wrapper">
        <SideMenu />
        <div className="content-page">
          <TopBar />
          <div className="content px-1">
            <div className="row">
              {/* content title section */}
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">{/*  */}</div>
                  {/* page title */}
                  <h4 className="page-title">{title}</h4>
                </div>
              </div>

              {/* content body section */}
              <div class="col-12">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FiltersProvider>
  );
};
export default withPermissions(Index);
