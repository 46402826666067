import * as React from 'react';

const apiUrl = process.env.REACT_APP_BASE_URL;

const PrescriptionProduct = ({ image = {} }) => {
  return (
    <div class="modal" tabindex="-1" role="dialog" id='prescriptionImage' data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{image.name}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img
              src={
                apiUrl + image.image
              }
              height={300}
              width={450}
              alt={"prdct_img"}
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrescriptionProduct;