const statusOptions = [{
    label: 'Y',
    value: 'true'
},
{
    label: 'N',
    value: 'false'
},];

const statusOptionsBool = [{
    label: 'Y',
    value: true
},
{
    label: 'N',
    value: false
},];

const faqStatusOptions = [{
    label: 'Draft',
    value: 'DRAFT'
},
{
    label: 'Publish',
    value: 'PUBLISH'
},];

export const FILTER_OPTIONS = {
    gender: [
        {
            label: 'Female',
            value: 'female'
        },
        {
            label: 'Male',
            value: 'male'
        },
    ],
    is_active: statusOptions,
    featured: statusOptions,
    faq_status: faqStatusOptions,
    active_inactive_status: [
        {
            label: 'Active',
            value: 'active'
        },
        {
            label: 'Expired',
            value: 'expired'
        },
    ],
    payment_status: [
        {
            label: 'Paid',
            value: true
        },
        {
            label: 'Unpaid',
            value: false
        },
    ],
}

export const FILTER_OPTIONS_BOOL = {
    ...FILTER_OPTIONS,
    is_active: statusOptionsBool,
    featured: statusOptionsBool,
}
