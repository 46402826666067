import React from 'react';
import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

const DatePicker = ({ onDateTimeChange, className, minDate, defaultValue, name }) => {
  const [value, onChange] = useState('');

  return (
    <DateTimePicker
      clasName={className}
      onChange={(value) => { onChange(value); onDateTimeChange(value); }}
      value={value || defaultValue}
      minDate={minDate}
      disableClock={true}
      name={name}
    />
  );
}

export default DatePicker;