import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Drawer from "react-modern-drawer";
import DateFormater from "../../Common/DateFormater";
import Loader from "../../Common/Loader";
import ToggleCheck from "../../Common/ToggleCheck";
import moment from "moment";
import { return400Error } from "../../Common/Errors";
import SideModal from "./SideModal";
import ReactSelect from "react-select";
import CustomPagination from "../../Common/CustomPagination";
import { formatDate } from "../../helper/dateUtils";

const LoyaltyPoint = () => {
  const [image, setImage] = useState("");
  const [form, setFormData] = useState({});
  const [imageData, setImageData] = useState("");
  const [imageError, setImageError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [loyaltyPoint, setLoyaltyPoint] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [isOpenAddData, setIsOpenAddData] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [allcategory, setAllCategory] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [selectUser, setSelectUser] = useState(false);
  const [allsubcategory, setAllSubCategory] = useState([
    { value: "", label: "None" },
  ]);
  const [allproduct, setAllProduct] = useState([{ value: "", label: "None" }]);
  const [alluser, setAllUser] = useState([{ value: "", label: "None" }]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState("");

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  useEffect(() => {
    if (startDate && endDate && moment(startDate).diff(moment(endDate)) > 0) {
      setError("Ensure end date value is greater than or equal to start date");
    } else {
      setError("");
    }
  }, [startDate, endDate]);

  useEffect(() => {
    GetAllcategory();
  }, []);

  const GetAllcategory = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/category/AllCategories", {
        headers: {
          Authorization: "Token " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let updatedata = {
            value: res.data.data[i].id,
            label: res.data.data[i].name,
          };
          setAllCategory((oldArray) => [...oldArray, updatedata]);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          if (error.response?.status === 400) {
            if (
              error.response?.data &&
              error.response?.data["data Request failed"]
            ) {
              toast.error(
                return400Error(error.response?.data["data Request failed"])
              );
            } else if (error.response?.data && error.response?.data.message) {
              toast.error(error.response.data.message.toString());
            }
          } else {
            toast.error(error.toString());
          }
        }
      });

  };

  const GetAllSubcategory = (category_id) => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/category/AllsubCategories?category_id=" + category_id, {
        headers: {
          Authorization: "Token " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let updatedata = {
            value: res.data.data[i].id,
            label: res.data.data[i].name,
          };
          setAllSubCategory((oldArray) => [...oldArray, updatedata]);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          if (error.response?.status === 400) {
            if (
              error.response?.data &&
              error.response?.data["data Request failed"]
            ) {
              toast.error(
                return400Error(error.response?.data["data Request failed"])
              );
            } else if (error.response?.data && error.response?.data.message) {
              toast.error(error.response.data.message.toString());
            }
          } else {
            toast.error(error.toString());
          }
        }
      });

  };

  const GetAllProduct = (category_id, sub_category_id) => {

    axios
      .post(process.env.REACT_APP_BASE_URL + "/products/get_products_dropdown?category_id=" + category_id + "&sub_category_id=" + sub_category_id)
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let updatedata = {
            value: res.data.data[i].id,
            label: res.data.data[i].itemName,
          };
          setAllProduct((oldArray) => [...oldArray, updatedata]);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          if (error.response?.status === 400) {
            if (
              error.response?.data &&
              error.response?.data["data Request failed"]
            ) {
              toast.error(
                return400Error(error.response?.data["data Request failed"])
              );
            } else if (error.response?.data && error.response?.data.message) {
              toast.error(error.response.data.message.toString());
            }
          } else {
            toast.error(error.toString());
          }
        }
      });
    GetAllUser();
  };

  const GetAllUser = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/get_all_users",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let updatedata = {
            value: res.data.data[i].id,
            label: res.data.data[i].name,
          };
          setAllUser((oldArray) => [...oldArray, updatedata]);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          if (error.response?.status === 400) {
            if (
              error.response?.data &&
              error.response?.data["data Request failed"]
            ) {
              toast.error(
                return400Error(error.response?.data["data Request failed"])
              );
            } else if (error.response?.data && error.response?.data.message) {
              toast.error(error.response.data.message.toString());
            }
          } else {
            toast.error(error.toString());
          }
        }
      });
  };

  useEffect(() => {
    setLoader(true);
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_all_loyalty_points?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
        {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (res) {
        setLoader(false);
        setTotalItems(res.data.total_count);
        setLoyaltyPoint(res.data.data);
      })
      .catch(function (err) {
        setLoader(false);
        if (error.response?.status === 400) {
          if (
            error.response?.data &&
            error.response?.data["data Request failed"]
          ) {
            toast.error(
              return400Error(error.response?.data["data Request failed"])
            );
          } else if (error.response?.data && error.response?.data.message) {
            toast.error(error.response.data.message.toString());
          }
        } else {
          toast.error(error.toString());
        }
      });
  }, [refresh, pageNo, totalItemToBeFetched]);

  const handleSubmitAddData = () => {
    const formData = new FormData();
    const updatedForm = {
      ...selectedOffer,
      ...form,
    };
    Object.keys(updatedForm).forEach((key) => {
      if (updatedForm[key] && typeof updatedForm[key] === "object") {
        formData.append(key, updatedForm[key].value);
      } else {
        updatedForm[key] && formData.append(key, updatedForm[key].toString());
      }
    });
    formData.set(
      "start_date",
      moment(startDate || selectedOffer.start_date).format("YYYY-MM-DD")
    );
    formData.set(
      "end_date",
      moment(endDate || selectedOffer.end_date).format("YYYY-MM-DD")
    );
    formData.append("loyalty_points_image", imageData);

    formData.delete("category_list");
    formData.delete("sub_category_list");
    formData.delete("product_list");
    // if (
    //   new Date(formData.get("start_date")).getTime() < new Date().getTime() &&
    //   new Date(formData.get("end_date")).getTime() < new Date().getTime()
    // ) {
    //   toast.warning("Please select a future date for Start date and End date");
    // } else {
    if (selectedOffer.id) {
      axios
        .put(
          process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/update_loyalty_point/" +
          selectedOffer.id,
          formData,
          {
            headers: {
              Authorization: "Token " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          setBtnDisable(false);
          // setRefresh((prevState) => prevState + 1);
          if (res.data.status === 400) {
            toast.warning(res.data.message);
          } else {
            window.location = "/loyalty-points";
            setIsOpenAddData(false);
            toast.success(res.data.message);
            setFormData({});
            setSelectedOffer({});
            setStartDate("");
            setEndDate("");
          }
        })
        .catch(function (error) {
          setBtnDisable(false);
          return400Error(error);
        });
    } else {
      axios
        .post(
          process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/add_loyalty_point",
          formData,
          {
            headers: {
              Authorization: "Token " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          setBtnDisable(false);
          if (res.data.status === 400) {
            window.location = "/loyalty-points";
            setRefresh((prevState) => prevState + 1);
          } else {
            toast.success(res.data.message);
            // setRefresh((prevState) => prevState + 1);
            setIsOpenAddData(false);
            toast.success(res.data.message);
            setSelectedOffer({});
            setFormData({});
            setStartDate("");
            setEndDate("");
          }
        })
        .catch(function (error) {
          setBtnDisable(false);
          return400Error(error);
        });
      // }
    }
  };

  useEffect(() => {
    if (selectedOffer?.id) {
      setBtnDisable(
        selectedOffer.customer_id?.length === 0 &&
        selectedOffer.category_id?.length === 0 &&
        selectedOffer.sub_category_id?.length === 0 &&
        selectedOffer.product_id?.length === 0
      );
      setFormData({
        ...form,
        // max_aed_limit_credit: selectedOffer.max_aed_limit_credit,
        // max_aed_limit_debit: selectedOffer.max_aed_limit_debit,
        // max_loyalty_points_limit_debit:
        //   selectedOffer.max_loyalty_points_limit_debit,
        // max_loyalty_points_limit_credit:
        //   selectedOffer.max_loyalty_points_limit_credit,
        loyalty_points_amount: selectedOffer.loyalty_points_amount,
        // loyalty_points_description: selectedOffer.loyalty_points_description,
        // terms_and_conditions: selectedOffer.terms_and_conditions,
        number_of_days: selectedOffer.number_of_days,
        category_id: selectedOffer.category_id,
        customer_id: selectedOffer.customer_id,
        sub_category_id: selectedOffer.sub_category_id,
        product_id: selectedOffer.product_id,
      });
    }
  }, [selectedOffer]);

  const OfferCartUi = ({ data }) => {
    return (
      <>
        <div className="card border-primary border" key={data?.id}>
          <div className="card-body">
            {/* <h4 className="card-title text-primary mb-2">
              {data?.loyalty_points_image ? (
                <img
                  className="mr-2"
                  src={
                    process.env.REACT_APP_BASE_URL +
                    data?.loyalty_points_image
                  }
                  width={50}
                  height={50}
                />
              ) : (
                <i className="uil-gift"></i>
              )}
              {data?.loyalty_points_description}
            </h4> */}

            {/* <p className="cart-text">
              <b>T&C - </b>
              <small>{data?.terms_and_conditions}</small>
            </p> */}

            {/* <p className="card-text p-0 m-0">
              <b>Max QAR Limit Credit - </b>
              {data?.max_aed_limit_credit}
            </p>
            <p className="card-text p-0 m-0">
              <b>Max QAR Limit Debit - </b>
              {data?.max_aed_limit_debit}
            </p> */}

            {/* <p className="card-text p-0 m-0">
              <b> Max Loyalty Points Limit Debit - </b>
              {data?.max_loyalty_points_limit_debit}
            </p>
            <p className="card-text p-0 m-0">
              <b> Max Loyalty Points Limit Credit - </b>
              {data?.max_loyalty_points_limit_credit}
            </p> */}

            <p className="card-text p-0 m-0">
              <b>Loyalty Points - </b>
              {data?.loyalty_points_amount}
            </p>
            <p className="card-text p-0 m-0">
              <b>Category - </b>
              {data?.category_name}
            </p>
            <p className="card-text p-0 m-0">
              <b>Sub Category - </b>
              {data?.sub_category_name}
            </p>
            <p className="card-text p-0 m-0">
              <b>Product - </b>
              {data?.product_name}
            </p>
            <p className="card-text p-0 m-0">
              <b>Customer - </b>
              {data?.customer_name}
            </p>
            <p className="card-text p-0 m-0">
              <b>Points Expire after(Days) - </b>
              {data?.number_of_days}
            </p>
            <p className="card-text p-0 m-0">
              <b>Modify on - </b>
              {formatDate(data?.modified_on)}
            </p>
            <p className="card-text p-0 m-0 mt-2">
              {formatDate(data?.start_date)}
              <b> &nbsp;{" - "}&nbsp; </b>
              {formatDate(data?.end_date)}
            </p>
            <div className="w-100 mt-3 d-flex align-items-center justify-content-between">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setIsOpenAddData(true);
                  setSelectedOffer(data);
                }}
              >
                <i className="uil-edit"></i> Edit
              </button>
              <ToggleCheck
                value={data?.is_active}
                id={data?.id}
                baseURL={process.env.REACT_APP_BASE_URL}
                token={"Token " + sessionStorage.getItem("token")}
                url="/medpacks_admin/delete_loyalty_point/"
                method={"DELETE"}
                className="ml-3 mt-1"
                setRefresh={setRefresh}
              />
              {/* {new Date(data?.end_date).getTime() > new Date().getTime() ?
                <ToggleCheck
                  value={data?.is_active}
                  id={data?.id}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  token={"Token " + sessionStorage.getItem("token")}
                  url="/medpacks_admin/delete_loyalty_point/"
                  method={"DELETE"}
                  className="ml-3 mt-1"
                /> : null} */}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container">
          <button
            className="btn btn-primary"
            onClick={() => setIsOpenAddData(true)}
          >
            <i class="mdi mdi-plus-circle"></i> Add Loyalty Point
          </button>
          <div className="row mt-2">
            {loyaltyPoint?.map((e) => {
              return (
                <div className="col-md-6">
                  <OfferCartUi data={e} />
                </div>
              );
            })}
          </div>
          <CustomPagination
            totalItems={totalItems}
            totalItemToBeFetched={totalItemToBeFetched}
            setTotalItemToBeFetched={setTotalItemToBeFetched}
            pageNo={pageNo}
            setPageNo={setPageNo}
          />
        </div>
      )}

      {/* <Drawer
        open={isOpenAddData}
        onClose={() => setIsOpenAddData(false)}
        direction="right"
        duration={200}
        className="CommonDrawer w-25"
      > */}
      <SideModal
        title={selectedOffer.id ? "Edit Loyalty Points" : "Add Loyalty Points"}
        setIsOpen={setIsOpenAddData}
        show={isOpenAddData}
        clearOnClose={() => {
          setFormData({});
          selectedOffer && setSelectedOffer({});
          // selectedOffer && setSelectedOffer({});
          setStartDate('');
          setEndDate('');
        }}
      >
        <div className="rightbar-content h-100" data-simplebar>
          {/* <form onSubmit={handleSubmitAddData} className="mt-4 mb-5 pb-4"> */}
          {/* <div className="form-group">
            <label htmlFor="max_aed_limit_credit">Max QAR Limit Credit</label>
            <input
              className="form-control"
              type="number"
              name="max_aed_limit_credit"
              id="max_aed_limit_credit"
              value={form.max_aed_limit_credit}
              onChange={(e) =>
                setFormData({
                  ...form,
                  max_aed_limit_credit: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="max_aed_limit_debit">Max QAR Limit Debit</label>
            <input
              className="form-control"
              type="number"
              name="max_aed_limit_debit"
              id="max_aed_limit_debit"
              value={form.max_aed_limit_debit}
              onChange={(e) =>
                setFormData({
                  ...form,
                  max_aed_limit_debit: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="max_loyalty_points_limit_debit">
              Max Loyalty Points Limit Debit
            </label>
            <input
              className="form-control"
              type="number"
              name="max_loyalty_points_limit_debit"
              id="max_loyalty_points_limit_debit"
              value={form.max_loyalty_points_limit_debit}
              onChange={(e) =>
                setFormData({
                  ...form,
                  max_loyalty_points_limit_debit: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="max_loyalty_points_limit_credit">
              Max Loyalty Points Limit Credit
            </label>
            <input
              className="form-control"
              type="number"
              name="max_loyalty_points_limit_credit"
              id="max_loyalty_points_limit_credit"
              value={form.max_loyalty_points_limit_credit}
            />
          </div> */}

          <div className="form-group">
            <label htmlFor="loyalty_points_amount">Loyalty Points <span className="text-danger">*</span></label>
            <input
              className="form-control"
              type="number"
              name="loyalty_points_amount"
              id="loyalty_points_amount"
              value={form.loyalty_points_amount}
              onChange={(e) => {
                if (e.target.value.length <= 5) {
                  setFormData({
                    ...form,
                    loyalty_points_amount: e.target.value,
                  })
                }
                return false
              }
              }
            />
          </div>

          {/* <div className="form-group">
            <label htmlFor="loyalty_points_image">Loyalty Points Image</label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              name="loyalty_points_image"
              id="loyalty_points_image"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  let file = e.target.files[0];
                  if (
                    [
                      "image/png",
                      "image/jpg",
                      "image/jpeg",
                      "application/pdf",
                    ].some((x) => x === file?.type)
                  ) {
                    setImageData(file);
                    setImage(URL.createObjectURL(file));
                    setImageError(false);
                  } else {
                    setImageData("");
                    setImage("");
                    setImageError(true);
                    e.target.value = null;
                  }
                }
                // e.target.value = null;
              }}
            />
            {imageError && (
              <span
                className="mt-4"
                style={{
                  fontStyle: "italic",
                  color: imageError ? "red" : "unset",
                }}
              >
                Please upload only .png, .jpg, .jpeg, .pdf files
              </span>
            )}
          </div> */}

          <div className="form-group">
            <label htmlFor="category_id">Offer Category <span className="text-danger">*</span></label>
            <ReactSelect
              className="mt-2 flex-grow-1 mr-1"
              name="category_id"
              // isMulti
              isClearable={true}
              id="category_id"
              options={allcategory}
              placeholder="Select category"
              value={
                allcategory.filter(
                  (x) => x.value.toString() === form.category_id?.toString()
                )[0]
              }
              onChange={(e) => {
                GetAllSubcategory(e.value);
                GetAllProduct(e.value);
                setFormData({
                  ...form,
                  category_id: e,
                });
                setBtnDisable(
                  e?.value
                    ? false
                    : (!form.sub_category_id ||
                      !selectedOffer?.sub_category_id) &&
                    (!e?.value || !selectedOffer?.category_id) &&
                    (!form.product_id || !selectedOffer.product_id) &&
                    (!form.customer_id || !selectedOffer.customer_id)
                );
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="sub_category_id">Offer Sub Category</label>
            <ReactSelect
              className="mt-2 mr-1 flex-grow-1"
              name="sub_category_id"
              id="sub_category_id"
              isClearable={true}
              // isMulti
              options={allsubcategory}
              placeholder="Select category"
              value={
                allsubcategory.filter(
                  (x) => x.value.toString() === form.sub_category_id?.toString()
                )[0]
              }
              // value={form.sub_category_id}
              onChange={(e) => {
                GetAllProduct('', e.value);
                setFormData({
                  ...form,
                  sub_category_id: e,
                });
                setBtnDisable(
                  e?.value
                    ? false
                    : (!form.category_id || !selectedOffer.category_id) &&
                    (!e?.value || !selectedOffer.sub_category_id) &&
                    (!form.product_id || !selectedOffer.product_id) &&
                    (!form.customer_id || !selectedOffer.customer_id)
                );
              }}
            />
          </div>

          <div className="form-group  mb-4">
            <label htmlFor="product_id">
              Offer Products
            </label>
            <ReactSelect
              className="mt-2 mr-1 flex-grow-1"
              name="product_id"
              isClearable={true}
              id="product_id"
              // isMulti
              options={allproduct}
              placeholder="Select product"
              value={
                allproduct.filter(
                  (x) => x.value.toString() === form.product_id?.toString()
                )[0]
              }
              // value={form.product_id}
              onChange={(e) => {
                setFormData({
                  ...form,
                  product_id: e,
                });
                setBtnDisable(
                  e?.value
                    ? false
                    : (!form.sub_category_id ||
                      !selectedOffer.sub_category_id) &&
                    (!e?.value || !selectedOffer.product_id) &&
                    (!form.category_id || !selectedOffer.category_id) &&
                    (!form.customer_id || !selectedOffer.customer_id)
                );
              }}
            />
          </div>

          {/* <div className="form-group mt-4">
            <input
              type="checkbox"
              className="mr-1"
              id="user-select-checkbox"
              name="user-select-checkbox"
              value={selectUser}
              style={{
                height: '20px',
                width: '20px'
              }}
              onChange={(e) => setSelectUser(e.target.checked)}
            /> <label
              style={{
                position: 'relative',
                top: '-6px'
              }}
            >Check to Select Users</label>
          </div> */}

          {
            <div className="form-group">
              <label htmlFor="customer_id">Customer</label>
              <ReactSelect
                className="mt-2"
                name="customer_id"
                isClearable={true}
                id="customer_id"
                options={alluser}
                // isMulti
                placeholder="Select customer"
                value={
                  alluser.filter(
                    (x) => x.value.toString() === form.customer_id?.toString()
                  )[0]
                }
                onChange={(e) => {
                  setFormData({
                    ...form,
                    customer_id: e,
                  });
                  setBtnDisable(
                    e?.value
                      ? false
                      : (!form.sub_category_id ||
                        !selectedOffer.sub_category_id) &&
                      (!e?.value || !selectedOffer.customer_id) &&
                      (!form.category_id || !selectedOffer.category_id) &&
                      (!form.product_id || !selectedOffer.product_id)
                  );
                }}
              />
            </div>
          }

          {/* <div className="form-group">
            <label htmlFor="loyalty_points_description">
              Loyalty Points Description <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="loyalty_points_description"
              required
              id="loyalty_points_description"
              value={form.loyalty_points_description}
              onChange={(e) =>
                setFormData({
                  ...form,
                  loyalty_points_description: e.target.value,
                })
              }
            />
          </div> */}

          <div className="form-group">
            <label htmlFor="number_of_days">
              Points Expire after(Days) <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="number_of_days"
              required
              id="number_of_days"
              value={form.number_of_days}
              onChange={(e) =>
                setFormData({
                  ...form,
                  number_of_days: e.target.value,
                })
              }
            />
          </div>

          {/* <div className="form-group">
            <label htmlFor="terms_and_conditions">
              Terms & Conditions <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control"
              type="text"
              name="terms_and_conditions"
              required
              id="terms_and_conditions"
              rows={2}
              value={form.terms_and_conditions}
              onChange={(e) =>
                setFormData({
                  ...form,
                  terms_and_conditions: e.target.value,
                })
              }
            />
          </div> */}

          <div className="w-100">
            <div className="pr-1">
              <div className="form-group">
                <label htmlFor="start_date">
                  Start Date <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="start_date"
                  required
                  id="start_date"
                  value={startDate || selectedOffer?.start_date}
                  onChange={(e) =>
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"))
                  }
                  min={moment().format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="pl-1">
              <div className="form-group">
                <label htmlFor="end_date">
                  End Date <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="end_date"
                  required
                  id="end_date"
                  value={endDate || selectedOffer?.end_date}
                  onChange={(e) =>
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"))
                  }
                  min={
                    startDate.length > 0
                      ? startDate
                      : moment().format("YYYY-MM-DD")
                  }
                />
                <p className="text-danger mt-2">{error}</p>
              </div>
            </div>
          </div>

          <div className="form-group mt-2 text-center">
            <button
              className="btn btn-primary px-4"
              disabled={
                btnDisable ||
                imageError ||
                form.loyalty_points_amount === undefined ||
                form.loyalty_points_amount === "" ||
                form.number_of_days === undefined ||
                form.number_of_days === ""
              }
              type="submit"
              onClick={handleSubmitAddData}
            >
              Add
            </button>
          </div>
          {/* </form> */}
        </div>
      </SideModal>
    </>
  );
};

export default LoyaltyPoint;
