import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { AccessLabels, AccessList, getKeyByValue } from './utils';
import axios from "axios";
import { useEffect } from 'react';
import { return400Error } from '../Common/Errors';

const CreateRole = ({ close, setRefresh }) => {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [roles, setRoles] = useState({});

  const renderSwitch = (id, name) => {
    return (<div className={`custom-control custom-switch mb-1 col-6`}>
      <input
        type="checkbox"
        className="custom-control-input"
        id={id + "_id_name"}
        defaultChecked={roles[getKeyByValue(AccessList, name)] === true}
        onClick={(e) => {
          let newRoles = { ...roles };
          setRoles({ ...newRoles, [getKeyByValue(AccessList, name)]: e.target.checked });
        }}
      />
      <label
        className="custom-control-label"
        htmlFor={id + "_id_name"}
      >{name}</label>
    </div>
    );
  };

  useEffect(() => {
    return () => {
      setName('');
      setDescription('');
      setRoles({});
    }
  }, [])

  const handleCreateRole = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/save_admin_roles",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
      data: {
        name,
        ...roles,
        description,
      }
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setName('');
          setDescription('');
          setRoles({});
          setRefresh(1)
          toast.success(response.data.message);
          close();
        }
      })
      .catch(function (error) {
        return400Error(error);
        setName('');
        setDescription('');
        setRoles({});
        setRefresh(1)
        close();
      });
  };

  return (
    <div class="modal fade" tabindex="-1" role="dialog" id='roleModal' aria-labelledby="roleModalLabel" data-backdrop="static" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Role</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => close()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className='container'>
              <div className="row">
                <div className="col">
                  <label className='required'>Name of Role</label>
                  <input
                    className="form-control"
                    name={'name'}
                    onChange={e => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className="col">
                  <label className='required'>Description of Role</label>
                  <input
                    className="form-control"
                    name={'description'}
                    onChange={e => setDescription(e.target.value)}
                    value={description}
                  />
                </div>
              </div>
              <div className="row">
                <h4>Menu Access</h4>
                <div className='row pl-2'>
                  {AccessLabels.map((menu, i) => renderSwitch(i, menu))}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div className='m-auto'>
              <button
                type="button"
                class="btn btn-primary mr-2"
                data-dismiss="modal"
                onClick={() => { handleCreateRole() }}
                disabled={!name || !Object.keys(roles)?.some(key => roles[key] === true)}
              >
                Create
              </button>
              <button type="button" class="btn btn-secondary" onClick={() => { close() }} data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateRole;