import axios from "axios";
// import { useState } from "react";
import { toast } from "react-toastify";
import { return400Error } from "./Errors";
// import Toggle from "react-toggle";
// import "react-toggle/style.css";

const ToggleCheck = (props) => {
  // const [check, setCheck] = useState(props.value);
  const handleClick = () => {
    // setCheck(!check);
    // disable or active
    if (props?.forwardslash) {
      axios({
        method: props.method,
        url: props.baseURL + props.url + props.id + props?.forwardslash,
        headers: {
          Authorization: props.token,
        },
      })
        .then((res) => {
          props.setRefresh((prevState) => prevState + 1);
          if (res.data.status === 200) {
            if (props.onSuccess) {
              props.onSuccess();
            }
            toast.success(res.data.message);
          } else {
            toast.warning(res.data.message);
          }
        })
        .catch(function (error) {
          props.setRefresh((prevState) => prevState + 1);
          props.onError && props.onError();
          return400Error(error);
        });
    } else {
      axios({
        method: props.method,
        url: props.baseURL + props.url + props.id,
        headers: {
          Authorization: props.token,
        },
      })
        .then((res) => {
          props.setRefresh((prevState) => prevState + 1);
          if (res.data.status === 200) {
            if (props.onSuccess) {
              props.onSuccess();
            }
            toast.success(res.data.message);
          } else {
            toast.warning(res.data.message);
          }
        })
        .catch(function (error) {
          props.setRefresh((prevState) => prevState + 1);
          props.onError && props.onError();
          return400Error(error);
        });
    }
  };

  return (
    <>
      {props.action === "delete" ? (
        <i
          className="uil-trash"
          style={{ cursor: "pointer", fontSize: "20px" }}
          onClick={handleClick}
        ></i>
      ) : props.button ? (
        props.value ? (
          <button className="btn btn-success py-0 px-1" onClick={handleClick}>
            Disable
          </button>
        ) : (
          <button className="btn btn-danger py-0 px-1" onClick={handleClick}>
            Enable
          </button>
        )
      ) : (
        <div className={`custom-control custom-switch mb-1 ${props.className}`}>
          <input
            type="checkbox"
            className="custom-control-input"
            id={props.id + "_id_name"}
            defaultChecked={props.value}
            value={props.id}
            onClick={handleClick}
          />
          <label
            className="custom-control-label"
            htmlFor={props.id + "_id_name"}
          ></label>
        </div>
      )}
      {/* <Toggle checked={check} icons={false} onChange={handleClick} /> */}
      {/* {props.value ? "true" : "false"} */}
    </>
  );
};

export default ToggleCheck;
