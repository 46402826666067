import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { return400Error } from "../Common/Errors";
import "./AddUser.scss";

const AddUser = ({ selectedUser, setSelectedUser, onSuccess, close }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country_code, setCountryCode] = useState("974");
  const [role_id, setRoleId] = useState("");
  const [role, setSelectedRole] = useState({});
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [phone, setPhone] = useState();
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    selectedUser?.phone_number || ""
  );

  useEffect(() => {
    return () => {
      setFirstName("");
      setLastName("");
      setRoleId("");
      setEmail("");
      setSelectedRole({});
      setSelectedUser({});
      setCountryCode("974");
      setPhoneCountryCode("");
    };
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_active_admin_roles",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function(response) {
        if (response.status === 200) {
          setRoles(
            response.data.data &&
              response.data.data.map((role) => ({
                label: role.name,
                value: role.id,
              }))
          );
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function(error) {
        // setLoader(false);
        return400Error(error);
      });
  }, []);

  useEffect(() => {
    if (selectedUser?.phone_number) {
      setFirstName(selectedUser?.name?.split(" ")[0]);
      setLastName(selectedUser?.name?.split(" ")[1]);
      setPhoneCountryCode(
        selectedUser?.country_code + selectedUser?.phone_number
      );
      setRoleId(selectedUser?.role_id);
    }
  }, [selectedUser]);

  const handleAddUser = () => {
    axios({
      method: selectedUser?.phone_number ? "PUT" : "POST",
      url: selectedUser?.phone_number
        ? process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/update_admin_user/" +
          selectedUser?.id
        : process.env.REACT_APP_BASE_URL + "/medpacks_admin/add_admin_user",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
      data: {
        name: firstName
          ? firstName + " " + lastName
          : selectedUser?.name &&
            selectedUser?.name.split(" ")[0] +
              " " +
              (lastName || selectedUser?.name.split(" ")[1]),
        country_code: country_code || selectedUser?.country_code,
        role_id: role_id || selectedUser?.role_id,
        email: email || selectedUser?.email,
        phone_number: phone || selectedUser?.phone_number,
      },
    })
      .then(function(response) {
        // console.log(response.data);
        if (response.data.status === 200) {
          setSelectedUser(null);
          setCountryCode("974");
          setPhoneCountryCode("");
          toast.success(response.data.message);
          onSuccess();
          close();
        } else if (response.data.status === 400) {
          // setSelectedUser(null);
          setCountryCode("974");
          // setPhoneCountryCode("");
          toast.warning(response.data.message);
          // return400Error({ response: { status: 400, data: { ["data Request failed"]: response.data.data } } });
          // close();
        }
      })
      .catch(function(error) {
        setSelectedUser({});
        return400Error(error);
      });
  };

  return (
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      id="add-user-modal"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {selectedUser?.phone_number ? "Edit" : "Add"} User
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                close();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="container">
              <div className="row" style={{ margin: "15px 0" }}>
                <div className="col">
                  <label className="required">First Name</label>
                  <input
                    className="form-control"
                    name={"firstName"}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label>Last Name</label>
                  <input
                    className="form-control"
                    name={"lastName"}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="row" style={{ margin: "15px 0" }}>
                <div className="col">
                  <label className="required">Phone Number</label>
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                    }}
                    defaultMask={".... ...."}
                    // masks={{ qa: '.... ....' }}
                    onChange={(phoneNumber, countryCodeObj) => {
                      setPhone(
                        phoneNumber.substring(countryCodeObj.dialCode.length)
                      );
                      setCountryCode(countryCodeObj.dialCode);
                      setPhoneCountryCode(phoneNumber);
                    }}
                    country="qa"
                    value={phoneCountryCode}
                  />
                </div>
              </div>
              <div className="row" style={{ margin: "15px 0" }}>
                <div className="col">
                  <label>Email</label>
                  <input
                    className="form-control"
                    name={"email"}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email || selectedUser?.email}
                  />
                </div>
              </div>
              <div className="row" style={{ margin: "15px 0" }}>
                <div className="col">
                  <label className="required">Roles</label>
                  <ReactSelect
                    name={"roles"}
                    options={roles}
                    onChange={(value) => {
                      setRoleId(value.value);
                      setSelectedRole(value);
                    }}
                    value={
                      (role && role.value && role) ||
                      (selectedUser &&
                        roles &&
                        roles.filter(
                          (role) => role.value === selectedUser?.role_id
                        )[0])
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div className="m-auto">
              <button
                type="button"
                className="btn btn-primary mr-2"
                data-dismiss="modal"
                disabled={!firstName || !phoneCountryCode || !role_id}
                onClick={() => {
                  handleAddUser();
                  setSelectedUser({});
                  setCountryCode("974");
                  setPhoneCountryCode("");
                }}
              >
                {selectedUser ? "Update" : "Add"}
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => {
                  close();
                }}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
