import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../Common/Loader";
import DateFormater from "../../Common/DateFormater";
import ToggleCheck from "../../Common/ToggleCheck";
import Drawer from "react-modern-drawer";
import Table from "../../Common/Table";
import { return400Error } from "../../Common/Errors";

const Diagnosis = () => {
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [actionbtnsts, setactionbtnsts] = useState("add");
  const [ActionTitle, setActionTitle] = useState("ADD DIAGNOSIS");
  const [actionbtntxt, setactionbtntxt] = useState("ADD");
  const [allFormData, setAllFormData] = useState({});
  const [isOpenAddData, setIsOpenAddData] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [refresh, setRefresh] = useState(0);

  const columns = [
    {
      dataField: "sl_no",
      text: "Index no.",
      sort: true,
    },
    {
      dataField: "id",
      text: "ID",
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "description",
      text: "Description",
      headerStyle: () => {
        return { width: "100px" };
      },
      sort: true,
    },
    {
      dataField: "created_by_name",
      text: "Created By",
      sort: true,
    },
    {
      dataField: "modified_by_name",
      text: "Modified By",
      sort: true,
    },
    {
      dataField: "is_active",
      text: "Action",
    },
  ];

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  useEffect(() => {
    fetchAllData();
  }, [pageNo, totalItemToBeFetched, refresh]);

  const fetchAllData = () => {
    setLoader(true);
    axios
      .get(
        process.env.REACT_APP_DOCTOR_URL +
        "/master/diagnosis/?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
        {
          headers: {
            Authorization: process.env.REACT_APP_DOCTOR_TOKEN,
          },
        }
      )
      .then((res) => {
        setLoader(false);
        setAllData([]);
        setTotalItems(res.data.total_count);
        res.data.data.map((e, i) => {
          let updatedata = {
            ...e,
            sl_no: i + 1,
            id: e.id,
            name: e.name,
            description: e.description,
            created_by: e.created_by,
            modified_by: e.modified_by,
            is_active: (
              <ToggleCheck
                value={e?.is_active}
                id={e?.id}
                baseURL={process.env.REACT_APP_DOCTOR_URL}
                token={process.env.REACT_APP_DOCTOR_TOKEN}
                url="/master/diagnosis/"
                forwardslash="/"
                method={"DELETE"}
                action={"delete"}
                setRefresh={setRefresh}
                onSuccess={fetchAllData}
              />
            ),
            edit: (
              <i
                className="uil-edit"
                onClick={handleClickEditbtn}
                allval={JSON.stringify(e)}
                style={{ cursor: "pointer" }}
              ></i>
            ),
          };
          setAllData((oldArray) => [...oldArray, updatedata]);
        });
      })
      .catch((err) => {
        const { status, message } = err.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          return400Error(err);
        }
      });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setAllFormData({ ...allFormData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (actionbtnsts === "add") {
      axios
        .post(
          process.env.REACT_APP_DOCTOR_URL + "/master/diagnosis/",
          allFormData,
          {
            headers: {
              Authorization: process.env.REACT_APP_DOCTOR_TOKEN,
            },
          }
        )
        .then((res) => {
          fetchAllData();
          toast.success(res.data.message);
          setAllFormData({
            name: "",
            description: "",
          });
        })
        .catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            sessionStorage.clear()
            window.location = "/";
            toast.error(message);
          }
          else {
            return400Error(err);
          }
        });
    } else {
      axios
        .patch(
          process.env.REACT_APP_DOCTOR_URL +
          "/master/diagnosis/" +
          actionbtnsts +
          "/",
          allFormData,
          {
            headers: {
              Authorization: process.env.REACT_APP_DOCTOR_TOKEN,
            },
          }
        )
        .then((res) => {
          fetchAllData();
          toast.success(res.data.message);
        })
        .catch((err) => {
          const { status, message } = err.response.data.data;
          if (status === "400") {
            sessionStorage.clear()
            window.location = "/";
            toast.error(message);
          }
          else {
            return400Error(err);
          }
        });
    }
  };

  const handleClickEditbtn = (e) => {
    const data = JSON.parse(e.target.getAttribute("allval"));
    console.log(data);
    setAllFormData(data);
    setActionTitle("UPDATE " + data.name);
    setactionbtntxt("UPDATE");
    setactionbtnsts(data.id);
  };

  return (
    <div className="row px-3">
      <div className="col-8">
        <Table
          data={allData}
          searchKey="name"
          columns={columns}
          maxRow={10}
          loader={loader}
          totalItems={totalItems}
          totalItemToBeFetched={totalItemToBeFetched}
          setTotalItemToBeFetched={setTotalItemToBeFetched}
          pageNo={pageNo}
          setPageNo={setPageNo}
        />
      </div>

      <div className="col-4">
        <div className="card border-primary border">
          <div className="card-body">
            <h5 className="text-center mb-2 pb-1 text-uppercase">
              {ActionTitle}
            </h5>
            <form onSubmit={handleSubmit}>
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                required
                value={allFormData.name}
                onChange={handleOnChange}
              />
              <textarea
                name="description"
                className="form-control mt-2"
                placeholder="Description"
                rows={3}
                value={allFormData.description}
                onChange={handleOnChange}
              ></textarea>
              <button
                type="submit"
                className="btn btn-primary px-3 py-1 mt-3 w-100"
              >
                {actionbtntxt}
              </button>
              {actionbtnsts === "add" ? null : (
                <button
                  className="btn btn-primary px-3 py-1 mt-3 w-100"
                  onClick={() => {
                    setActionTitle("ADD DIAGNOSIS");
                    setactionbtntxt("ADD");
                    setactionbtnsts("add");
                    setAllFormData({});
                  }}
                >
                  {"Cancel"}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>

      <Drawer
        open={isOpenAddData}
        onClose={() => setIsOpenAddData(false)}
        direction="right"
        duration={200}
        className="CommonDrawer w-25"
      >
        <div className="rightbar-title">
          <a
            href="javascript:void(0);"
            className="right-bar-toggle float-right"
            onClick={() => setIsOpenAddData(false)}
          >
            <i className="dripicons-cross noti-icon"></i>
          </a>
          <h5 className="m-0 text-uppercase">
            {previewData?.id} - {previewData?.name}
          </h5>
        </div>
        <div className="rightbar-content h-100 px-3 mt-3" data-simplebar>
          <p className="cart-text p-0 m-0">
            <b>Description - </b>
            <small>{previewData.description}</small>
          </p>
          <p className="cart-text p-0 m-0 mt-3">
            <b>Created By - </b>
            <small>{previewData.created_by}</small>
          </p>
          <p className="cart-text p-0 m-0 mt-1">
            <b>Modified By - </b>
            <small>{previewData.modified_by}</small>
          </p>
        </div>
      </Drawer>
    </div>
  );
};
export default Diagnosis;
