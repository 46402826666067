import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import Loader from "./Loader";
import cellEditFactory from "react-bootstrap-table2-editor";
import filterFactory from "react-bootstrap-table2-filter";
import { useEffect, useState } from "react";
import useDebounce from "./debounce";
import CustomPagination from "./CustomPagination";

const Table = ({
  data,
  columns,
  maxRow,
  loader,
  rowEvents,
  rowStyle,
  searchKey,

  totalItems,
  totalItemToBeFetched,
  setTotalItemToBeFetched,
  pageNo,
  setPageNo,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setData] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 150);

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm) {
      setData(
        data.filter((x) =>
          x[searchKey].toLowerCase().match(searchTerm.toLowerCase())
        )
      );
    }
    if (searchTerm === "") {
      setData(data);
    }
  }, [debouncedSearchTerm]);

  //customize table
  const tableStyle = {
    border: "none !important",
  };

  // customize pagination button
  const pageButtonRenderer = ({ page, active, onPageChange }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {
      padding: "10px 15px",
      borderRadius: "50%",
    };
    if (active) {
      activeStyle.backgroundColor = "#F58329";
      activeStyle.border = "1px solid #F58329";
      activeStyle.color = "white";
    } else {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
      activeStyle.border = "none";
    }
    return (
      <li className="page-item">
        <a href="#" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };

  // options
  const options = {
    custom: true,
    totalSize: tableData?.length,
    hideSizePerPage: true, // Hide the sizePerPage dropdown always
    pageButtonRenderer,
    sizePerPageList: [maxRow],
  };

  const rowStyles = (row, rowIndex) => {
    const style = {
      padding: "5px",
    };
    return style;
  };

  return (
    <>
      <div class="card">
        <div class="card-body">
          {loader ? (
            <Loader />
          ) : (
            <>
              {/* <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => ( */}
              <>
                {/* <div className="app-search mb-2 w-100">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        id="top-search"
                      />
                      <span className="mdi mdi-magnify search-icon"></span>
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div> */}
                {searchKey ? (
                  <div class="input-group" style={{ marginBottom: "20px" }}>
                    <div class="form-outline flex-grow-1">
                      <input
                        type="search"
                        id="form1"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <button type="button" class="btn btn-primary text-white">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                ) : null}
                <div className="table-sm">
                  <BootstrapTable
                    // classes="table-hover-custom"
                    keyField="id"
                    data={tableData}
                    columns={columns}
                    headerClasses="bg-primary text-light text-center"
                    bodyClasses="text-center"
                    style={tableStyle}
                    // {...paginationTableProps}
                    rowEvents={rowEvents}
                    rowStyle={rowStyle}
                    filter={filterFactory()}
                    bordered={false}
                    // cellEdit={cellEditFactory({ mode: 'click' })}
                  />
                </div>
                {/* <div className="mt-3 d-flex justify-content-end">
                  <PaginationListStandalone {...paginationProps} />
                </div> */}
              </>
              {/* )}
          </PaginationProvider> */}
            </>
          )}
        </div>

        <CustomPagination
          totalItems={totalItems}
          totalItemToBeFetched={totalItemToBeFetched}
          setTotalItemToBeFetched={setTotalItemToBeFetched}
          pageNo={pageNo}
          setPageNo={setPageNo}
        />
      </div>
    </>
  );
};

export default Table;
