import { useEffect } from "react";
import { useState } from "react";

const CarouselCmp = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data.data)
  }, [data])

  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {data?.map((e, i) => {
            return (
              <li
                data-target="#carouselExampleIndicators"
                className={i === 0 ? "active" : null}
                data-slide-to={i}
                key={i}
              ></li>
            );
          })}
        </ol>
        <div className="carousel-inner">
          {data?.map((e, i) => {
            return (
              <div
                className={`carousel-item ${i === 0 ? "active" : null}`}
                key={i}
              >
                <img
                  className="d-block w-100"
                  src={process.env.REACT_APP_BASE_URL + e.image}
                  alt="Second slide"
                />
              </div>
            );
          })}
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </>
  );
};

export default CarouselCmp;
