import WishlistProductList from "./WishListProducts";
import OrdersProductList from "./orders/OrdersProducts";
import PrescriptionProduct from "./PrescriptionProducts";
import ToggleCheck2 from "../../../../Common/ToggleCheck2";

const apiUrl = process.env.REACT_APP_BASE_URL;

export const groupBy = (key, array) =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
    }),
    {}
  );

export const TABS = {
  WISHLIST: "wishlist",
  ORDERS: "orders",
  PRESCRIPTIONS: "prescriptions",
};

const WithBackButton = ({ children, table, setData, setShowProductsList }) => (
  <div className="card">
    <button
      className="btn btn-outline-primary back-button"
      style={{
        width: "10%",
        margin: "5px 5px 5px 85%",
      }}
      onClick={() => {
        setData(table);
        setShowProductsList(false);
      }}
    >
      Back
    </button>
    {children}
  </div>
);

export const renderProductListComponent = (...args) => {
  const [data, tab, setShowProductsList, setRow, setData, table] = args;
  switch (tab) {
    case TABS.WISHLIST: {
      return (
        <WithBackButton
          table={table}
          setData={setData}
          setShowProductsList={setShowProductsList}
        >
          <WishlistProductList
            data={data}
            setShowProductsList={setShowProductsList}
            setRow={setRow}
          />
        </WithBackButton>
      );
    }
    case TABS.ORDERS: {
      return (
        <WithBackButton
          table={table}
          setData={setData}
          setShowProductsList={setShowProductsList}
        >
          <OrdersProductList
            data={data}
            setShowProductsList={setShowProductsList}
            setRow={setRow}
          />
        </WithBackButton>
      );
    }
    case TABS.PRESCRIPTIONS: {
      return <PrescriptionProduct />;
    }
    default: {
      return null;
    }
  }
};

export const transformData = (
  data,
  tab,
  setShowProductsList,
  setRow,
  setImage,
  image,
  fetchTabData,
  filters
) => {
  let updatedData = data.map((e, i) => {
    return {
      ...e,
      is_active: (
        <ToggleCheck2
          value={e.is_active}
          actionfor={"status"}
          data={{ name: e.name, file: e.file }}
          id={e.id}
          url={
            tab === TABS.PRESCRIPTIONS
              ? "/medpacks_admin/update_prescription/"
              : "/medpacks_admin/" + "disable_user"
          }
          token={"Token " + sessionStorage.getItem("token")}
          method={"PUT"}
          onSuccess={() => fetchTabData(filters)}
          baseURL={process.env.REACT_APP_BASE_URL}
          changeVal={{ is_active: !e.is_active }}
        />
      ),
      [tab === TABS.ORDERS && "payment_status"]:
        e.payment_status === 0
          ? "Unpaid"
          : e.payment_status === 1
            ? "Paid"
            : " Payment Pending",
      [tab === TABS.PRESCRIPTIONS && "number_of_products_ordered"]:
        e.number_of_products_ordered || "No Orders",
      [tab === TABS.PRESCRIPTIONS && "file"]: (
        <>{e?.file?.indexOf(".pdf") >= 0 ? (
          <a
            target="_blank"
            href={apiUrl + e.file}
            download={data.name}
          >
            <button
              className="btn"
              style={{
                fontSize: "0.8rem",
                color: "#727cf5",
                fontWeight: "500",
              }}
            >
              View Prescription
            </button>
          </a>)
          : <button
            className="btn"
            style={{
              fontSize: "0.8rem",
              color: "#727cf5",
              fontWeight: "500",
            }}
            data-toggle="modal"
            disabled={!e.file}
            onClick={() => {
              if (e?.file.indexOf(".pdf") >= 0) {
                const a = document.createElement("A");
                a.href = e.file;
                a.target = "_blank";
                a.download = e.name;
                a.click();
              } else {
                setImage({
                  image: e.file,
                  name: e.name,
                });
              }
            }}
            data-target="#prescriptionImage"
          >
            View Prescription
          </button>}
        </>
      ),
      [tab === TABS.PRESCRIPTIONS && "active_inactive_status"]: (
        <>
          <span>{e.is_reusable ? "Active" : "Expired"}</span>
        </>
      ),
      [tab === TABS.ORDERS && "productSize"]: (
        <>
          <span>{e.details?.products?.length}</span>
        </>
      ),
      viewMore: (
        <button
          className="btn"
          style={{
            fontSize: "0.8rem",
            color: "#727cf5",
            fontWeight: "500",
            padding: "0",
            marginLeft: "-80%",
          }}
          onClick={() => {
            setShowProductsList(true);
            setRow(e);
          }}
        >
          View Product List
        </button>
      ),
    };
  });

  return updatedData;
};
