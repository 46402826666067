import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Drawer from "react-modern-drawer";
import DateFormater from "../../Common/DateFormater";
import Loader from "../../Common/Loader";
import ToggleCheck from "../../Common/ToggleCheck";
import DateTimePicker from "../../Common/DateTimePicker";
import moment from "moment";
import SideModal from "./SideModal";
import ReactSelect from "react-select";
import { useRef } from "react";
import { return400Error } from "../../Common/Errors";
import CustomPagination from "../../Common/CustomPagination";
import { formatDate } from "../../helper/dateUtils";

const Coupons = () => {
  const [loader, setLoader] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [isOpenAddData, setIsOpenAddData] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [allcategory, setAllCategory] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allsubcategory, setAllSubCategory] = useState([
    { value: "", label: "None" },
  ]);
  const [allproduct, setAllProduct] = useState([{ value: "", label: "None" }]);
  const [alluser, setAllUser] = useState([{ value: "", label: "None" }]);
  const [form, setFormData] = useState({
    reset_category_id: false,
    reset_sub_category_id: false,
    reset_product_id: false,
  });
  const [selectUser, setSelectUser] = useState(false);
  const [select_all, setOfferForAll] = useState(false);
  const [offerForAll, setAllDisabled] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  // useEffect(() => {
  //   setFormData(selectedOffer);
  // }, [selectedOffer]);

  const fetchAllCoupons = () => {
    setLoader(true);
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_all_coupons?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
        {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("token"),
          },
        }
      )
      .then(function (res) {
        setLoader(false);
        setTotalItems(res.data.total_count);
        setCoupons(res.data.data);
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  };

  useEffect(() => {
    fetchAllCoupons();
  }, [refresh, pageNo, totalItemToBeFetched]);

  useEffect(() => {
    GetAllcategory();
  }, []);

  const GetAllcategory = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/category/AllCategories", {
        headers: {
          Authorization: "Token " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let updatedata = {
            value: res.data.data[i].id,
            label: res.data.data[i].name,
          };
          setAllCategory((oldArray) => [...oldArray, updatedata]);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          return400Error(error);
        }
      });

    GetAllSubcategory();
  };

  const GetAllSubcategory = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/category/AllsubCategories", {
        headers: {
          Authorization: "Token " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let updatedata = {
            value: res.data.data[i].id,
            label: res.data.data[i].name,
          };
          setAllSubCategory((oldArray) => [...oldArray, updatedata]);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          return400Error(error);
        }
      });

    GetAllProduct();
  };

  const GetAllProduct = () => {
    axios
      .post(process.env.REACT_APP_BASE_URL + "/products/get_products_dropdown")
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let updatedata = {
            value: res.data.data[i].id,
            label: res.data.data[i].itemName,
          };
          setAllProduct((oldArray) => [...oldArray, updatedata]);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          return400Error(error);
        }
      });
    GetAllUser();
  };

  const GetAllUser = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/get_all_users",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          let updatedata = {
            value: res.data.data[i].id,
            label: res.data.data[i].name,
          };
          setAllUser((oldArray) => [...oldArray, updatedata]);
        }
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          return400Error(error);
        }
      });
  };

  const handleSubmitAddData = () => {
    // e.preventDefault();
    // const form = e.target;
    const formData = new FormData();
    let updatedForm = {
      ...selectedOffer,
      ...form,
      select_all: select_all,
    };

    updatedForm = {
      ...updatedForm,
      category_id: !form.category_id && updatedForm.category_id !== [] && updatedForm.category_list?.length > 0 ? updatedForm.category_list.map(({ categoryId, categoryName }) => ({ value: categoryId, label: categoryName })) : updatedForm.category_id,
      sub_category_id: !form.sub_category_id && updatedForm.sub_category_id !== [] && updatedForm.sub_category_list?.length > 0 ? updatedForm.sub_category_list.map(({ subCategoryId, subCategoryName }) => ({ value: subCategoryId, label: subCategoryName })) : updatedForm.sub_category_id,
      product_id: !form.product_id && updatedForm.product_id !== [] && updatedForm.product_list?.length > 0 ? updatedForm.product_list.map(({ productId, productName }) => ({ value: productId, label: productName })) : updatedForm.product_id,
      customer_id: !form.customer_id && updatedForm.customer_id !== [] && updatedForm.customer_list?.length > 0 ? updatedForm.customer_list.map(({ customerId, customerName }) => ({ value: customerId, label: customerName })) : updatedForm.customer_id,
    }

    Object.keys(updatedForm).forEach((key) => {
      if (updatedForm[key] && typeof updatedForm[key] === "object") {
        formData.append(key, updatedForm[key].map((x) => x.value).toString());
      } else {
        updatedForm[key] && formData.append(key, updatedForm[key].toString());
      }
    });
    const offer_amount =
      formData.get("offer_amount") || selectedOffer.offer_amount;
    const offer_percent =
      formData.get("offer_percent") || selectedOffer.offer_percent;
    formData.set(
      "start_date",
      moment(startDate || selectedOffer.start_date).format("YYYY-MM-DD")
    );
    formData.set(
      "end_date",
      moment(endDate || selectedOffer.end_date).format("YYYY-MM-DD")
    );
    formData.set(
      "start_time", startDate ?
      moment(startDate).format("LTS") : selectedOffer.start_time
    );
    formData.set(
      "end_time", endDate ?
      moment(endDate).format("LTS") : selectedOffer.end_time
    );

    formData.delete("category_list");
    formData.delete("sub_category_list");
    formData.delete("product_list");
    formData.delete("customer_list");
    formData.delete("category_ids");
    formData.delete("sub_category_ids");
    formData.delete("product_ids");
    formData.delete("customer_ids");
    if (moment(formData.get('end_date') + ' ' + formData.get('end_time')).isBefore(moment(formData.get('start_date') + ' ' + formData.get('start_time')))) {
      toast.warning('Please select end date greater than start date');
    } else if (!offer_percent) {
      toast.warning("Please enter a valid Offer percent");
    } else {
      if (selectedOffer.id) {
        axios
          .put(
            process.env.REACT_APP_BASE_URL +
            "/medpacks_admin/update_coupon/" +
            selectedOffer.id,
            formData,
            {
              headers: {
                Authorization: "Token " + sessionStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            setBtnDisable(false);
            if (res.data.status === 400) {
              toast.warning(res.data.message);
            } else {
              setRefresh((prevState) => prevState + 1);
              setIsOpenAddData(false);
              toast.success(res.data.message);
              setFormData({});
              setSelectedOffer({});
              setStartDate("");
              setEndDate("");
            }
          })
          .catch(function (error) {
            setBtnDisable(false);
            return400Error(error);
          });
      } else {
        axios
          .post(
            process.env.REACT_APP_BASE_URL + "/medpacks_admin/add_coupon",
            formData,
            {
              headers: {
                Authorization: "Token " + sessionStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            setBtnDisable(false);
            if (res.data.status === 400) {
              toast.error(res.data.message);
            } else {
              // setRefresh((prevState) => prevState + 1);
              window.location = "/coupons"
              setIsOpenAddData(false);
              toast.success(res.data.message);
              setSelectedOffer({});
              setFormData({});
              setStartDate("");
              setEndDate("");
            }
          })
          .catch(function (error) {
            setBtnDisable(false);
            return400Error(error);
          });
      }
    }
  };

  useEffect(() => {
    if (selectedOffer?.id) {
      if (selectedOffer.customer_id) {
        setSelectUser(true);
      }
      setBtnDisable(
        selectedOffer.customer_list?.length === 0 &&
        selectedOffer.category_ids?.length === 0 &&
        selectedOffer.sub_category_ids?.length === 0 &&
        selectedOffer.product_ids?.length === 0
      );
      setFormData({
        ...form,
        offer_percent: selectedOffer.offer_percent,
        offer_amount: selectedOffer.offer_amount,
        offer_description: selectedOffer.offer_description,
        coupon_code: selectedOffer.coupon_code,
        terms_and_conditions: selectedOffer.terms_and_conditions,
      });
    }
  }, [selectedOffer]);

  const CouponsCartUi = ({ data }) => {
    const getNameValue = (list, key) =>
      list.reduce(
        (ac, c, i) => (ac = ac + c[key] + (i !== list.length - 1 ? " , " : "")),
        ""
      );

    return (
      <>
        <div className="card border-primary border" key={data?.id}>
          <div className="card-body">
            <h4 className="card-title text-primary mb-2">
              <i className="uil-gift"></i> {data?.coupon_code}
            </h4>
            {/* <div className="alert alert-warning"></div> */}
            <p className="cart-text">
              <b>T&C - </b>
              <small>{data?.terms_and_conditions}</small>
            </p>
            <p className="card-text p-0 m-0">
              <b>Description - </b>
              {data?.offer_description}
            </p>
            {!data?.offer_percent ? (
              // <p className="card-text p-0 m-0">
              //   <b>Offer Amount - </b>
              //   {!data?.offer_amount ? "N/A" : "QAR " + data?.offer_amount}
              // </p>
              <></>
            ) : (
              <p className="card-text p-0 m-0">
                <b>Offer Percent - </b>
                {data?.offer_percent === null
                  ? "N/A"
                  : data?.offer_percent + "%"}
              </p>
            )}
            {data?.category_list.length > 0 &&
              <p className="card-text p-0 m-0">
                <b>Category - </b>
                {data?.category_list &&
                  getNameValue(data?.category_list, "categoryName")}
              </p>}
            {data?.sub_category_list.length > 0 &&
              <p className="card-text p-0 m-0">
                <b>Sub Category - </b>
                {getNameValue(data?.sub_category_list, "subCategoryName")}
              </p>}
            {data?.product_list.length > 0 &&
              <p className="card-text p-0 m-0">
                <b>Product - </b>
                {getNameValue(data?.product_list, "productName")}
              </p>}
            {data?.customer_id &&
              <p className="card-text p-0 m-0">
                <b>Customer - </b>
                {getNameValue(data?.customer_list, "customerName")}
              </p>}
            <p className="card-text p-0 m-0">
              <b>Modify on - </b>
              {formatDate(data?.modified_on)}
            </p>
            <p className="card-text p-0 m-0 mt-2">
              {formatDate(data?.start_date)}
              <b> &nbsp;{" - "}&nbsp; </b>
              {formatDate(data?.end_date)}
            </p>

            <div className="w-100 mt-3 d-flex align-items-center justify-content-between">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setIsOpenAddData(true);
                  setSelectedOffer(data);
                }}
              >
                <i className="uil-edit"></i> Edit
              </button>
              <ToggleCheck
                value={data?.is_active}
                id={data?.id}
                baseURL={process.env.REACT_APP_BASE_URL}
                token={"Token " + sessionStorage.getItem("token")}
                url="/medpacks_admin/delete_coupon/"
                method={"DELETE"}
                className="mt-1"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const getValue = (list, key, prop) => {
    return (
      selectedOffer[prop] &&
      list.filter((item) =>
        selectedOffer[prop].length === 1
          ? selectedOffer[prop][0][key] === item.value
          : selectedOffer[prop].filter(
            (x) => x[key].toString() === item.value.toString()
          )?.length > 0
      )
    );
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <button
            className="btn btn-primary"
            onClick={() => {
              setIsOpenAddData(true);
              setSelectedOffer({});
            }}
          >
            <i class="mdi mdi-plus-circle"></i> Add Coupon
          </button>
          <div className="row mt-2">
            {coupons?.map((e) => {
              return (
                <div className="col-md-6">
                  <CouponsCartUi data={e} />
                </div>
              );
            })}
          </div>
          <CustomPagination
            totalItems={totalItems}
            totalItemToBeFetched={totalItemToBeFetched}
            setTotalItemToBeFetched={setTotalItemToBeFetched}
            pageNo={pageNo}
            setPageNo={setPageNo}
          />
        </div>
      )}

      <SideModal
        title={selectedOffer.id ? "Edit Coupon" : "Add Coupon"}
        setIsOpen={setIsOpenAddData}
        show={isOpenAddData}
        clearOnClose={() => {
          setFormData({});
          selectedOffer && setSelectedOffer({});
          setStartDate("");
          setEndDate("");
          setSelectUser(false);
        }}
      >
        <div className="form-group">
          <label htmlFor="offer_percent">Coupon Percent</label>
          <input
            className="form-control"
            type="number"
            name="offer_percent"
            id="offer_percent"
            value={form.offer_percent}
            onChange={(e) =>
              setFormData({
                ...form,
                offer_percent: e.target.value,
              })

            }
          />
        </div>
        {/* <div className="form-group">
          <label htmlFor="offer_amount">Coupon Amount</label>
          <input
            className="form-control"
            type="number"
            name="offer_amount"
            id="offer_amount"
            value={form.offer_amount}
            onChange={(e) =>
              setFormData({
                ...form,
                offer_amount: e.target.value,
              })
            }
          />
        </div> */}

        <div className="form-group">
          <label htmlFor="coupon_code">
            Coupon Code <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            type="text"
            name="coupon_code"
            required
            id="coupon_code"
            value={form.coupon_code}
            onChange={(e) =>
              setFormData({
                ...form,
                coupon_code: e.target.value,
              })
            }
          />
        </div>

        <div className="form-group mt-4">
          <input
            type="checkbox"
            className="mr-1"
            id="user-select-checkbox"
            name="user-select-checkbox"
            value={select_all}
            style={{
              height: "20px",
              width: "20px",
            }}
            onChange={(e) => {
              setAllDisabled(
                e.target.checked ||
                !form.category_id ||
                !form.sub_category_id ||
                !form.product_id ||
                !form.customer_id
              );
              setOfferForAll(e.target.checked);
              setFormData({
                ...form,
                category_id: [],
                sub_category_id: [],
                product_id: [],
                customer_id: []
              });
            }}
          />{" "}
          <label
            style={{
              position: "relative",
              top: "-6px",
              fontSize: "16px",
            }}
          >
            Apply coupon on all products
          </label>
        </div>

        <div className="form-group mt-4">
          <input
            type="checkbox"
            className="mr-1"
            id="user-select-checkbox"
            name="user-select-checkbox"
            value={selectUser}
            style={{
              height: "20px",
              width: "20px",
            }}
            onChange={(e) => setSelectUser(e.target.checked)}
          />{" "}
          <label
            style={{
              position: "relative",
              top: "-6px",
              fontSize: "16px",
            }}
          >
            Check to Select Users
          </label>
        </div>

        {selectUser && (
          <div className="form-group">
            <label htmlFor="customer_id">Customer</label>
            <ReactSelect
              className="mt-2"
              name="customer_id"
              id="customer_id"
              options={alluser}
              isMulti
              isDisabled={select_all}
              placeholder="Select customer"
              value={
                selectedOffer?.customer_id?.toString().length > 0 &&
                  !form.customer_id
                  ? getValue(alluser, "customerId", "customer_list")
                  : form?.customer_id
              }
              onChange={(e) => {
                setFormData({
                  ...form,
                  customer_id: e,
                });
                setBtnDisable(
                  e.length > 0
                    ? false
                    : (e.length === 0 ||
                      selectedOffer.customer_list?.length === 0) &&
                    (form.sub_category_id?.length === 0 ||
                      selectedOffer.sub_category_list?.length === 0) &&
                    (form.category_id?.length === 0 ||
                      selectedOffer.category_list?.length === 0) &&
                    (form.product_id?.length === 0 ||
                      selectedOffer.product_list?.length === 0)
                );
              }}
            />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="category_id">Coupon Category</label>
          <ReactSelect
            className="mt-2 flex-grow-1 mr-1"
            name="category_id"
            isMulti
            id="category_id"
            options={allcategory}
            placeholder="Select category"
            value={
              selectedOffer?.category_list?.length > 0 && !form.category_id
                ? getValue(allcategory, "categoryId", "category_list")
                : form?.category_id
            }
            isDisabled={select_all}
            onChange={(e) => {
              setFormData({
                ...form,
                category_id: e,
              });
              setBtnDisable(
                e.length > 0
                  ? false
                  : (e.length === 0 ||
                    selectedOffer.category_list?.length === 0) &&
                  (form.sub_category_id?.length === 0 ||
                    selectedOffer.sub_category_list?.length === 0) &&
                  (form.product_id?.length === 0 ||
                    selectedOffer.product_list?.length === 0) &&
                  (form.customer_id?.length === 0 ||
                    selectedOffer.customer_list?.length === 0)
              );
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="sub_category_id">Coupon Sub Category</label>
          <ReactSelect
            className="mt-2 mr-1 flex-grow-1"
            name="sub_category_id"
            id="sub_category_id"
            isMulti
            options={allsubcategory}
            placeholder="Select category"
            isDisabled={select_all}
            value={
              selectedOffer?.sub_category_list?.length > 0 &&
                !form.sub_category_id
                ? getValue(allsubcategory, "subCategoryId", "sub_category_list")
                : form?.sub_category_id
            }
            // value={form.sub_category_id}
            onChange={(e) => {
              setFormData({
                ...form,
                sub_category_id: e,
              });
              setBtnDisable(
                e.length > 0
                  ? false
                  : (e.length === 0 ||
                    selectedOffer.sub_category_list?.length === 0) &&
                  (form.category_id?.length === 0 ||
                    selectedOffer.category_list?.length === 0) &&
                  (form.product_id?.length === 0 ||
                    selectedOffer.product_list?.length === 0) &&
                  (form.customer_id?.length === 0 ||
                    selectedOffer.customer_list?.length === 0)
              );
            }}
          />
        </div>

        <div className="form-group  mb-4">
          <label htmlFor="product_id">
            Coupon Products
          </label>
          <ReactSelect
            className="mt-2 mr-1 flex-grow-1"
            name="product_id"
            id="product_id"
            isMulti
            options={allproduct}
            placeholder="Select product"
            isDisabled={select_all}
            value={
              selectedOffer?.product_list?.length > 0 && !form.product_id
                ? getValue(allproduct, "productId", "product_list")
                : form?.product_id
            }
            // value={form.product_id}
            onChange={(e) => {
              setFormData({
                ...form,
                product_id: e,
              });
              setBtnDisable(
                e.length > 0
                  ? false
                  : (e.length === 0 ||
                    selectedOffer.product_list?.length === 0) &&
                  (form.sub_category_id?.length === 0 ||
                    selectedOffer.sub_category_list?.length === 0) &&
                  (form.category_id?.length === 0 ||
                    selectedOffer.category_list?.length === 0) &&
                  (form.customer_id?.length === 0 ||
                    selectedOffer.customer_list?.length === 0)
              );
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="offer_description">
            Coupon Description <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            type="text"
            name="offer_description"
            required
            id="offer_description"
            value={form.offer_description}
            onChange={(e) =>
              setFormData({
                ...form,
                offer_description: e.target.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="terms_and_conditions">
            Terms & Conditions <span className="text-danger">*</span>
          </label>
          <textarea
            className="form-control"
            type="text"
            name="terms_and_conditions"
            required
            id="terms_and_conditions"
            rows={2}
            value={form.terms_and_conditions}
            onChange={(e) =>
              setFormData({
                ...form,
                terms_and_conditions: e.target.value || ' ',
              })
            }
          />
        </div>
        <div className="w-100">
          <div className="w-100 pr-1">
            <div className="form-group">
              <div>
                <label htmlFor="start_date">
                  Start Date <span className="text-danger">*</span>
                </label>
              </div>
              <DateTimePicker
                onDateTimeChange={setStartDate}
                minDate={
                  selectedOffer?.start_date &&
                    moment(selectedOffer?.start_date, "YYYY-MM-DD").diff(
                      moment(new Date(), "YYYY-MM-DD")
                    ) < 0
                    ? new Date(selectedOffer.start_date)
                    : new Date()
                }
                defaultValue={
                  selectedOffer?.start_date
                    ? new Date(
                      moment(
                        `${selectedOffer?.start_date} ${selectedOffer?.start_time}`,
                        "YYYY-MM-DD Thh:mm"
                      )
                    )
                    : null
                }
              />
            </div>
          </div>
          <div className="w-100 pl-1 mb-2">
            <div className="form-group">
              <div>
                <label htmlFor="end_date">
                  End Date <span className="text-danger">*</span>
                </label>
              </div>
              <DateTimePicker
                onDateTimeChange={setEndDate}
                disabled={!startDate}
                minDate={
                  startDate
                    ? new Date(startDate)
                    : selectedOffer?.start_date
                      ? new Date(selectedOffer.start_date)
                      : new Date()
                }
                defaultValue={
                  selectedOffer?.end_date
                    ? new Date(
                      moment(
                        `${selectedOffer?.end_date} ${selectedOffer?.end_time}`,
                        "YYYY-MM-DD Thh:mm"
                      )
                    )
                    : null
                }
              />
            </div>
          </div>
        </div>
        {/* <div className="form-group">
            <label htmlFor="offer_category_id">Offer Category</label>
            <ReactSelect
              className="mt-2"
              name="offer_category_id"
              isMulti
              id="offer_category_id"
              options={allcategory}
              placeholder="Select Offer category"
              value={(selectedOffer?.category_id?.toString().length > 0 && !form.offer_category_id) ? getValue(allcategory, 'category_id') : form?.offer_category_id}
              onChange={(e) => {
                setFormData({
                  ...form,
                  offer_category_id: e
                });
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="offer_sub_category_id">Offer Sub Category</label>
            <ReactSelect
              className="mt-2"
              name="offer_sub_category_id"
              id="offer_sub_category_id"
              isMulti
              options={allsubcategory}
              placeholder="Select Offer Sub category"
              value={(selectedOffer?.sub_category_id?.toString().length > 0 && !form.offer_sub_category_id) ? getValue(allsubcategory, 'sub_category_id') : form?.offer_sub_category_id}
              onChange={(e) => {
                setFormData({
                  ...form,
                  offer_sub_category_id: e
                });
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="offer_product_id">
              Offer Products <span className="text-danger">*</span>
            </label>
            <ReactSelect
              className="mt-2"
              name="offer_product_id"
              id="offer_product_id"
              isMulti
              options={allproduct}
              placeholder="Select Offer products"
              value={(selectedOffer?.product_id?.toString().length > 0 && !form.offer_product_id) ? getValue(allproduct, 'product_id') : form?.offer_product_id}
              onChange={(e) => {
                setFormData({
                  ...form,
                  offer_product_id: e
                });
              }}
            />
          </div> */}
        <div className="form-group mt-2 text-center mb-5">
          <button
            className="btn btn-primary px-4"
            disabled={btnDisable}
            onClick={handleSubmitAddData}
          >
            {selectedOffer.id ? "Update" : "ADD"}
          </button>
        </div>
      </SideModal>
    </>
  );
};

export default Coupons;
