import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { return400Error } from '../../Common/Errors';

const FAQStatus = ({ e, onSuccess, setLoader }) => {
    const [itemStatus, setItemStatus] = useState(e.status);

    const submitFAQResponse = () => {
        const formData = new FormData();
        formData.set('question', e.question);
        formData.set('answer', e.answer);
        formData.set('status', itemStatus === 'PUBLISH' ? 'DRAFT' : 'PUBLISH');
        e?.id && formData.set('faq_id', e.id);
        axios({
            method: "POST",
            url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/faq",
            headers: {
                Authorization: "Token " + sessionStorage.getItem("token"),
            },
            data: formData,
        })
            .then(function (response) {
                if (response.data.status === 200) {
                    // setLoader(true);
                    onSuccess();
                } else if (response.data.status === 400) {
                    setLoader(false);
                    toast.error(response.data.message);
                }
            })
            .catch(function (error) {
                setLoader(false);
                return400Error(error);
            });
    }
    return (
        <div className={`custom-control custom-switch mb-1`} id={e.id}>
            <input
                type="checkbox"
                className="custom-control-input"
                id={'status' + e.id}
                defaultChecked={itemStatus === 'PUBLISH'}
                onClick={() => {
                    setItemStatus(prevState => prevState === 'PUBLISH' ? 'DRAFT' : 'PUBLISH');
                    submitFAQResponse();
                }}
            />
            <label
                className="custom-control-label"
                htmlFor={"status" + e.id}
            >{itemStatus}</label>
        </div>
    );
}

export default FAQStatus;