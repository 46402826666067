import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { DoctorPanelMenu, SettingsMenuBar, SideBarMenus } from '../Common/SideMenu';

function withPermissions(Component) {
  function ComponentWithRouterProp(props) {
    const [user, setUserData] = useState({});
    const current = useLocation();
    const navigate = useNavigate();
    const allRoutes = [
      ...SideBarMenus,
      ...SettingsMenuBar,
      ...DoctorPanelMenu,
    ];
    const currentRouteObj = allRoutes.filter(route => route.url === current.pathname)[0];

    useEffect(() => {
      if (sessionStorage.getItem("user")) {
        setUserData(JSON.parse(atob(sessionStorage.getItem("user"))));
      }
    }, []);
    useEffect(() => {
      if (user?.name && current.pathname !== '/' && !((currentRouteObj?.role ? user?.permissions?.[currentRouteObj.role] : true))) {
        navigate('/no-access=true');
      }
    }, [user]);


    return (
      <Component
        {...props}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withPermissions;