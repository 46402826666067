import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Drawer from "react-modern-drawer";
import Table from "../../Common/Table";
import CustomersFilter from "../SubCmp/CustomersFilter";
import ToggleCheck from "../../Common/ToggleCheck";
import EditData from "../../Common/EditData";
import DateFormater from "../../Common/DateFormater";
import { useNavigate } from "react-router-dom";
import { return400Error } from "../../Common/Errors";
import CountryCodes from "../../Common/CountryCodes.json"

const PhoneCode = () => {
  const navigate = useNavigate();
  const [countryCodes, setCountryCodes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAddCode, setIsOpenAddCode] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const [successResponse, sendSuccesResponse] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);
  const [countryCode, setCountryCode] = useState(null);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "country_code",
      text: "Phone Code",
    },
    {
      dataField: "modified_on",
      text: "Modified on",
      sort: true,
    },
    {
      dataField: "is_active",
      text: "Status",
    },
    {
      dataField: "edit",
      text: "Edit",
    },
  ];

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  const fetchPhoneCodes = () => {
    setLoader(true);
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_phone_codes?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoader(false);
        setTotalItems(response.data.total_count);
        if (response.data.status === 1) {
          setCountryCodes([]);
          // customize data
          response.data.data.map((e, i) => {
            let updatedata = {
              id: e.id,
              country_code: e.country_code,
              modified_on: <DateFormater time={e.modified_on} />,
              is_active: (
                <ToggleCheck
                  value={e.is_active}
                  countryCode={e.country_code}
                  id={e.id}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  token={"Token " + sessionStorage.getItem("token")}
                  url="/medpacks_admin/delete_phone_codes/"
                  method={"DELETE"}
                // onSuccess={fetchPhoneCodes}
                />
              ),
              edit: (
                <EditData data={e} sendSuccesResponse={sendSuccesResponse} onSuccess={fetchPhoneCodes} />
              ),
            };
            setCountryCodes((oldArray) => [...oldArray, updatedata]);
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        if (error.response.data.is_authenticated === "False") {
          toast.error(error.response.data.error);
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("user");
          navigate("/");
        } else {
          setLoader(false);
          return400Error(error);
        }
      });
  };

  useEffect(() => {
    fetchPhoneCodes();
  }, [pageNo, totalItemToBeFetched]);

  // add phone code
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidNumber = /\d/g.test(countryCode);
    if (isValidNumber) {
      setBtnDisable(true);
      axios
        .post(
          process.env.REACT_APP_BASE_URL + "/medpacks_admin/save_phone_code",
          {
            country_code: countryCode,
          },
          {
            headers: {
              Authorization: "Token " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          setBtnDisable(false);
          sendSuccesResponse(res.data.status);
          fetchPhoneCodes();
          toast.success(res.data.message);
          setIsOpenAddCode(false);
        })
        .catch(function (error) {
          setBtnDisable(false);
          return400Error(error);
        });
    }
    else {
      toast.warning("Country Code should contain only number and special characters or number not only  special characters")
    }
  };

  return (
    <>
      {/* filter button */}
      <div className="w-100 pb-3 d-flex justify-content-between">
        <button
          className="btn btn-primary"
          onClick={() => setIsOpenAddCode(true)}
        >
          <i class="mdi mdi-plus-circle mr-1"></i> Add Phone Code
        </button>
        {/* <button className="btn btn-primary" onClick={toggleDrawer}>
          <i className="uil-filter mr-1"></i> Filter Data
        </button> */}
      </div>

      {/* table data */}
      <Table
        data={countryCodes}
        columns={columns}
        maxRow={200}
        loader={loader}
        totalItems={totalItems}
        totalItemToBeFetched={totalItemToBeFetched}
        setTotalItemToBeFetched={setTotalItemToBeFetched}
        pageNo={pageNo}
        setPageNo={setPageNo}
      />

      {/* right drawer for add phone code */}
      <Drawer
        open={isOpenAddCode}
        onClose={() => setIsOpenAddCode(false)}
        direction="right"
        duration={200}
        className="CommonDrawer w-25"
      >
        <div className="rightbar-title">
          <a
            href="javascript:void(0);"
            className="right-bar-toggle float-right"
            onClick={() => setIsOpenAddCode(false)}
          >
            <i className="dripicons-cross noti-icon"></i>
          </a>
          <h5 className="m-0">
            <i class="mdi mdi-plus-circle mr-1"></i> Add Phone Code
          </h5>
        </div>
        <div className="rightbar-content h-100 px-3" data-simplebar>
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="form-group">
              <label htmlFor="country_code">
                Country Code <span className="text-danger">*</span>
              </label>
              {/* <input
                className="form-control"
                type="text"
                name="country_code"
                required
                value={countryCode}
                id="country_code"
                onChange={(event) => {
                  const isValid = /^[0-9\W_]*$/.test(event.target.value);
                  if (isValid) {
                    setCountryCode(event.target.value);
                  }
                  return false;
                }}
              /> */}
              <select
                name="country_code"
                id="country_code"
                className="form-control"
                value={countryCode}
                onChange={(event) => setCountryCode(event.target.value)}
              >
                <option value="">Select country code</option>
                {
                  CountryCodes.map((e, i) => <option key={i} value={e.dial_code}>{e.name} ({e.dial_code})</option>)
                }
              </select>
            </div>

            <div className="form-group mb-0 text-center">
              <button
                className="btn btn-primary"
                disabled={btnDisable}
                type="submit"
              >
                {" "}
                {"Add"}{" "}
              </button>
            </div>
          </form>
        </div>
      </Drawer >

      {/* right drawer for filter */}
      < Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        duration={200}
        className="CommonDrawer w-25"
      >
        <div className="rightbar-title">
          <a
            href="javascript:void(0);"
            className="right-bar-toggle float-right"
            onClick={toggleDrawer}
          >
            <i className="dripicons-cross noti-icon"></i>
          </a>
          <h5 className="m-0">
            <i className="uil-filter"></i> Filtter Phone Code
          </h5>
        </div>
        <div className="rightbar-content h-100 px-3" data-simplebar>
          <CustomersFilter />
        </div>
      </Drawer >
    </>
  );
};

export default PhoneCode;
