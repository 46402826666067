import * as React from 'react';
import { useState } from 'react';
import './SideModal.scss';

const SideModal = ({
    title,
    setIsOpen,
    show,
    children,
    clearOnClose,
    contentStyle
}) => {
    if (show) {
        return (
            <div className='sideModal card'>
                <div className="modal-content" style={contentStyle}>
                    <div className='modal-header d-flex pt-3'>
                        <div className='flex-grow-1' style={{ fontSize: '18px' }}><i class="uil-edit mr-1"></i>{title}</div>
                        <button
                            className='btn btn-primary close-button'
                            onClick={() => { setIsOpen(prevState => !prevState); clearOnClose(); }}
                        >&times;</button>
                    </div>
                    <div className='form'>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
    return <></>;
}

export default SideModal;