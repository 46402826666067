import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import withRouter from '../../../HOCs/withRouter';
import './styles.scss';

import moment from 'moment';
import CommonPanel from './panels/CommonPanel';
import { orderColumns, prescriptionColumns, wishlistColumns } from './columns';
import { TABS } from './panels/utilities';
import AddressPanel from './panels/AddressPanel';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { return400Error } from '../../../Common/Errors';

const Profile = ({
  setShowSummary,
  router: {
    location: {
      state
    },
    navigate,
  }
}) => {

  const [details, setDetails] = useState([]);
  const ID = state?.order?.id || '';
  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_BASE_URL + "/medpacks_admin/get_single_user/" + ID,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          setDetails(response.data.data);
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        return400Error(error);
      });
  }, []);


  return (
    <div className='profile'>
      <div className='card profile-card'>
        <div className='d-flex card-header'>
          <div className='header-image'><div>{details?.name && details?.name.slice(0, 2).toUpperCase()}</div></div>
          <p className='header-info flex-grow-1'>
            <div>{details?.name && details?.name[0].toUpperCase() + details?.name.substring(1)}</div>
            <div>Phone: {`${details?.country_code ? `(${details?.country_code})` : ''}${' '}${details?.phone_number}`}</div>
            <div>Email: {details?.email}</div>
          </p>
          <button
            className="btn btn-outline-primary"
            style={{
              padding: '2px 6px',
              height: '10%',
              fontSize: '16px'
            }}
            onClick={() => { navigate('/customers'); setShowSummary(false); }}
          >
            <i className='mdi mdi-arrow-left' />
          </button>
        </div>
      </div>
      <div className='tabs-list'>
        <Tabs className='profile-tabs'>
          <TabList className='tabs'>
            <Tab>Customer Details</Tab>
            <Tab>Addresses</Tab>
            <Tab>Wishlist</Tab>
            <Tab>Orders</Tab>
            <Tab>Prescriptions</Tab>
          </TabList>

          <TabPanel>
            <div className='card profile-details'>
              <div>
                <label>Name</label>
                <span>{details?.name || '_'}</span>
              </div>
              <div>
                <label>Email Id:</label>
                <span className='email'>{details?.email || '_'}</span>
              </div>
              <div>
                <label>Gender:</label>
                <span>{details?.gender || '_'}</span>
              </div>
              <div>
                <label>DOB:</label>
                <span>{moment(details?.dob).format('DD MMM YYYY') || '_'}</span>
              </div>
              <div>
                <label>Phone No:</label>
                <span>{`${details?.country_code ? `(${details?.country_code})` : ''}${details?.phone_number ? details?.phone_number.toString().slice(0, 4) + ' ' + details?.phone_number.toString().slice(4) : ''}` || '_'}</span>
              </div>
              <div>
                <label>Nationality:</label>
                <span>{details?.country || '_'}</span>
              </div>
              <div>
                <label>Passport No:</label>
                <span>{details?.passport_no || '_'}</span>
              </div>
              <div>
                <label>National ID:</label>
                <span>{details?.national_id || '_'}</span>
              </div>
            </div>
          </TabPanel>

          <TabPanel className='profile-addresses'>
            <AddressPanel
              ID={ID}
              url='/medpacks_admin/get_all_users_address_info'
            />
          </TabPanel>

          <TabPanel className=' profile-wishlist'>
            <CommonPanel
              ID={ID}
              url='/medpacks_admin/get_all_users_wishlist_info'
              columns={wishlistColumns}
              tab={TABS.WISHLIST}
            />
          </TabPanel>
          <TabPanel className='profile-orders'>
            <CommonPanel
              ID={ID}
              url='/medpacks_admin/get_all_users_orders_info'
              columns={orderColumns}
              tab={TABS.ORDERS}
              searchKey={'details.order_id'}
            />
          </TabPanel>
          <TabPanel className='profile-prescriptions'>
            <CommonPanel
              ID={ID}
              url='/medpacks_admin/get_all_users_prescription_info'
              columns={prescriptionColumns}
              tab={TABS.PRESCRIPTIONS}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default withRouter(Profile);