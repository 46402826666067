import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Drawer from "react-modern-drawer";
import DateFormater from "../../Common/DateFormater";
import Table from "../../Common/Table";
import ToggleCheck from "../../Common/ToggleCheck";
import CommonTable from "../../Common/CommonTable";
import { return400Error } from "../../Common/Errors";

const TopMenu = () => {
  const [topMenu, setTopMenu] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isOpenAddTopmenu, setIsOpenAddTopmenu] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [successResponse, sendSuccesResponse] = useState(null);
  const [category_id, setCategory_id] = useState(null);
  const [sequence, setSequence] = useState(null);
  const [allCategory, setAllCategory] = useState([]);

  const columns = [
    {
      dataField: "sl_no",
      text: "Index no.",
      sort: true,
    },
    {
      dataField: "id",
      text: "Menu ID",
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: "category_name",
      text: "Category Name",
      sort: true,
      sort: true,
      isFilterable: true,
      type: "input",
    },
    {
      dataField: "sequence",
      text: "Sequence",
      sort: false,
      isFilterable: false,
    },
    // {
    //   dataField: "modified_on",
    //   text: "Modified on",
    //   sort: true,
    //   isFilterable: true,
    //   type: "datePicker",
    // },
    {
      dataField: "is_active",
      text: "Status",
      sort: true,
      isFilterable: true,
      type: "dropDown",
    },
  ];

  const fetchData = () => {
    axios({
      method: "get",
      url:
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_top_menu_categories",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.status === 1) {
          setTopMenu([]);
          // customize data
          response.data.data.map((e, i) => {
            let updatedata = {
              sl_no: i + 1,
              id: e.id,
              category_name: e.category_name,
              sequence: (
                <>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={e.sequence}
                    onChange={(event) => {
                      if (!isNaN(event.target.value)) {
                        axios
                          .put(
                            process.env.REACT_APP_BASE_URL +
                            "/medpacks_admin/update_top_menu_category/" +
                            e.id,
                            {
                              category_id: e.category_id,
                              sequence: Number(event.target.value),
                            },
                            {
                              headers: {
                                Authorization:
                                  "Token " + sessionStorage.getItem("token"),
                              },
                            }
                          )
                          .then((res) => {
                            toast.success(res.data.message);
                          })
                          .catch((error) => {
                            const { status, message } = error.response.data.data;
                            if (status === "400") {
                              sessionStorage.clear()
                              window.location = "/";
                              toast.error(message);
                            }
                            else {
                              return400Error(error);
                            }
                          });
                      } else {
                        toast.warning("Please enter a number");
                      }
                    }}
                  />
                </>
              ),
              // modified_on: <DateFormater time={e?.modified_on} />,
              is_active: (
                <ToggleCheck
                  value={e.is_active}
                  id={e.id}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  token={"Token " + sessionStorage.getItem("token")}
                  url="/medpacks_admin/delete_top_menu_category/"
                  method={"DELETE"}
                  onSuccess={fetchData}
                  onError={() => { setLoader(true); setTopMenu([]); fetchData(); }}
                />
              ),
            };
            setTopMenu((oldArray) => [...oldArray, updatedata]);
          });
        } else if (response.data.status === 400) {
          toast.warning(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  }
  useEffect(() => {
    fetchData();
  }, [successResponse]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnDisable(true);
    axios
      .post(
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/save_top_menu_category",
        {
          category_id,
          sequence,
        },
        {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setBtnDisable(false);
        setSequence(null);
        setCategory_id(0);
        window.location = "/top-menu";
        setIsOpenAddTopmenu(false);
        sendSuccesResponse(res.data.status);
        toast.success(res.data.message);
      })
      .catch(function (error) {
        setBtnDisable(false);
        return400Error(error);
      });
  };

  return (
    <>
      <div className="w-100 pb-3">
        <button
          className="btn btn-primary"
          onClick={() => {
            setIsOpenAddTopmenu(true);
            axios({
              method: "get",
              url: process.env.REACT_APP_BASE_URL + "/category/AllCategories",
            })
              .then(function (response) {
                setAllCategory(response.data.data);
              })
              .catch(function (error) {
                return400Error(error);
              });
          }}
        >
          <i class="mdi mdi-plus-circle mr-1"></i> Add Top Menu
        </button>
      </div>
      {/* table data */}
      <CommonTable
        data={topMenu}
        searchKey="category_name"
        columns={columns}
        maxRow={10}
        loader={loader}
        search={true}
        enableFESearchFilter={true}
      />

      {/* right drawer for add top menu */}
      <Drawer
        open={isOpenAddTopmenu}
        onClose={() => setIsOpenAddTopmenu(false)}
        direction="right"
        duration={200}
        className="CommonDrawer w-25"
      >
        <div className="rightbar-title">
          <a
            href="javascript:void(0);"
            className="right-bar-toggle float-right"
            onClick={() => setIsOpenAddTopmenu(false)}
          >
            <i className="dripicons-cross noti-icon"></i>
          </a>
          <h5 className="m-0">
            <i class="mdi mdi-plus-circle mr-1"></i> Add Top Menu
          </h5>
        </div>
        <div className="rightbar-content h-100 px-3" data-simplebar>
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="form-group">
              <label htmlFor="category_id">Category</label>
              <select
                name="category_id"
                id="category_id"
                className="form-control"
                defaultValue={category_id}
                onChange={(event) => setCategory_id(event.target.value)}
              >
                <option value={0}>Select</option>
                {allCategory.map((e) => {
                  return <option value={e.id}>{e.name}</option>;
                })}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="sequence">
                Sequence <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="number"
                name="sequence"
                required
                defaultValue={sequence}
                id="sequence"
                onChange={(event) => setSequence(event.target.value)}
              />
            </div>

            <div className="form-group mb-0 text-center">
              <button
                className="btn btn-primary"
                disabled={btnDisable}
                type="submit"
              >
                {" "}
                {"Add"}{" "}
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default TopMenu;
