import * as React from 'react';
import { useState } from 'react';
import OrderItem from './OrderItem';
import OrderAddress from './OrderShippingAddress';
import OrderTracking from './TrackOrder';

const apiUrl = process.env.REACT_APP_BASE_URL;

const Products = ({ data, productKey, setShowProductsList, setRow }) => {
  const [image, setImage] = useState({});
  const {
    details: {
      order_id,
      order_placed_date,
      products,
    }
  } = data;
  return (
    <>
      {
        <div className='customer-Products-orders'>
          <div className='header'>
            <div className='all-orders'>
              <OrderItem orders={[data]} />
              <OrderAddress address={data?.address_data} />
              <OrderTracking status={data?.order_status} />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Products;