import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CarouselCmp from "../../Common/CarouselCmp";
import ToggleCheck from "../../Common/ToggleCheck";
import Loader from "../../Common/Loader";
import Drawer from "react-modern-drawer";
import { return400Error } from "../../Common/Errors";
import SideModal from "./SideModal";
import CustomPagination from "../../Common/CustomPagination";

const Carousel = () => {
  const [carouselData, setCarouselData] = useState([]);
  const [activeBtn, setActiveBtn] = useState(1);
  const [loader, setLoader] = useState(true);
  const [title, setTitle] = useState("Home Page - Top");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [url, setUrl] = useState();
  const [selectedItem, setSelectedItem] = useState({});
  const [imageError, setImageError] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');

  useEffect(() => {
    fetchCarousel();
  }, [activeBtn, pageNo, totalItemToBeFetched]);

  useEffect(() => {
    setUrl(selectedItem.url || "");
  }, [selectedItem]);

  const fetchCarousel = () => {
    setLoader(true);
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_home_page_banner_" +
        activeBtn +
        "?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
        {
          headers: {
            Authorization: "Token " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoader(false);
        setTotalItems(res.data.total_count);
        setCarouselData(res.data);
      })
      .catch((error) => {
        const { status, message } = error.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          setLoader(false);
          setCarouselData([]);
          return400Error(error);
        }
      });
  };

  const CarouselBtns = [
    {
      txt: "Home Page - Top",
      index: 1,
    },
    {
      txt: "Home Page - Middle",
      index: 2,
    },
    {
      txt: "Home Page - Last",
      index: 3,
    },
    // {
    //   txt: "Products page - Top",
    //   index: 4,
    // },
  ];

  // add image to carousel
  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnDisable(true);
    const form = e.target;
    const formData = new FormData(form);

    if (selectedItem?.id) {
      formData.append("is_active", selectedItem.is_active);
      // if (selectedItem.url !== formData.get('url')) {
      //   formData.delete('url');
      // }
      axios
        .put(
          process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/update_home_page_banner_" +
          activeBtn +
          "/" +
          selectedItem.id,
          formData,
          {
            headers: {
              Authorization: "Token " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.status === 400) {
            return400Error({
              response: {
                status: 400,
                data: { ["data Request failed"]: res.data.data },
              },
            });
          } else {
            toast.success(res.data.message);
          }
          setOpenDrawer(false);
          setBtnDisable(false);
          setImgUrl(null);
          fetchCarousel();
          setSelectedItem({});
          setOpenDrawer(false);
          setImage("");
          setImgUrl("");
          setUrl("");
          setImage("");
        })
        .catch(function (error) {
          setBtnDisable(false);
          return400Error(error);
        });
    } else {
      axios
        .post(
          process.env.REACT_APP_BASE_URL +
          "/medpacks_admin/save_home_page_banner_" +
          activeBtn,
          formData,
          {
            headers: {
              Authorization: "Token " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          setOpenDrawer(false);
          setBtnDisable(false);
          setImgUrl(null);
          fetchCarousel();
          setUrl("");
          setImage("");
        })
        .catch(function (error) {
          setBtnDisable(false);
          return400Error(error);
        });
    }
  };

  return (
    <>
      {loader ? (
        <div className="py-5" style={{ background: "#ededed" }}>
          <Loader />
        </div>
      ) : (
        <CarouselCmp data={carouselData} />
      )}

      <div className="container mt-5">
        <div className="row">
          {/* right */}
          <div className="col-md-4 pl-0">
            <button
              className="btn btn-primary w-100 mb-2"
              onClick={() => setOpenDrawer(true)}
            >
              <i class="mdi mdi-plus-circle"></i> Add Image To {title}
            </button>
            <div className="card">
              <div className="card-body p-0">
                {CarouselBtns.map((e) => {
                  return (
                    <button
                      className={`btn w-100 py-3 ${activeBtn === e.index ? "btn-primary" : null
                        }`}
                      key={e.index}
                      onClick={() => {
                        setPageNo(1);
                        setActiveBtn(e.index);
                        setTitle(e.txt);
                      }}
                    >
                      {e.txt}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          {/* left */}
          <div className="col-md-8 pr-0">
            <div className="card">
              <div className="card-body p-0">
                <ul class="list-group">
                  {/* header */}
                  <li class="list-group-item bg-primary text-light d-flex align-items-center justify-content-between py-3">
                    <h4 className="d-flex align-items-center">
                      <i
                        class="uil-images mr-1"
                        style={{ fontSize: "25px" }}
                      ></i>{" "}
                      {title}
                    </h4>
                    <span
                      class="badge badge-light badge-pill"
                      style={{ fontSize: "14px" }}
                    >
                      {carouselData.data?.length}
                    </span>
                  </li>

                  {/* body */}
                  {loader ? (
                    <Loader />
                  ) : (
                    carouselData.data?.map((e) => {
                      return (
                        <li
                          class="list-group-item d-flex align-items-center justify-content-between"
                          key={e.id}
                        >
                          <span className="d-flex align-items-center">
                            <i
                              class={`${e.is_active
                                ? `uil-scenery text-success`
                                : `uil-image-block text-danger`
                                } mr-2`}
                              style={{ fontSize: "20px" }}
                            ></i>{" "}
                            <img
                              src={process.env.REACT_APP_BASE_URL + e.image}
                              alt="carousel_img"
                              width={250}
                              height={80}
                            />
                          </span>
                          {/* disable toggle */}
                          <span>
                            <ToggleCheck
                              value={e.is_active}
                              id={e.id}
                              onSuccess={fetchCarousel}
                              baseURL={process.env.REACT_APP_BASE_URL}
                              token={"Token " + sessionStorage.getItem("token")}
                              url={
                                "/medpacks_admin/delete_home_page_banner_" +
                                activeBtn +
                                "/"
                              }
                              method={"DELETE"}
                            />
                          </span>
                          <span>
                            <button
                              className="btn"
                              onClick={() => {
                                setSelectedItem(e);
                                setOpenDrawer(true);
                              }}
                            >
                              <i
                                className="uil-edit-alt"
                                style={{ fontSize: "22px" }}
                              ></i>
                            </button>
                          </span>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
              <div className="card-footer">
                <CustomPagination
                  totalItems={totalItems}
                  totalItemToBeFetched={totalItemToBeFetched}
                  setTotalItemToBeFetched={setTotalItemToBeFetched}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* right drawer for add phone code */}
      <SideModal
        title={`${selectedItem?.id ? "Update" : "Add"}` + " Images to " + title}
        setIsOpen={setOpenDrawer}
        show={openDrawer}
        clearOnClose={() => {
          setSelectedItem({});
          setOpenDrawer(false);
          setImage("");
          setImgUrl("");
        }}
        contentStyle={{ height: "100%" }}
      >
        {/* <Drawer
          open={openDrawer}
          onClose={() => { setUrl(''); setOpenDrawer(false) }}
          direction="right"
          duration={200}
          className="CommonDrawer w-25"
        > */}
        {/* <div className="rightbar-title">
            <a
              href="javascript:void(0);"
              className="right-bar-toggle float-right"
              onClick={() => setOpenDrawer(false)}
            >
              <i className="dripicons-cross noti-icon"></i>
            </a>
            <h5 className="m-0">
              <i class="mdi mdi-plus-circle"></i> Add Image to {title}
            </h5>
          </div> */}
        <div className="rightbar-content h-100 px-3" data-simplebar>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="image">
                Image <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                name="image"
                required={!selectedItem.image}
                // value={(selectedItem?.id && !image) ? '' : image}
                id="image"
                // onChange={(e) => {
                //   if (e.target.files && e.target.files[0]) {
                //     let file = e.target.files[0];
                //     if (['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].some(x => x === file?.type)) {
                //       setImage(file);
                //       setImgUrl(URL.createObjectURL(file));
                //       setImageError(false);
                //     } else {
                //       setImageError(true);
                //     }
                //   }
                //   e.target.value = null;
                // }}
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    let file = event.target.files[0];
                    setFileSize(file.size);
                    setFileType(file.type);
                    if (
                      [
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                        "application/pdf",
                      ].some((x) => x === file?.type) && Number((file.size / 1048576).toFixed(0)) <= 10
                    ) {
                      setImage(event.target.value);
                      const reader = new FileReader();
                      reader.readAsDataURL(event.target.files[0]);
                      reader.addEventListener("load", (e) => {
                        setImgUrl(e.target.result);
                      });
                      setImageError(false);
                    } else {
                      setImgUrl("");
                      setImageError(true);
                      event.target.value = null;
                    }
                  }
                }}
              />
              <label
                style={{
                  fontSize: '14px',
                  color: imageError ? "red" : "initial",
                  display: 'block',
                  marginTop: '5px'
                }}
              >
                Upload only .jpg, .png files. Size limit is 10 MB
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="url">URL</label>
              <input
                className="form-control"
                type="url"
                name="url"
                value={url}
                id="url"
                placeholder="https://example.com"
                onChange={(event) => setUrl(event.target.value)}
              />
            </div>
            {imgUrl || selectedItem.image ? (
              <div className="px-3">
                <img
                  src={
                    selectedItem?.id && !imgUrl
                      ? process.env.REACT_APP_BASE_URL + selectedItem.image
                      : imgUrl
                  }
                  alt="image"
                  style={{ maxWidth: '100%', maxHeight: '350px' }}
                />
              </div>
            ) : null}

            {(image || imageError) && selectedItem && Object.keys(selectedItem).length === 0 && <div className="row  mt-4">
              <label className="col row ml-1" style={{ color: imageError ? 'red' : 'initial' }}><label className="col">Type: {fileType}</label></label>
              <label className="col row" style={{ color: imageError ? 'red' : 'initial' }}><label className="col">Size: {(fileSize / 1048576).toFixed(2)}MB</label></label>
            </div>}

            <div className="form-group mb-0 text-center mt-3">
              <button
                className="btn btn-primary"
                disabled={btnDisable || imageError}
                type="submit"
              >
                {selectedItem?.id ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </div>
      </SideModal>
    </>
  );
};
export default Carousel;
