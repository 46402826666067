import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from './arrayMove';
import { render } from 'react-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { return400Error } from '../../Common/Errors';
import ReactSelect from 'react-select';
import Loader from '../../Common/Loader';

const SortableItem = SortableElement(({ value, setCheckedItems }) => <li className='card px-2  py-2 d-flex flex-row align-items-center'>
  <div className="form-group mt-3 mr-2">
    <input
      type="checkbox"
      className="mr-1"
      id="select-product"
      name="select-product"
      // value={selectUser}
      style={{
        height: "20px",
        width: "20px",
      }}
      value={value.isChecked}
      onChange={(e) => setCheckedItems({ itemId: value.id, isChecked: e.target.checked })}
    />
  </div>
  <span className='mr-2'>{value.sequence}</span>
  {value.product_images &&
    <img
      src={process.env.REACT_APP_BASE_URL + value.product_images[0]}
      height={20}
      width={20}
      className="mr-4"
    />}
  <span className='flex-grow-1'>{value.itemName}</span>
  <span className='d-flex align-items-center'
  >
    <i class="mdi mdi-menu"></i>
  </span>
</li>);

const SortableList = SortableContainer(({ items, setCheckedItems }) => {
  return (
    <ul
      style={{
        listStyle: 'none'
      }}
    >
      {items.map((item, index) => (
        <SortableItem setCheckedItems={setCheckedItems} key={`item-${item.itemName}-${index}`} index={index} value={item} />
      ))}
    </ul>
  );
});

const SortableComponent = ({
  data,
  setLoader,
}) => {
  const [items, setItems] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);


  const fetchData = () => {
    setLoader(true);
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_top_products?page_no=1&page_count=25",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoader(false);
        if (response.data.status === 1) {
          setNewItems(response.data.data);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch(function (error) {
        // setLoader(false);
        return400Error(error);
      });
  }

  useEffect(() => {
    setOptions(data);
  }, [data])

  useEffect(() => {
    fetchData();
  }, [])



  const onSortEnd = ({ oldIndex, newIndex }) => {
    setNewItems(arrayMove(newItems, oldIndex, newIndex));
  };

  const setCheckedItemsOnCheck = ({ itemId, isChecked }) => {
    setNewItems(newItems.map(item => ({ ...item, isChecked: item.id === itemId ? isChecked : item.isChecked, featured: item.id === itemId ? !isChecked : item.featured })));
    if (isChecked) {
      setCheckedItems(checkedItems.length > 0
        ? (
          !checkedItems.includes(',') ? [checkedItems, itemId].join() : [...checkedItems.split(','), itemId.toString()].join()
        )
        : [itemId])
    } else {
      setCheckedItems(checkedItems.length === 1 ? [] : checkedItems.split(',').filter(x => Number(x) !== Number(itemId)).join())
    }
  }

  const saveSequence = () => {
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/update_top_products",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
      data: newItems?.map(({ id, featured, isChecked }, index) => ({ id, sequence: index + 1, featured }))
    })
      .then(function (response) {
        if (response.data.status === 1) {
          setLoader(true);
          setNewItems([]);
          fetchData();
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch(function (error) {
        // setLoader(false);
        return400Error(error);
      });
  }

  const onDelete = () => {
    // setNewItems(newItems.filter(item => !checkedItems.split(',').includes(item.id.toString()))); setCheckedItems([]);
    saveSequence();
  }

  return (
    <>
      <div className="row mb-4 d-flex justify-content-end">
        {/* {options.length === 0 ? <p className='text-primary my-2'>Loading products....</p> : null} */}
        <button
          className="btn btn-primary mx-2"
          data-target="#add-top-products"
          data-toggle="modal"
        // disabled={options.length === 0}
        >
          <i class="mdi mdi-plus-circle"></i> Add Top Products
        </button>
        <button
          className="btn btn-primary mx-2"
          disabled={newItems.length === 0}
          onClick={() => saveSequence()}
        >
          Save
        </button>
        <button
          className="btn btn-primary mx-2"
          onClick={() => { onDelete() }}
          disabled={checkedItems.length === 0}
        >
          <i class="mdi mdi-delete"></i> Delete
        </button>
        <div class="modal" tabindex="-1" role="dialog" id='add-top-products' data-backdrop="static">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Top Products</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" onClick={() => { setSelectedItems([]); setItems([]); }}>&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="form-group">
                  <label htmlFor="top-products">Select Top Products</label>
                  <ReactSelect
                    className="mt-2"
                    name="top-products"
                    id="top-products"
                    options={newItems.length > 0 ? options.filter(option => !newItems.some(item => item.id === option.id)) : options}
                    isMulti
                    value={selectedItems}
                    onChange={(e) => {
                      let all;
                      if (newItems.length + e.length <= 25) {
                        all = e.map((x, i) => ({ ...x, sequence: newItems.length + i + 1, featured: true }));
                        setSelectedItems(all);
                        setItems(all);
                      } else {
                        all = e.slice(0, 25 - newItems.length).map((x, i) => ({ ...x, sequence: newItems.length + i + 1, featured: true }))
                        setSelectedItems(all);
                        setItems(all);
                        toast.error('Max 25 items can be show');
                      }
                    }}
                    styles={{
                      multiValueLabel: (styles, { data }) => {
                        return {
                          ...styles,
                          backgroundColor: 'orange',
                          color: 'white',
                          flexGrow: '1'
                        };
                      },
                      multiValue: (styles, { data }) => {
                        return {
                          ...styles,
                          width: '100%',
                          backgroundColor: 'orange',
                        };
                      },
                      multiValueRemove: (styles, { data }) => {
                        return {
                          ...styles,
                          backgroundColor: 'orange',
                          color: 'white',
                        };
                      },
                    }}
                  />
                  {selectedItems.length + newItems.length === 25 && <h6 className='text-primary d-flex flex-column'>
                    <div className='mb-2'>Current Top Products Size: {newItems.length}</div>
                    <div className='mb-2'>Selected Top Products Size: {selectedItems.length}</div>
                    <div>Total Top Products size should not be more than 25</div>
                  </h6>}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  disabled={selectedItems?.length === 0}
                  onClick={() => {
                    setSelectedItems([]);
                    setNewItems([...new Set([...newItems, ...items])]);
                  }}
                >
                  Add
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                  onClick={() => { setSelectedItems([]); setItems([]); }}
                >Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SortableList
        items={newItems}
        onSortEnd={onSortEnd}
        useWindowAsScrollContainer={true}
        setCheckedItems={setCheckedItemsOnCheck}
      />
    </>
  );

}

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchData = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_BASE_URL + "/products/get_products_dropdown",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.data.status === 1) {
          setProducts(response.data.data.map(option => ({ ...option, label: option.itemName, value: option.itemName })));
        } else {
          toast.warning(response.data.message);
        }
      })
      .catch(function (error) {
        return400Error(error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {
        <SortableComponent
          data={products}
          setLoader={setLoader}
        />
      }
      {loader ? <Loader /> : null}
    </>
  )
}

export default ProductList;