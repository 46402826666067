import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';

const DateRangePicker = ({ name, onChange, noValues }) => {
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [{ minStart, minEnd, maxStart, maxEnd }, setLimit] = useState({});

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        onChange(name, { start: e.target.value, end: endDate });
        setLimit({
            minEnd: e.target.value
        });
    };

    useEffect(() => {
        if (noValues) {
            setStartDate(undefined);
            setEndDate(undefined);
        }
    }, [noValues]);

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        onChange(name, { start: startDate, end: e.target.value });
    };

    return (
        <>
            <label>From</label>
            <input
                type='date'
                name={name + '-startDate'}
                className="form-control"
                placeholder='From'
                value={noValues ? '' : startDate}
                onChange={handleStartDateChange}
            />
            <hr />
            <label>To</label>
            <input
                type='date'
                name={name + '-endDate'}
                min={minEnd}
                max={maxEnd}
                className="form-control"
                placeholder='To'
                value={noValues ? '' : endDate}
                onChange={handleEndDateChange}
            />
        </>
    );
}

export default DateRangePicker;