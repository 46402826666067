import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Table from "../../Common/Table";
import ToggleCheck2 from "../../Common/ToggleCheck2";
import { return400Error } from "../../Common/Errors";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(true);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "image",
      text: "Category Image",
    },
    {
      dataField: "name",
      text: "Category name",
    },
    {
      dataField: "sequence",
      text: "Sequence",
      sort: true,
    },
    {
      dataField: "is_active",
      text: "Status",
    },
    {
      dataField: "featured",
      text: "Featured",
    },
  ];

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  useEffect(() => {
    fetchData();
  }, [pageNo, totalItemToBeFetched]);

  const fetchData = () => {
    setLoader(true);
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        "/medpacks_admin/get_all_categories?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
      headers: {
        Authorization: "Token " + sessionStorage.getItem("token"),
      },
    })
      .then(function (response) {
        setLoader(false);
        setTotalItems(response.data.total_count);
        if (response.data.status === 200) {
          setCategories([]);
          // customize data
          response.data.data.map((e, i) => {
            let updatedata = {
              id: e.id,
              name: e.name,
              image: <img src={e.image} alt="image" width={50} />,
              sequence: (
                <>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={e.sequence}
                    onChange={(event) => {
                      if (!isNaN(event.target.value)) {
                        axios
                          .put(
                            process.env.REACT_APP_BASE_URL +
                            "/medpacks_admin/update_category/" +
                            e.id,
                            {
                              sequence: Number(event.target.value),
                            },
                            {
                              headers: {
                                Authorization:
                                  "Token " + sessionStorage.getItem("token"),
                              },
                            }
                          )
                          .then((res) => {
                            toast.success(res.data.message);
                          })
                          .catch((err) => {
                            const { status, message } = err.response.data.data;
                            if (status === "400") {
                              sessionStorage.clear()
                              window.location = "/";
                              toast.error(message);
                            }
                            else {
                              return400Error(err);
                            }
                          });
                      } else {
                        toast.warning("Please enter a number");
                      }
                    }}
                  />
                </>
              ),
              is_active: (
                <ToggleCheck2
                  id={e.id}
                  value={e.is_active}
                  actionfor={"status"}
                  method={"PUT"}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  url="/medpacks_admin/update_category/"
                  token={"Token " + sessionStorage.getItem("token")}
                  onSuccess={fetchData}
                />
              ),
              featured: (
                <ToggleCheck2
                  id={e.id}
                  value={e.featured}
                  actionfor={"featured"}
                  method={"PUT"}
                  baseURL={process.env.REACT_APP_BASE_URL}
                  url="/medpacks_admin/update_category/"
                  token={"Token " + sessionStorage.getItem("token")}
                  onSuccess={fetchData}
                />
              ),
            };
            setCategories((oldArray) => [...oldArray, updatedata]);
          });
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        return400Error(error);
      });
  };

  return (
    <>
      <div className="card w-50 p-2">
        <small>
          <b>* Status=Y - </b>Category will be shown across the website
          <br />
          <b>* Status=N -</b> Category will not be shown across the website{" "}
          <br />
          <b>* Status=Y and Featured=Y - </b> Category will show under Popular
          Categories <br />
          <b>* Status=Y and Featured=N -</b> Category will not show under
          Popular Categories data
        </small>
      </div>
      <Table
        data={categories}
        // searchKey="name"
        columns={columns}
        maxRow={10}
        loader={loader}
        totalItems={totalItems}
        totalItemToBeFetched={totalItemToBeFetched}
        setTotalItemToBeFetched={setTotalItemToBeFetched}
        pageNo={pageNo}
        setPageNo={setPageNo}
      />
    </>
  );
};

export default Categories;
