import * as React from 'react';
import { useState } from 'react';

const apiUrl = process.env.REACT_APP_BASE_URL;

const Products = ({ data, productKey, setShowProductsList, setRow }) => {
  const [image, setImage] = useState({});
  return (
    <>
      <div className='card customer-Products'>
        <h5>{data.name}&nbsp;{`(${data?.product_list?.length})`}</h5>
        <hr />
        {
          data.product_list.length > 0
            ? <>
              <div className='row' style={{ margin: '10px 0' }}>
                <div className="col">Product Image</div>
                <div className="col-6">Name</div>
                <div className="col">Total QAR</div>
                <div className="col">Unit</div>
              </div>
              {data?.product_list?.map(({
                product_data: {
                  product_images,
                  itemName,
                  pricePerPcs,
                  pcsUnit
                },
              }, i) => (
                <div className='customer-product'>
                  <div className='row' style={{ margin: '10px 0' }}>
                    <div className="col">
                      <div className="wishlist-i-img">
                        <button
                          className='btn'
                          data-toggle="modal"
                          onClick={() => setImage({
                            image: product_images[0],
                            name: itemName
                          })}
                          data-target='#itemImage'
                        >
                          <img
                            src={
                              apiUrl + product_images[0]
                            }
                            height={50}
                            width={50}
                            alt={"prdct_img"}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-6"><p>{itemName}</p></div>
                    <div className="col"><div>{pricePerPcs}</div></div>
                    <div className="col"><div>{pcsUnit}</div></div>
                  </div>
                  <div class="modal" tabindex="-1" role="dialog" id='itemImage' data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">{image.name}</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <img
                            src={
                              apiUrl + image.image
                            }
                            height={300}
                            width={300}
                            alt={"prdct_img"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
            : <p style={{ fontSize: '0.7rem', padding: '10px' }}>No Products to display</p>
        }
      </div>
    </>
  )
}

export default Products;