import axios from 'axios';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { return400Error } from '../../../../Common/Errors';
import Loader from '../../../../Common/Loader';

const AddressPanel = ({
    ID,
    url
}) => {
    const [address, setAddress] = useState([]);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        axios({
            method: "POST",
            url: process.env.REACT_APP_BASE_URL + url,
            data: {
                customer_id: ID,
            },
            headers: {
                Authorization: "Token " + sessionStorage.getItem("token"),
            },
        })
            .then(function (response) {
                setLoader(false);
                if (response.status === 200) {
                    let data = [...response.data.data];
                    data.length > 0 && setAddress([
                        data.filter(x => x.is_default)[0],
                        ...data.filter(x => !x.is_default),
                    ]);
                } else if (response.data.status === 400) {
                    toast.error(response.data.message);
                }
            })
            .catch(function (error) {
                // setLoader(false);
                return400Error(error);
            });
    }, []);
    return (
        <>
            {loader ? <Loader /> : null}
            {address && address.length > 0 ?
                address.map(({
                    address,
                    name,
                    building_number,
                    street,
                    zone,
                    unit_number,
                    phone_number,
                    is_default,
                }) => (
                    <div className='card addresses-card border shadow-sm rounded'>
                        <div className='d-flex category'>
                            <div className='flex-grow-1'><h3 style={{ fontSize: '0.9rem', fontWeight: '500' }}>{name}</h3></div>
                        </div>
                        <div className='details'>
                            <div>Address: {address}</div>
                            <div>Building no: {building_number}</div>
                            <div>Street: {street}</div>
                            <div>Zone: {zone}</div>
                            <div>Unit no: {unit_number}</div>
                            <div>Mobile: {phone_number ? phone_number.toString().slice(0, 4) + ' ' + phone_number.toString().slice(4) : ''}</div>
                        </div>
                        <div className='text-primary' style={{ marginLeft: '88%' }}><h4 style={{ fontSize: '0.8rem', fontWeight: '500' }}>{is_default ? 'Default' : ''}</h4></div>
                    </div>
                ))
                : <p className='card text-center' style={{ padding: '30px' }}>No customer addresses to display</p>}
        </>
    );
}

export default AddressPanel