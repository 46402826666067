import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const ProductSequence = ({ sequence, onChange, id, onKeyDown }) => {
    const [value, setValue] = useState('');
    return (
        <input
            key={id}
            className="form-control"
            defaultValue={value || sequence}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e);
            }}
            // onKeyUpCapture={onKeyDown}
            id={id}
        />
    )
}

export default ProductSequence;