import { HOME, ROLES, USERS } from './constants';

export default (path) => {
    let pageView;
    switch (true) {
        case path.includes(USERS):
            {
                pageView = USERS;
                break;
            }
        case path.includes(ROLES):
            {
                pageView = ROLES;
                break;
            }
        default: {
            pageView = HOME;
        }
    }
    return pageView;
}