import * as React from 'react';

import upload_insu from "../../../../../../../src/assets/upload_insu.png";
import upload_pres from "../../../../../../../src/assets/upload_pres.png";

import moment from 'moment';
import axios from 'axios';
import { useEffect } from 'react';
import withRouter from '../../../../../HOCs/withRouter';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { return400Error } from '../../../../../Common/Errors';

// const apiUrl = "https://prod.be.consumer.medpack.helloapps.io";
const apiUrl = process.env.REACT_APP_BASE_URL;

const OrderItem = ({
  orders,
  setShowSummary,
  showHeader = true,
  router: {
    location: {
      state
    },
    navigate,
    // params: {
    //   ID
    // }
  }
}) => {

  const [data, setData] = useState([]);
  const ID = state?.order?.id || '';

  useEffect(() => {
    if (window.location.href.includes('details')) {
      axios({
        method: "GET",
        url: process.env.REACT_APP_BASE_URL + '/medpacks_admin/get_single_order/' + ID,
        headers: {
          Authorization: "Token " + sessionStorage.getItem("token"),
        },
      })
        .then(function (response) {
          // setLoader(false);
          if (response.status === 200) {
            setData(response.data.data);
          } else if (response.data.status === 400) {
            toast.error(response.data.message);
          }
        })
        .catch(function (error) {
          // setLoader(false);
          return400Error(error);
        });
    }
  }, []);
  const orderData = data?.details?.order_id ? [data] : orders;

  return (
    <>
      {window.location.href.includes('details') && <div className="card">
        <button
          className="btn btn-outline-primary back-button"
          style={{
            width: '10%',
            margin: '5px 5px 5px 88%'
          }}
          onClick={() => {
            setShowSummary(false);
            navigate(-1);
          }}>
          Back
        </button>
      </div>}
      {orderData && orderData.map(({
        status,
        details: {
          order_placed_date,
          total_amount,
          ship_to,
          order_id,
          products,
        } = {},
        address_data: {
          address
        } = {},
        insurance: {
          file: insuranceFile,
          expiry_date,
          insurance_type,
          premium_no,
          sub_insurance
        } = {},
        prescription,
        prescription: {
          name,
          remarks,
          file: prescriptionFile,
        } = {}
      }) =>
        <>
          <div className='card orderItem'>
            <div className='order-status'><h5>{status}</h5></div>
            {showHeader && <table className='order-header'>
              <tr>
                <th>ORDER PLACED</th>
                <th>TOTAL QAR</th>
                <th>SHIP TO</th>
                <th>ORDER NO</th>
              </tr>
              <tr>
                <td>{moment(order_placed_date).format('MMMM Do YYYY')}</td>
                <td>{total_amount}</td>
                <td>
                  <button className='btn' title={address}>
                    {ship_to} <i className='mdi mdi mdi-menu-down text-dark' />
                    {/* <button type="button" class="btn p-0 address-button" data-toggle="tooltip" data-placement="bottom" title={address}> */}
                    {/* <h4><i className='mdi mdi mdi-menu-down text-dark' /></h4> */}
                    {/* </button> */}
                  </button>
                </td>
                <td>{order_id}</td>
              </tr>
            </table>}

            {prescription?.name && <div className='order-prescription'>
              <div className='order-prescription-title'>
                <img src={upload_pres} alt="upload_pres" width={25} />
                PRESCRIPTION
              </div>
              <table className='order-prescription-items'>
                <tr>
                  <th colSpan='2'>NAME</th>
                  <th >Remarks</th>
                  <th></th>
                </tr>
                <tr>
                  <td colSpan='2'>{name}</td>
                  <td >{remarks}</td>
                  <td><button
                    className='btn'
                    onClick={() => {
                      let alink = document.createElement("a");
                      alink.href = apiUrl + prescriptionFile;
                      alink.target = "_blank";
                      alink.click();
                    }}
                    disabled={!prescriptionFile}
                  >Preview</button></td>
                </tr>
              </table>
            </div>}
            {prescription?.name && < div className='order-insurance'>
              <div className='order-insurance-title'>
                <img src={upload_insu} alt="upload_pres" width={25} />
                INSURANCE
              </div>
              <table className='order-insurance-items'>
                <tr>
                  <th>INSURANCE TYPE</th>
                  <th>SUB INSURANCE</th>
                  <th>PREMIUM NO.</th>
                  <th>EXPIRY DATE</th>
                  <th></th>
                </tr>
                <tr>
                  <td>{insurance_type?.toLowerCase()}</td>
                  <td>{sub_insurance?.toLowerCase()}</td>
                  <td>{premium_no?.toLowerCase()}</td>
                  <td>{expiry_date?.toLowerCase()}</td>
                  <td>
                    <button
                      className='btn'
                      onClick={() => {
                        let alink = document.createElement("a");
                        alink.href = apiUrl + insuranceFile;
                        alink.target = "_blank";
                        alink.click();
                      }}
                      disabled={!insuranceFile}
                    >
                      Preview
                    </button>
                  </td>
                </tr>
              </table>
            </div>}
          </div>
          <div className='card orderItem'>
            <div className='order-status'><h5>Products</h5></div>
            <div>
              {products?.length > 0 && <div className='row' style={{ margin: '10px 0' }}>
                <div className="col"></div>
                <div className="col-6">PRODUCT NAME</div>
                <div className="col">QTY</div>
                <div className="col">Total QAR</div>
              </div>}
              {products.length > 0
                ? products.map(product =>
                  <>
                    <div className='row order-products' style={{ margin: '10px 0' }}>
                      <div className="col">
                        <div className="wishlist-i-img">
                          <img
                            src={
                              apiUrl + product.image
                            }
                            height={50}
                            width={50}
                            alt={"prdct_img"}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <p>
                          {status === "Cancel Request Accepted" ? (
                            <>
                              <span
                                style={{
                                  position: "relative",
                                }}
                              >
                                {product.itemName}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "7px",
                                    left: 0,
                                    background: "#F5832B",
                                    width: "100%",
                                    height: "2px",
                                  }}
                                />
                              </span>
                            </>
                          ) : product.itemName}
                        </p>
                      </div>
                      <div className="col"><div>{product.quantity}</div></div>
                      <div className="col"><div>{product.itemAmountAfterOffer}</div></div>
                    </div>
                  </>)
                : <p style={{ fontSize: '0.7rem', padding: '10px' }}>No Products to display</p>
              }
            </div>
          </div>
        </>
      )
      }
    </>
  );
}

export default withRouter(OrderItem);