import Select from "react-select";
import { useState } from "react";

const CustomersFilter = () => {
  const [selectedOption, setSelectedOption] = useState();

  const options = [
    { value: "1", label: "option 1" },
    { value: "2", label: "option 2" },
    { value: "3", label: "option 3" },
  ];

  const customStyles = {
    // control: (styles,state) => ({
    //     ...styles,
    //     backgroundColor: "white",
    //  }),
    option: (styles, state) => {
      console.log(state);
      return {
        ...styles,
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "#F58329" : "#fff",
        // color: state.isFocused ? "white" : "black",
        // backgroundColor: state.isFocused ? "#f7a86c" : "#fff",
      };
    },
  };
  return (
    <>
      <Select
        className="mt-2"
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        styles={customStyles}
        placeholder="Single"
      />

      <Select
        className="mt-2"
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        styles={customStyles}
        placeholder="Multiple"
        isMulti="true"
      />

      <button className="btn btn-primary btn-block mt-3" id="resetBtn">
        <i className="uil-filter"></i> Apply Filter
      </button>
    </>
  );
};
export default CustomersFilter;
