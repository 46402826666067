import { Link, Navigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useState } from "react";
import { useEffect } from "react";
import getOptionsByPath from "./getCurrentPageViewByPath";
import { HOME, ROLES, USERS } from "./constants";
import { useContext } from "react";
import { FiltersContext } from "./FiltersProvider";

export const SideBarMenus = [
  // {
  //   name: "Dashboard",
  //   icon: "uil-dashboard",
  //   url: "/",
  // },
  {
    name: "Customers",
    icon: "uil-users-alt",
    url: "/customers",
    role: "customers_access",
  },
  {
    name: "Orders",
    icon: "uil-cart",
    url: "/orders",
    role: "customers_access",
  },
  {
    name: "Phone Code",
    icon: "uil-phone-alt",
    url: "/country-code",
    role: "consumer_app_settings_access",
  },
  {
    name: "Products",
    icon: "uil-cart",
    url: "/top-products",
    role: "consumer_app_settings_access",
  },
  {
    name: "Top Products",
    icon: "uil-cart",
    url: "/products-sequence",
    role: "consumer_app_settings_access",
  },
  {
    name: "Categories",
    icon: "uil-window-section",
    url: "/categories",
    role: "consumer_app_settings_access",
  },
  {
    name: "Offers",
    icon: "uil-gift",
    url: "/offers",
  },
  {
    name: "Coupons",
    icon: "uil-ticket",
    url: "/coupons",
  },
  {
    name: "Loyalty Points",
    icon: "uil-coins",
    url: "/loyalty-points",
  },
  {
    name: "Loyalty Point Setting",
    icon: "uil-coins",
    url: "/loyalty-point-setting",
  },
  {
    name: "Top Menu",
    icon: "uil-chart-bar-alt",
    url: "/top-menu ",
    role: "consumer_app_settings_access",
  },
  {
    name: "SignIn Image",
    icon: "uil-image",
    url: "/images-control",
    role: "consumer_app_settings_access",
  },
  {
    name: "Carousel",
    icon: "uil-image",
    url: "/homepage-carousel",
  },
  // {
  //   name: "Static Pages",
  //   icon: "uil-document-layout-left",
  //   url: "/static-pages",
  // },
  {
    name: "Create Static Pages",
    icon: "uil-document-layout-left",
    url: "/all-static-pages",
  },
  {
    name: "Social Media Link",
    icon: "uil-instagram",
    url: "/social-media-link",
    role: "consumer_app_settings_access",
  },
  {
    name: "FAQs",
    icon: "uil-book-medical",
    url: "/faq",
    role: "consumer_app_settings_access",
  },
  {
    name: "Document Types",
    icon: "uil-book",
    url: "/document-types",
    role: "clinic_access",
  },
];

export const SettingsMenuBar = [
  {
    name: "Users",
    icon: "uil-users-alt",
    url: "/users",
    role: "admin_access",
  },
  {
    name: "Roles",
    icon: "uil-user-check",
    url: "/roles",
    role: "admin_access",
  },
];

export const DoctorPanelMenu = [
  {
    name: "Add Clinic",
    icon: "uil-book-medical",
    url: "/add-clinic",
    role: "clinic_access",
  },
  // {
  //   name: "Blood Groups",
  //   icon: "uil-medical-drip",
  //   url: "/blood-group",
  // },
  {
    name: "Medicine Strength",
    icon: "uil-monitor-heart-rate",
    url: "/medicine-strength",
    role: "clinic_access",
  },
  {
    name: "Dosage Unit",
    icon: "uil-syringe",
    url: "/dosage-unit",
    role: "clinic_access",
  },
  {
    name: "Medicine Route",
    icon: "uil-capsule",
    url: "/medicine-route",
    role: "clinic_access",
  },
  {
    name: "Medicine Duration",
    icon: "uil-clock-seven",
    url: "/medicine-duration",
    role: "clinic_access",
  },
  {
    name: "Medicine Direction",
    icon: "uil-chart-bar-alt",
    url: "/medicine-direction",
    role: "clinic_access",
  },
  {
    name: "Complaints",
    icon: "uil-medical-square",
    url: "/complaints",
    role: "clinic_access",
  },
  {
    name: "Diagnosis",
    icon: "uil-file-medical-alt",
    url: "/diagnosis",
    role: "clinic_access",
  },
  {
    name: "Tests",
    icon: "uil-heartbeat",
    url: "/test",
    role: "clinic_access",
  },
];

const SideMenu = () => {
  const { dispatch } = useContext(FiltersContext);
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname);
  const [pageView, setPageView] = useState("general");
  const [user, setUserData] = useState({});

  useEffect(() => {
    setPageView(getOptionsByPath(location.pathname));
    if (location.pathname === "/" + USERS) {
      setActiveMenu("/users");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUserData(JSON.parse(atob(sessionStorage.getItem("user"))));
    }
  }, []);

  return (
    <div className="left-side-menu">
      <Link to="/" className="logo text-center logo-light">
        <span className="logo-lg">
          <img src={logo} alt="logo" height="40" />
        </span>
        <span className="logo-sm">
          <img src={logo} alt="logo" height="15" />
        </span>
      </Link>

      <Link to="/" className="logo text-center logo-dark">
        <span className="logo-lg">
          <img src={logo} alt="logo" height="40" />
        </span>
        <span className="logo-sm">
          <img src={logo} alt="logo" height="15" />
        </span>
      </Link>

      <div className="h-100" id="left-side-menu-container" data-simplebar>
        <ul className="metismenu side-nav">
          {/* <li className="side-nav-title side-nav-item">Consumer Panel</li>
          <li className="side-nav-item">
            {SideBarMenus.map((e, i) => {
              return (
                <Link
                  to={e.url}
                  className={`side-nav-link ${
                    activeMenu === e.url ? `active` : null
                  }`}
                  key={i}
                  onClick={() => setActiveMenu(e.url)}
                >
                  <i className={e.icon}></i>
                  <span> {e.name} </span>
                </Link>
              );
            })}
          </li> */}

          {(user?.permissions?.customers_access ||
            user?.permissions?.consumer_app_settings_access) && (
              <div class="accordion" id="accordionExample1">
                <div class="card mb-0">
                  <div class="card-header" id="headingOne">
                    <h5 class="m-0">
                      <a
                        class="custom-accordion-title d-flex justify-content-between text-light d-block pt-2 pb-2"
                        data-toggle="collapse"
                        href="#Consumer"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Consumer Panel
                        <i className="uil-angle-down"></i>
                      </a>
                    </h5>
                  </div>

                  <div
                    id="Consumer"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample1"
                  >
                    <div class="card-body p-0 m-0 mt-2">
                      <ul className="metismenu side-nav">
                        <li className="side-nav-item">
                          {SideBarMenus.map((e, i) => {
                            return (Object.keys(user?.permissions).includes(
                              e.role
                            ) &&
                              user?.permissions[e.role]) ||
                              !e.role ? (
                              <Link
                                to={e.url}
                                className={`side-nav-link ${activeMenu === e.url ? `active` : null
                                  }`}
                                key={i}
                                onClick={() => {
                                  dispatch({ type: 'reset-filters' });
                                  setActiveMenu(e.url);
                                  localStorage.setItem(
                                    "MENU",
                                    e.name.toUpperCase()
                                  );
                                }}
                              >
                                <i className={e?.icon}></i>
                                <span> {e.name} </span>
                              </Link>
                            ) : (
                              <></>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/* menus for doctor panel */}
          {user?.permissions?.clinic_access && (
            <div class="accordion" id="accordionExample2">
              <div class="card mb-0">
                <div class="card-header" id="headingOne">
                  <h5 class="m-0">
                    <a
                      class="custom-accordion-title d-flex justify-content-between text-light d-block pt-2 pb-2"
                      data-toggle="collapse"
                      href="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Clinic Panel
                      <i className="uil-angle-down"></i>
                    </a>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample2"
                >
                  <div class="card-body p-0 m-0 mt-2">
                    <ul className="metismenu side-nav">
                      <li className="side-nav-item">
                        {DoctorPanelMenu.map((e, i) => {
                          return (
                            <Link
                              to={e.url}
                              className={`side-nav-link ${activeMenu === e.url ? `active` : null
                                }`}
                              key={i}
                              onClick={() => { dispatch({ type: 'reset-filters' }); setActiveMenu(e.url); }}
                            >
                              <i className={e?.icon}></i>
                              <span> {e.name} </span>
                            </Link>
                          );
                        })}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {user?.permissions?.admin_access && (
            <>
              <div class="accordion" id="accordionExample">
                <div class="card mb-0">
                  <div class="card-header" id="headingOne">
                    <h5 class="m-0">
                      <a
                        className="custom-accordion-title d-flex justify-content-between text-light d-block pt-2 pb-2"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOneSettings"
                        aria-expanded="true"
                        aria-controls="collapseOneSettings"
                      >
                        User Settings
                        <i className="uil-angle-down"></i>
                      </a>
                    </h5>
                  </div>

                  <div
                    id="collapseOneSettings"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="card-body p-0 m-0 mt-2">
                      <ul className="metismenu side-nav">
                        <li className="side-nav-item">
                          {SettingsMenuBar.map((e, i) => {
                            return (
                              <Link
                                to={e.url}
                                className={`side-nav-link ${activeMenu === e.url ? `active` : null
                                  }`}
                                key={i}
                                onClick={() => { dispatch({ type: 'reset-filters' }); setActiveMenu(e.url); }}
                              >
                                <i className={e?.icon}></i>
                                <span> {e.name} </span>
                              </Link>
                            );
                          })}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
