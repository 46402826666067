import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";

const MyAccount = () => {
  const [userdata, setuserdata] = useState({});
  const [saveBtnState, setSaveBtnState] = useState("Save");
  const [nationalId, setNationalId] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [validIdProof, setValidIdProof] = useState(true);
  const [idmsg, setidmsg] = useState("");
  const [salutation, setSalutation] = useState(null);
  const [countryName, setCountryName] = useState("");
  const countryoptions = useMemo(() => countryList().getData(), []);
  const [DOB, setDOB] = useState(null);
  const [savebtnstate, setSavebtnstate] = useState(false);
  const [gender, setGender] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setuserdata(JSON.parse(atob(sessionStorage.getItem("user"))));
    }
  }, []);

  useEffect(() => {
    if (gender === "male") {
      setSalutation("Mr");
    } else if (gender === "female") {
      setSalutation("Miss");
    }
  }, [gender]);

  useEffect(() => {
    if (salutation === "Mr") {
      setGender("male");
    } else if (salutation === "Miss") {
      setGender("female");
    }
  }, [salutation]);

  useEffect(() => {
    setDOB(userdata?.dob);
    setCountryName(userdata?.country);
    setNationalId(userdata?.national_id);
    setPassportNo(userdata?.passport_no);
    setSalutation(userdata?.salutation);
    setGender(userdata?.gender);
  }, [userdata]);

  useEffect(() => {
    if (nationalId !== "") {
      if (!nationalId?.match(/(2|3)\d{10}/)) {
        setValidIdProof(false);
        setidmsg("Please enter a valid national id");
      } else {
        setValidIdProof(true);
        setidmsg("");
      }
    } else {
      setValidIdProof(true);
      setidmsg("");
    }
  }, [nationalId]);

  const onsubmitUpdateuser = async (e) => {
    e.preventDefault();
    if (validIdProof) {
      const form = e.target;
      const formData = new FormData(form);
      const data = {
        phone_number: userdata.phone_number,
        salutation: formData.get("salutation"),
        name: formData.get("name"),
        gender: formData.get("gender"),
        dob: formData.get("dob"),
        country: formData.get("country"),
        email: formData.get("email"),
        national_id: formData.get("national_id"),
        passport_no: formData.get("passport_no"),
      };

      // if (countryName === "Qatar") {
      //   data["national_id"] = formData.get("national_id");
      // } else {
      //   data["passport_no"] = formData.get("passport_no");
      // }

      if (
        data.email &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)
      ) {
        if (passportNo || nationalId) {
          setSaveBtnState("Saving...");
          try {
            const result = await axios.put(
              process.env.REACT_APP_BASE_URL + "/user/updatecustomerDetails",
              data,
              {
                headers: {
                  Authorization: "Token " + sessionStorage.getItem("token"),
                },
              }
            );

            if (result.data.status === 200) {
              toast.success(result.data.message);
              setSaveBtnState("Saved");
            }
          } catch (err) {
            toast.error(err.toString());
          }
        } else {
          toast.warning("Passport no or national id required");
        }
      } else {
        toast.warning("Please enter a valid email");
      }
    }
  };

  return (
    <div className="my-account-user-details px-md-0 px-3">
      <form autoComplete="off" onSubmit={onsubmitUpdateuser}>
        <div className="input-group-cust">
          <label htmlFor="name">
            Name<sup className="text-danger">*</sup>
          </label>
          <div className="w-100 d-flex">
            <select
              className="form-select w-25"
              name="salutation"
              value={salutation}
              onChange={(e) => setSalutation(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Dr">Dr</option>
            </select>
            <input
              type="text"
              name="name"
              className="form-control w-75"
              id="name"
              defaultValue={userdata?.name}
              required
            />
          </div>
        </div>
        <div className="input-group-cust">
          <label htmlFor="email">
            Email ID<sup className="text-danger">*</sup>
          </label>
          <input
            type="text"
            name="email"
            className="form-control"
            id="email"
            defaultValue={userdata?.email}
            required
          />
        </div>
        <div className="input-group-cust">
          <label htmlFor="phone">Phone No</label>
          <div className="w-100 d-flex">
            <input
              type="text"
              name="country_code"
              className="form-control w-25 rounded-0 text-center"
              id="country_code"
              disabled
              defaultValue={userdata?.country_code}
            />
            <input
              type="number"
              name="phone"
              className="form-control w-75 rounded-0"
              id="phone"
              disabled
              defaultValue={userdata?.phone_number}
            />
          </div>
        </div>
        {/* <div className="input-group-cust">
              <ReactCountryDropdown onSelect={handleSelect} countryCode="IN" />
            </div> */}
        <div className="input-group-cust">
          <label htmlFor="gender">Gender</label>
          <select
            name="gender"
            id="gender"
            className="form-select w-100"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="input-group-cust">
          <label htmlFor="dob">
            DOB<sup className="text-danger">*</sup>
          </label>
          <input
            type="date"
            name="dob"
            placeholder="dd-mm-yyyy"
            className="form-control"
            id="dob"
            defaultValue={DOB}
            onChange={(e) => {
              if (new Date(e.target.value).getTime() < new Date().getTime()) {
                setDOB(e.target.value);
                setSavebtnstate(false);
              } else {
                setSavebtnstate(true);
                toast.warning("Please select a past date");
              }
            }}
            required
          />
        </div>
        <div className="input-group-cust">
          <label htmlFor="gender">Select Nationality</label>
          {/* <Select
                options={countryoptions}
                defaultValue={countryName}
                styles={customStyles}
                onChange={(e) => {
                  setCountryName(e.label);
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#f7a86c",
                    primary: "#F58329",
                  },
                })}
              /> */}
          <select
            name="country"
            id="nationality"
            className="form-select w-100"
            value={countryName}
            onChange={(e) => setCountryName(e.target.value)}
          >
            <option value="">Select</option>
            {countryoptions.map((e, i) => {
              return (
                <option key={i} value={e.label}>
                  {e.label}
                </option>
              );
            })}
          </select>
        </div>

        {/* {countryName === "Qatar" || countryName === "" ? ( */}
        <div className="input-group-cust">
          <label htmlFor="national_id">
            National Id<sup className="text-danger">*</sup>
          </label>
          <input
            type="text"
            name="national_id"
            className="form-control"
            id="national_id"
            value={nationalId}
            onChange={(e) => setNationalId(e.target.value)}
          />
          <small className="text-danger">{idmsg}</small>
        </div>
        {/* ) : ( */}
        <div className="input-group-cust">
          <label htmlFor="passport_no">
            Passport No.<sup className="text-danger">*</sup>
          </label>
          <input
            type="text"
            name="passport_no"
            className="form-control"
            id="passport_no"
            value={passportNo}
            onChange={(e) => setPassportNo(e.target.value)}
          // defaultValue={userdata?.passport_no}
          />
        </div>
        {/* )} */}

        <div className="input-group-cust-btn">
          <button
            type="submit"
            className="btn border-dark"
            disabled={savebtnstate}
          >
            {saveBtnState}
          </button>
        </div>

        {/* <div className="input-group-cust-btn-mob">
              <center>
                <button type="submit" className="btn border-dark">
                  Save
                </button>
              </center>
            </div> */}
      </form>
    </div>
  );
};
export default MyAccount;
