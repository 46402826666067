import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
import DateFormater from "../../Common/DateFormater";
import ToggleCheck from "../../Common/ToggleCheck";
import Table from "../../Common/Table";
import CountryCodes from "../../Common/CountryCodes.json";
import Input from "../../Common/Input";
import moment from "moment";

import EditData from "../../Common/EditData";

const AddClinic = () => {
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [actionbtnsts, setactionbtnsts] = useState("add");
  const [ActionTitle, setActionTitle] = useState("ADD CLINIC");
  const [actionbtntxt, setactionbtntxt] = useState("ADD");
  const [allFormData, setAllFormData] = useState({
    name: "",
    clinic_name: '',
    phone_number: null,
  });

  const [selectedItem, setSelectedItem] = useState({});

  const [phoneCode, setPhoneCode] = useState("+974");
  const [phone_number, setPhoneNumber] = useState(null);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      headerStyle: () => {
        return { width: "40px" };
      },
    },
    {
      dataField: "name",
      text: "Name",
      headerStyle: () => {
        return { width: "110px" };
      },
    },
    {
      dataField: "total_users",
      text: "Total User",
      sort: true,
      headerStyle: () => {
        return { width: "60px" };
      },
    },
    {
      dataField: "total_patients",
      text: "Total Patients",
      sort: true,
      headerStyle: () => {
        return { width: "80px" };
      },
    },
    {
      dataField: "phone",
      text: "Phone",
    },
    {
      dataField: "is_active",
      text: "Status",
      headerStyle: () => {
        return { width: "60px" };
      },
    },
    {
      dataField: "edit",
      text: "",
      headerStyle: () => {
        return { width: "60px" };
      },
    },
    // {
    //   dataField: "edit",
    //   text: "Edit",
    // },
  ];

  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [totalItemToBeFetched, setTotalItemToBeFetched] = useState(10);

  useEffect(() => {
    fetchAllData();
  }, [pageNo, totalItemToBeFetched]);

  const fetchAllData = () => {
    setLoader(true);
    axios
      .get(
        process.env.REACT_APP_DOCTOR_URL +
        "/users/get_clinic_list?page_no=" +
        pageNo +
        "&page_count=" +
        totalItemToBeFetched,
        {
          headers: {
            Authorization: process.env.REACT_APP_DOCTOR_TOKEN,
          },
        }
      )
      .then((res) => {
        setAllData([]);
        setLoader(false);
        setTotalItems(res.data.total_count);
        res.data.data.map((e, i) => {
          let updatedata = {
            id: e.id,
            clinic_name: e.clinic_name,
            name: e.name,
            total_users: e.total_users,
            total_patients: e.total_patients,
            // modified_on: (
            //   <span style={{ textAlign: "left" }}>
            //     <DateFormater time={e.modified_on} />
            //   </span>
            // ),
            phone: e.country_code + " " + e.phone_number,
            is_active: (
              <ToggleCheck
                value={e.is_active}
                countryCode={e.country_code}
                baseURL={process.env.REACT_APP_DOCTOR_URL}
                token={process.env.REACT_APP_DOCTOR_TOKEN}
                id={e.id}
                url="/master/delete_clinic_list/"
                method={"DELETE"}
              />
            ),
            edit: (
              <span>
                <button
                  className="btn"
                  onClick={() => {
                    window.scroll(0, 0);
                    setSelectedItem(e);
                    setActionTitle("Edit Clinic");
                    setactionbtntxt("UPDATE");
                    setactionbtnsts("edit");
                  }}
                >
                  <i className="uil-edit-alt" style={{ fontSize: "22px" }}></i>
                </button>
              </span>
            ),
          };
          setAllData((oldArray) => [...oldArray, updatedata]);
        });
      })
      .catch((err) => {
        const { status, message } = err.response.data.data;
        if (status === "400") {
          sessionStorage.clear()
          window.location = "/";
          toast.error(message);
        }
        else {
          toast.error(message);
        }
      });
  };

  useEffect(() => {
    if (selectedItem?.id) {
      setAllFormData({
        name: selectedItem.name || "",
        clinic_name: selectedItem.name || "",
        // phone_number: selectedItem.primary_contact_no
        //   ? selectedItem.primary_contact_no.slice(0, 4) +
        //     " " +
        //     selectedItem.primary_contact_no.slice(4)
        //   : "",
        phone_number: selectedItem?.phone_number && `${selectedItem?.phone_number.slice(0, 4)}${" "}${selectedItem?.phone_number.slice(4, 8)}`,
        country_code: selectedItem.country_code || "Qatar",
      });
      setPhoneNumber(selectedItem?.phone_number);
    }
  }, [selectedItem]);

  const handleOnChange = (e, isPhoneNumber) => {
    const { name, value } = e.target;
    let newValue = value;
    if (isPhoneNumber) {
      newValue = newValue.replaceAll(" ", "");
    }
    newValue = isPhoneNumber && newValue?.length > 4
      ? `${newValue.slice(0, 4)}${" "}${newValue.slice(4, 8)}`
      : newValue;
    setAllFormData({ ...allFormData, [name]: newValue });
    isPhoneNumber && setPhoneNumber(newValue)
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    allFormData["country_code"] = phoneCode.replace("+", "");
    allFormData["created_on"] = moment();
    allFormData["clinic_name"] = allFormData['name'];
    allFormData["phone_number"] = phone_number?.replace(' ', '');

    if (phone_number?.replace(' ', '').length === 8) {
      if (actionbtnsts === "add") {
        axios
          .post(
            process.env.REACT_APP_DOCTOR_URL + "/users/add_clinic",
            allFormData,
            {
              headers: {
                Authorization: process.env.REACT_APP_DOCTOR_TOKEN,
              },
            }
          )
          .then((res) => {
            fetchAllData();
            if (res.data.status === 400) {
              toast.warning(res.data.message);
            } else {
              toast.success(res.data.message);
              setPhoneNumber(null);
              setAllFormData({
                name: "",
                phone_number: "",
              });
              setSelectedItem({});
            }
          })
          .catch((err) => {
            const { status, message } = err.response.data || err.response.data?.data;
            if (status === "400") {
              sessionStorage.clear()
              window.location = "/";
              toast.error(message);
            }
            else {
              toast.error(message);
            }
          });
      } else {
        allFormData["clinic_id"] = selectedItem.id;
        axios
          .post(
            process.env.REACT_APP_DOCTOR_URL + "/users/update_clinic_data",
            allFormData,
            {
              headers: {
                Authorization: process.env.REACT_APP_DOCTOR_TOKEN,
              },
            }
          )
          .then((res) => {
            fetchAllData();
            if (res.data.status === 400) {
              toast.warning(res.data.message);
            } else {
              toast.success(res.data.message);
              setAllFormData(
                {
                  name: "",
                  name: "",
                  phone_number: "",
                }
              );
              setPhoneNumber(null);
              setSelectedItem({});
            }
          })
          .catch((err) => {
            const { status, message } = err.response.data.data;
            if (status === "400") {
              sessionStorage.clear()
              window.location = "/";
              toast.error(message);
            }
            else {
              toast.error(message);
            }
          });
      }
    } else {
      toast.warning("Please enter a valid number");
    }
  };

  return (
    <div className="row px-2">
      <div className="col-8">
        <Table
          data={allData}
          searchKey="name"
          columns={columns}
          maxRow={10}
          loader={loader}
          totalItems={totalItems}
          totalItemToBeFetched={totalItemToBeFetched}
          setTotalItemToBeFetched={setTotalItemToBeFetched}
          pageNo={pageNo}
          setPageNo={setPageNo}
        />
      </div>

      <div className="col-4">
        <div className="card border-primary border">
          <div className="card-body">
            <h5 className="text-center mb-2 pb-1 text-uppercase">
              {ActionTitle}
            </h5>
            <form onSubmit={handleSubmit}>
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Clinic name"
                required
                value={allFormData.name}
                onChange={handleOnChange}
              />
              < select
                name="country_code"
                className="form-control mt-2"
                // defaultValue={
                //   allFormData?.country_code ? allFormData?.country_code : "+974"
                // }
                value={phoneCode}
                onChange={(e) => setPhoneCode(e.target.value)}
              >
                {/* <option value="">Select country code</option> */}
                {CountryCodes.map((e, i) => {
                  return (
                    <option value={e.dial_code}>
                      {e.dial_code} ({e.name})
                    </option>
                  );
                })}
              </select>
              <input
                className="form-control mt-2"
                type="text"
                name="phone_number"
                placeholder="Admin Phone"
                required
                value={allFormData.phone_number}
                onChange={(e) => handleOnChange(e, true)}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" || (event.ctrlKey && event.key === "a") || event.key === "Delete") {
                    return;
                  }
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              // isValid={
              //   allFormData?.phone_number?.length > 0
              //     ? allFormData?.phone_number?.length <= 8
              //     : true
              // }
              // error={"length of phone number must not be greater than 8"}
              />
              <button
                type="submit"
                className="btn btn-primary px-3 py-1 mt-3 w-100"
              >
                {actionbtntxt}
              </button>
            </form>
            {actionbtnsts === "add" ? null : (
              <button
                className="btn btn-primary px-3 py-1 mt-3 w-100"
                onClick={() => {
                  setActionTitle("ADD");
                  setactionbtntxt("ADD");
                  setactionbtnsts("add");
                  setAllFormData({
                    name: "",
                    clinic_name: "",
                    phone_number: "",
                  });
                  setSelectedItem({});
                }}
              >
                {"Cancel"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};
export default AddClinic;
