import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// users img
import avatar from "../../assets/users/avatar-1.jpg";

const TopBar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUser(JSON.parse(atob(sessionStorage.getItem("user"))));
    }
  }, []);

  return (
    <div className="navbar-custom">
      <ul className="list-unstyled topbar-right-menu float-right mb-0">
        <li className="dropdown notification-list d-lg-none">
          <a
            className="nav-link dropdown-toggle arrow-none"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <i className="dripicons-search noti-icon"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
            <form className="p-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search ..."
                aria-label="Recipient's username"
              />
            </form>
          </div>
        </li>

        <li className="dropdown notification-list">
          <a
            className="nav-link dropdown-toggle nav-user arrow-none mr-0"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            {/* <span className="account-user-avatar">
              <img src={avatar} alt="user-image" className="rounded-circle" />
            </span> */}
            <span>
              {/* name */}
              <span className="account-user-name">{user?.name}</span>
              {/* position */}
              {/* <span className="account-position">Developer</span> */}
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
            <div className=" dropdown-header noti-title">
              <h6 className="text-overflow m-0">Welcome !</h6>
            </div>

            <span
              className="dropdown-item notify-item"
              onClick={() => {
                navigate("/");
              }}
            >
              <i className="mdi mdi-account-circle mr-1"></i>
              <span>My Account</span>
            </span>

            {/* {user?.permissions?.admin_access && <span
              className="dropdown-item notify-item"
              onClick={() => {
                navigate("/users");
              }}
            >
              <i className="mdi mdi-cog mr-1"></i>
              <span>Settings</span>
            </span>} */}

            {/* <span className="dropdown-item notify-item">
                        <i className="mdi mdi-account-edit mr-1"></i>
                        <span>Settings</span>
                    </span> */}

            {/* <span className="dropdown-item notify-item">
                        <i className="mdi mdi-lifebuoy mr-1"></i>
                        <span>Support</span>
                    </span> */}

            {/* <span className="dropdown-item notify-item">
                        <i className="mdi mdi-lock-outline mr-1"></i>
                        <span>Lock Screen</span>
                    </span> */}

            <span
              className="dropdown-item notify-item"
              onClick={() => {
                sessionStorage.removeItem("token");
                navigate("/auth");
              }}
            >
              <i className="mdi mdi-logout mr-1"></i>
              <span>Logout</span>
            </span>
          </div>
        </li>
      </ul>
      <button className="button-menu-mobile open-left disable-btn">
        <i className="mdi mdi-menu"></i>
      </button>
      {/* <div className="app-search dropdown d-none d-lg-block">
        <form>
          <div className="input-group">
            <input
              type="text"
              className="form-control dropdown-toggle"
              placeholder="Search..."
              id="top-search"
            />
            <span className="mdi mdi-magnify search-icon"></span>
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
};

export default TopBar;
