import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import ReactSelect from 'react-select';
import DateRangePicker from './DateRangePicker';
import { FILTER_OPTIONS, FILTER_OPTIONS_BOOL, KEY_VALUE_MAP } from './FilterOptionConstants';
import moment from 'moment';
import { ViewColumnSharp } from '@mui/icons-material';
import axios from 'axios';
import { FiltersContext } from './FiltersProvider';

const MultiSelect = ({
  filtersConfig,
  data,
  setData,
  currentUrl,
  searchTerm,
  onFilter: onFilterApply,
  enableFESearchFilter
}) => {

  const { state: { filters }, dispatch } = React.useContext(FiltersContext);
  const [fields, setFields] = useState({});
  const [applyFilter, setApplyFilters] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [error, setError] = useState([]);

  const isBoolean = x => typeof x === 'boolean';

  useEffect(() => {
    setClearFilter(false);
  }, []);

  const getValue = (data, key) => {
    const key1 = key.split('.')[0];
    const key2 = key.split('.')[1];
    return data[key1] && data[key1][key2];
  }

  useEffect(() => {
    if (enableFESearchFilter) {
      if (!fields) {
        setData(data);
      } else if (applyFilter) {
        setData(
          data
            .filter(x =>
              Object.entries(fields)
                .every(entry => {
                  if (typeof entry[1] === 'string') {
                    if (entry[0].split('.').length === 2) {
                      if (typeof getValue(x, entry[0]) === 'number') {
                        return entry[1] && getValue(x, entry[0]) && getValue(x, entry[0]) === Number(entry[1])
                      }
                      return entry[1] && getValue(x, entry[0]) && String(getValue(x, entry[0])).toLowerCase()
                        .match(entry[1].toLowerCase())
                    }
                    if (typeof x[entry[0]] === 'number') {
                      return entry[1] && x[entry[0]] && x[entry[0]] === Number(entry[1])
                    }
                    return entry[1] && x[entry[0]] && String(x[entry[0]]).toLowerCase()
                      .match(entry[1].toLowerCase());
                  } else {
                    if (Object.keys(entry[1]).includes('start') || Object.keys(entry[1]).includes('end')) {
                      const value = `${x[entry[0]].props.children.split(' ')[2]}-${x[entry[0]].props.children.split(' ')[1]}-${x[entry[0]].props.children.split(' ')[0]}`;
                      if (!entry[1].start && entry[1].end) {
                        return moment(entry[0].split('.').length === 2 ? moment(getValue(x, entry[0])) : value).isSameOrBefore(entry[1].end)
                      } else if (!entry[1].end && entry[1].start) {
                        return moment(entry[0].split('.').length === 2 ? moment(getValue(data, x[entry[0]])) : value).isSameOrAfter(entry[1].start)
                      }
                      return moment(entry[0].split('.').length === 2 ? moment(getValue(data, x[entry[0]])) : value).isBetween(entry[1]?.start, entry[1]?.end, undefined, '[]')
                    }
                    return entry[1] && (isBoolean(x[entry[0]]) ? x[entry[0]] : x[entry[0]].toLowerCase()) === entry[1].value
                  }
                }))
        );
        setApplyFilters(false);
      }
    } else if (onFilterApply) {
      const queryParams = [];
      let url = currentUrl;

      Object.entries(fields).forEach((field) => {
        const customFilterLabel = filtersConfig.filter(x => x.dataField === field[0])[0].filterLabel;
        if (typeof fields[field[0]] === 'string') {
          if (field[1].length === 0) {
            queryParams.push(customFilterLabel ? `${customFilterLabel}=${field[1]}` : `${field[0]}=${field[1]}`);
            dispatch({ type: 'remove-single-filter', payload: { key: customFilterLabel ? customFilterLabel : field[0], value: field[1] } });
          } else {
            queryParams.push(customFilterLabel ? `${customFilterLabel}=${field[1]}` : `${field[0]}=${field[1]}`);
            dispatch({ type: 'update-filters', payload: { key: customFilterLabel ? customFilterLabel : field[0], value: field[1] } });
          }
        } else {
          if (field[1].hasOwnProperty('value')) {
            if (field[1].value !== false && field[1].value.length === 0) {
              queryParams.push(customFilterLabel ? `${customFilterLabel}=${field[1]}` : `${field[0]}=${field[1]}`);
              dispatch({ type: 'remove-single-filter', payload: { key: customFilterLabel ? customFilterLabel : field[0], value: field[1] } });
            } else {
              queryParams.push(customFilterLabel ? `${customFilterLabel}=${field[1].value}` : `${field[0]}=${field[1].value}`);
              dispatch({ type: 'update-filters', payload: { key: customFilterLabel ? customFilterLabel : field[0], value: field[1].value } });
            }
          } else {
            const dateFilterLabels = filtersConfig.filter(x => x.dataField === field[0])[0].dateFilterLabels;
            Object.entries(field[1]).forEach(props => {
              queryParams.push(dateFilterLabels ? `${props[0] === 'start' ? dateFilterLabels[0] : dateFilterLabels[1]}=${props[1]}` : `${field[0]}_${props[0] === 'start' ? 'from' : 'to'}=${props[1]}`);
              dispatch({ type: 'update-filters', payload: { key: dateFilterLabels ? `${props[0] === 'start' ? dateFilterLabels[0] : dateFilterLabels[1]}` : `${field[0]}_${props[0] === 'start' ? 'from' : 'to'}`, value: props[1] } });
            });
          }
        }
      });

      if (applyFilter) {
        const formData = new FormData();
        queryParams.forEach(param => {
          const data = param.split('=');
          if (data[1] && data[1].length > 0 && data[1] !== 'undefined') {
            formData.append(data[0], data[1]);
          }
        });

        onFilterApply(formData, setApplyFilters, undefined, applyFilter);
      }
    }
  }, [fields, applyFilter]);


  const handleOnChange = (dataField, value) =>
    setFields({
      ...fields,
      [dataField]: value,
    });

  useEffect(() => {
    Object.entries(fields)?.length > 0 && Object.entries(fields).forEach(value => {
      if (typeof value[1] === 'string' && value[0].includes('email')) {
        setError(prevState => {
          if (prevState?.length > 0 && prevState.some(x => x.hasOwnProperty(value[0]))) {
            return [...error.filter(x => !x.hasOwnProperty(value[0])), { [value[0]]: !(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(value[1])) ? (value[1].length > 0 ? 'Please enter valid email' : '') : '' }];
          } else {
            return [...prevState, { [value[0]]: !(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(value[1])) ? (value[1].length > 0 ? 'Please enter valid email' : '') : '' }];
          }
        });
      } else if (value[1].start && value[1].start?.length === 10 && value[1].end?.length === 10 && value[1].end) {
        setError((prevState = []) => {
          if (prevState?.length > 0 && prevState.some(x => x.hasOwnProperty(value[0]))) {
            return [...error.filter(x => !x.hasOwnProperty(value[0])), { [value[0]]: moment(value[1].start).isAfter(value[1].end) ? 'End date should be greater than or equal to start date' : '' }];
          } else {
            return [...prevState, { [value[0]]: moment(value[1].start).isAfter(value[1].end) ? 'End date should be greater than or equal to start date' : '' }];
          }
        })
      }
    });
  }, [fields]);

  const renderFilterInput = (dataField, text, type, isMulti, isBoolean) => {

    if (!dataField || !text) {
      return null;
    }

    return {
      input: (
        <input
          className="form-control"
          name={dataField.toLowerCase()}
          onChange={(e) => handleOnChange(dataField, e.target.value)}
          value={Object.keys(fields).length === 0 ? '' : fields[dataField]}
        />
      ),
      dropDown: (
        <ReactSelect
          isMulti={isMulti}
          name={dataField.toLowerCase()}
          options={isBoolean ? FILTER_OPTIONS_BOOL[dataField && dataField.toLowerCase()] : FILTER_OPTIONS[dataField && dataField.toLowerCase()]}
          onChange={(e) => {
            handleOnChange(dataField, e)
          }}
          value={fields[dataField] || {}}
        />
      ),
      datePicker: (
        <DateRangePicker
          name={dataField.toLowerCase()}
          onChange={handleOnChange}
          noValues={Object.keys(fields).length === 0}
        />
      )
    }[type];


  };



  return (
    <div class="modal fade" tabindex="-1" role="dialog" id='multi-select-filters' data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Filters</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => { if (clearFilter) { setApplyFilters(true); dispatch({ type: 'reset-filters' }) } setClearFilter(false); }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className='container'>
              <div className='row' style={{ margin: '15px 0' }}>
                <div className='col'>Column Name</div>
                <div className='col'>Search</div>
              </div>
              <hr />
              {filtersConfig.map(({ dataField, text, isFilterable, type, isMulti, isBoolean }) => !isFilterable ? null : (
                <div className='row' style={{ margin: '10px 0' }}>
                  <div className='col'>{text}</div>
                  <div className='col'>
                    {renderFilterInput(dataField, text, type, isMulti, isBoolean)}
                    {error && error.length > 0 && <p className='mx-1' style={{ color: 'red', display: 'block' }}>{error.some(obj => obj.hasOwnProperty(dataField) && Object.values(obj)[0] !== '') ? Object.values(error.filter(obj => obj.hasOwnProperty(dataField))[0])[0] : ''}</p>}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" disabled={(error.length > 0 && error.some(x => Object.values(x)[0] !== '')) || Object.keys(fields).length === 0} onClick={() => setApplyFilters(true)} data-dismiss="modal">Apply Filters</button>
            <button type="button" class="btn btn-secondary"
              disabled={Object.keys(fields).length === 0}
              onClick={() => {
                setClearFilter(true);
                setFields({});
                setError([]);
              }}>Clear Filters</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiSelect;